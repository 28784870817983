import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { ToastContainer } from 'wini-web-components';
import { Home } from './router/router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { Provider } from 'react-redux';
import { store } from './store';
import LandingLayout from './screen/module/landing/view';
import Chart from 'react-apexcharts'
import React from 'react';
import ReactApexChart from 'react-apexcharts';


library.add(fas, fab, far);
function App() {
  return <Provider store={store} stabilityCheck="always">
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<LandingLayout />} />
        <Route path="/*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  </Provider>
}

export default App;

// export default class App extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       series: [{
//         name: 'Net Profit',
//         data: Array.from({ length: 3 }, () => Math.floor(Math.random() * 1000) + 500),
//         color: "#000000FF",
//       }, {
//         name: 'Revenue',
//         data: Array.from({ length: 3 }, () => Math.floor(Math.random() * 1000) + 500),
//       }, {
//         name: 'Free Cash Flow',
//         data: Array.from({ length: 3 }, () => Math.floor(Math.random() * 1000) + 500),
//       }, {
//         name: 'Free Cash Flow 2',
//         data: Array.from({ length: 3 }, () => Math.floor(Math.random() * 1000) + 500),
//       }, {
//         name: 'Free Cash Flow 2',
//         data: Array.from({ length: 3 }, () => Math.floor(Math.random() * 1000) + 500),
//       }],
//       options: {
//         chart: {
//           type: 'bar',
//           height: 350,
//           events: {
//             click: function (chart, w, e) {
//               console.log('1', chart, w, e)
//             },
//           }
//         },
//         plotOptions: {
//           bar: {
//             horizontal: false,
//             borderRadius: 2,
//             columnWidth: '30%',
//             borderRadiusApplication: 'end'
//           },
//         },
//         legend: {
//           position: 'top',
//           horizontalAlign: 'left',
//           fontSize: '14px',
//           fontFamily: 'Inter',
//           fontWeight: 400,
//           offsetX: -10,
//           markers: {
//             customHTML: [() => `<div style="height: 16px;width: 16px;margin-right: 8px;border-radius: 4px;background-color: #000000;"></div>`
//               , () => {
//                 return '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="16" height="16" rx="2" fill="#A4E923FF"/></svg>';
//               }, () => {
//                 return '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="16" height="16" rx="2" fill="#DC375BFF"/></svg>';
//               }],
//           },
//         },
//         dataLabels: {
//           enabled: false
//         },
//         stroke: {
//           show: true,
//           width: 2,
//           colors: ['transparent']
//         },
//         xaxis: {
//           categories: ['Feb', 'Mar', 'Apr',],
//         },
//         tooltip: {
//           y: {
//             formatter: function (val) {
//               return "$ " + val + " thousands"
//             }
//           }
//         }
//       },


//     };
//   }

//   render() {
//     return (
//       <div>
//         <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
//       </div>
//     );
//   }
// }