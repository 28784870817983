import styles from './assest.module.css'
import { faArrowUpFromBracket, faCalendarDays, faChevronLeft, faChevronRight, faImage, faLink, faSearch, faSliders, faSquareCheck, faT, faTableCells, faToggleOff, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { closePopup, Popup, showPopup, Text, TextField } from "wini-web-components";
import { ComponentType } from "../../home/table/da";
import { OutlineButton, OutlineContainer, OutlineInputMultiLine, OutlineInputPipe } from "../../../assets/icon";
import { faCircleDot } from '@fortawesome/free-regular-svg-icons';
import { PageActions } from './reducer';
import { useDispatch, useSelector } from 'react-redux';
import { onSelectedLayer } from './pageView';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { TableController } from '../../home/table/controller';
import TbAssetsChart from './tbAssestsTab/chart';
import TbAssetsForm from './tbAssestsTab/form';

export default function AssetsTab() {
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const { page, layers, selectedLayerId } = useSelector((store) => store.page.data)
    const _tbController = new TableController(_pid, "table")
    const dispatch = useDispatch()
    const popupRef = useRef()
    const [selectedTb, setSelectedTb] = useState()
    const [tables, setTables] = useState({ data: [] })
    const tbAssets = ["Card", "Form", "Chart", "Report"]

    const getTables = async () => {
        const res = await _tbController.getListSimple({ page: 1, size: 10, returns: ["Id", "Name"] })
        if (res.code === 200) setTables({ data: res.data, totalCount: res.count })
    }

    const handleDragComponent = (ev, prop) => {
        ev.preventDefault()
        function onDrag(event) {
            const _component = document.body.querySelector(`:scope > div[class*="component-options"]`)
            if (_component) {
                _component.style.left = `${event.pageX}px`
                _component.style.top = `${event.pageY}px`
                handleDragToTarget(event)
            } else {
                document.body.appendChild(ev.target.cloneNode(true))
                onSelectedLayer(undefined)
            }
        }
        document.body.addEventListener('mousemove', onDrag)
        document.body.onmouseup = () => {
            let _demo = document.body.querySelector(`div[class*="demo-component-in-container"]`)
            const _component = document.body.querySelector(`:scope > div[class*="component-options"]`)
            if (_component) document.body.removeChild(_component)
            document.body.removeEventListener('mousemove', onDrag)
            document.body.onmouseup = undefined
            if (_demo) {
                const _parent = _demo.parentElement
                const _children = [..._parent.children].sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
                const _layerChildren = layers.filter(e => e.ParentId === _parent.id)
                PageActions.editLayers(dispatch, _children.map((e, i) => {
                    let _tmp;
                    if (e.id) {
                        _tmp = _layerChildren.find(el => el.Id === e.id)
                        if (_tmp) return { ..._tmp, Setting: JSON.stringify({ ..._tmp.Setting, style: { ..._tmp.Setting.style, order: i } }) }
                    }
                    _tmp = {
                        Name: prop,
                        Type: prop,
                        PageId: page.Id,
                        DateCreated: (new Date()).getTime(),
                        ParentId: _parent.id,
                        Setting: { style: { order: i } }
                    }
                    if (prop === ComponentType.container && window.getComputedStyle(_parent).flexDirection === "row") _tmp.Setting.style.width = '4.8rem'
                    return _tmp
                }))
                _parent.removeChild(_demo)
            }
        }
    }

    const handleDragToTarget = (ev) => {
        const _parent = ev.target
        let _demo = document.body.querySelector(`div[class*="demo-component-in-container"]`)
        if (_parent.id?.length === 32 && _parent.getAttribute("component-type") === ComponentType.container) {
            let _children = [..._parent.children].filter(e => !e.classList.contains(styles['demo-component-in-container'])).sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0))
            if (!_demo) {
                _demo = document.createElement("div")
                _demo.className = styles['demo-component-in-container']
            }
            const _direction = window.getComputedStyle(_parent).flexDirection
            let _order = 0
            let _distance = 0
            if (_direction === "column") {
                _demo.style.height = '0.3rem'
                _demo.style.width = "2rem"
                _demo.style.maxWidth = "100%"
                if (_children.length) {
                    let closestHTML = [..._children].sort((aHTML, bHTML) => {
                        let aRect = aHTML.getBoundingClientRect()
                        let bRect = bHTML.getBoundingClientRect()
                        let a_center_oy = Math.abs(ev.pageY - (aRect.y + aRect.height / 2))
                        let b_center_oy = Math.abs(ev.pageY - (bRect.y + bRect.height / 2))
                        return a_center_oy - b_center_oy
                    })[0]
                    if (closestHTML) {
                        let htmlRect = closestHTML.getBoundingClientRect()
                        _order = _children.indexOf(closestHTML)
                        _distance = ev.pageY - (htmlRect.y + htmlRect.height / 2)
                        if (_distance < 0) _order--
                    } else _order = _children.length - 1
                }
            } else {
                _demo.style.width = '0.3rem'
                _demo.style.height = "2rem"
                _demo.style.maxHeight = "100%"
                if (_children.length) {
                    let isWrap = window.getComputedStyle(_parent).flexWrap == 'wrap'
                    let closestHTML = [..._children].sort((aHTML, bHTML) => {
                        let aRect = aHTML.getBoundingClientRect()
                        let bRect = bHTML.getBoundingClientRect()
                        let a_center_ox = Math.abs(ev.pageX - (aRect.x + aRect.width / 2))
                        let b_center_ox = Math.abs(ev.pageX - (bRect.x + bRect.width / 2))
                        if (isWrap) {
                            a_center_ox = Math.sqrt(Math.pow(ev.pageX - (aRect.x + aRect.width / 2), 2) + Math.pow(ev.pageY - (aRect.y + aRect.height / 2), 2))
                            b_center_ox = Math.sqrt(Math.pow(ev.pageX - (bRect.x + bRect.width / 2), 2) + Math.pow(ev.pageY - (bRect.y + bRect.height / 2), 2))
                        }
                        return a_center_ox - b_center_ox
                    })[0]
                    if (isWrap) closestHTML = _children.find(childHTML => childHTML.getBoundingClientRect().bottom >= ev.pageY)
                    if (closestHTML) {
                        let htmlRect = closestHTML.getBoundingClientRect()
                        _order = _children.indexOf(closestHTML)
                        _distance = ev.pageX - (htmlRect.x + htmlRect.width / 2)
                        if (_distance < 0) _order--
                    } else _order = _children.length - 1
                }
            }
            _demo.style.order = _order
            if (_demo.parentElement !== _parent) _parent.appendChild(_demo)
        } else if (_demo) _demo.parentElement.removeChild(_demo)
    }

    const renderComponentOption = (prop) => {
        switch (ComponentType[prop]) {
            case ComponentType.text:
                var icon = <FontAwesomeIcon icon={faT} style={{ fontSize: '2.2rem' }} />
                break;
            case ComponentType.img:
                icon = <FontAwesomeIcon icon={faImage} style={{ fontSize: '2.4rem' }} />
                break;
            case ComponentType.checkbox:
                icon = <FontAwesomeIcon icon={faSquareCheck} style={{ fontSize: '2.6rem' }} />
                break;
            case ComponentType.switch:
                icon = <FontAwesomeIcon icon={faToggleOff} style={{ fontSize: '2.6rem' }} />
                break;
            case ComponentType.textField:
                icon = <OutlineInputPipe color={'#fff'} size={'2.8rem'} />
                break;
            case ComponentType.button:
                icon = <OutlineButton color={'#fff'} size={'2.8rem'} />
                break;
            case ComponentType.container:
                icon = <OutlineContainer color={'#fff'} size={'2.8rem'} />
                break;
            case ComponentType.radio:
                icon = <FontAwesomeIcon icon={faCircleDot} style={{ fontSize: '2.4rem' }} />
                break;
            case ComponentType.calendar:
                icon = <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: '2.4rem' }} />
                break;
            case ComponentType.textArea:
                icon = <OutlineInputMultiLine color={'#fff'} size={'3.2rem'} />
                break;
            case ComponentType.navLink:
                icon = <FontAwesomeIcon icon={faLink} style={{ fontSize: '2.4rem' }} />
                break;
            case ComponentType.upload:
                icon = <FontAwesomeIcon icon={faArrowUpFromBracket} style={{ fontSize: '2.4rem' }} />
                break;
            case ComponentType.table:
                icon = <FontAwesomeIcon icon={faTableCells} style={{ fontSize: '2.2rem' }} />
                break;
            default:
                return null
        }
        return <div key={prop} className={`col col8 ${styles['component-options']}`} onMouseDown={(ev) => { handleDragComponent(ev, ComponentType[prop]) }}>
            {icon}
            <Text className="regular11" maxLine={1}>{ComponentType[prop]}</Text>
        </div>
    }

    const showPopupCardOptions = () => {
        showPopup({
            ref: popupRef,
            hideButtonClose: true,
            style: { width: '68%', height: '80%', backgroundColor: "#14181b" },
            content: <PopupCardOptions ref={popupRef} />
        })
    }

    const showSelectedTbView = () => {
        switch (selectedTb) {
            case "Basic":
                return <>
                    <button type="button" className="row" style={{ gap: '0.8rem', padding: '1.2rem', width: "fit-content" }} onClick={() => { setSelectedTb(undefined) }}>
                        <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '1.2rem' }} />
                        <Text className="semibold1" style={{ flex: 1 }}>Basic Components</Text>
                    </button>
                    <div className={`row ${styles['component-options-group']}`}>
                        {Object.keys(ComponentType).map((prop) => renderComponentOption(prop))}
                    </div>
                </>
            default:
                const _tb = tables.data.find(e => selectedTb.includes(e.Id))
                const _type = tbAssets.find(e => selectedTb.endsWith(e))
                switch (_type) {
                    case 'Card':
                        return <>
                            <button type="button" className="row" style={{ gap: '0.8rem', padding: '1.2rem', width: "fit-content" }} onClick={() => { setSelectedTb(_tb.Id) }}>
                                <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '1.2rem' }} />
                                <Text className="semibold1" style={{ flex: 1 }}>{_tb.Name} / Card</Text>
                            </button>
                        </>
                    case 'Form':
                        return <>
                            <button type="button" className="row" style={{ gap: '0.8rem', padding: '1.2rem', width: "fit-content" }} onClick={() => { setSelectedTb(_tb.Id) }}>
                                <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '1.2rem' }} />
                                <Text className="semibold1" style={{ flex: 1 }}>{_tb.Name} / Form</Text>
                            </button>
                            <TbAssetsForm tbName={_tb.Name} />
                        </>
                    case 'Chart':
                        return <>
                            <button type="button" className="row" style={{ gap: '0.8rem', padding: '1.2rem', width: "fit-content" }} onClick={() => { setSelectedTb(_tb.Id) }}>
                                <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '1.2rem' }} />
                                <Text className="semibold1" style={{ flex: 1 }}>{_tb.Name} / Chart</Text>
                            </button>
                            <TbAssetsChart tbName={_tb.Name} />
                        </>
                    case 'Report':
                        return <>
                            <button type="button" className="row" style={{ gap: '0.8rem', padding: '1.2rem', width: "fit-content" }} onClick={() => { setSelectedTb(_tb.Id) }}>
                                <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '1.2rem' }} />
                                <Text className="semibold1" style={{ flex: 1 }}>{_tb.Name} / Report</Text>
                            </button>
                        </>
                    default:
                        return <>
                            <button type="button" className="row" style={{ gap: '0.8rem', padding: '1.2rem', width: "fit-content" }} onClick={() => { setSelectedTb(undefined) }}>
                                <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '1.2rem' }} />
                                <Text className="semibold1" style={{ flex: 1 }}>{_tb.Name}</Text>
                            </button>
                            {tbAssets.map((e) => {
                                return <button key={e} type="button" className={`row ${styles['module-card-tile']}`} onClick={() => { setSelectedTb(_tb.Id + "_" + e) }}>
                                    <Text className="semibold1" style={{ flex: 1, paddingLeft: '1.2rem' }}>{e}</Text>
                                    <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '1.2rem' }} />
                                </button>
                            })}
                        </>
                }
        }
    }

    useEffect(() => { getTables() }, [])

    return <>
        <Popup ref={popupRef} />
        <TextField
            placeholder="Search for components..."
            className="regular1"
            style={{ padding: '1rem 0.6rem 1rem 1.2rem', borderRadius: 0 }}
            prefix={<FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.2rem' }} />}
            suffix={<button type="button" className="row icon-button24" onClick={showPopupCardOptions}>
                <FontAwesomeIcon icon={faSliders} style={{ fontSize: '1.2rem' }} />
            </button>}
        />
        <div className={`col ${styles['component-options-container']}`}>
            {selectedTb ? showSelectedTbView() : <>
                <button type="button" className={`row ${styles['module-card-tile']}`} onClick={() => { setSelectedTb("Basic") }}>
                    <Text className="semibold1" style={{ flex: 1 }}>Basic Components</Text>
                    <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '1.2rem' }} />
                </button>
                {tables.data.map((_tb, i) => {
                    return <button key={_tb.Id} type="button" className={`row ${styles['module-card-tile']}`} onClick={() => { setSelectedTb(_tb.Id) }}>
                        <Text className="semibold1" style={{ flex: 1 }}>{_tb.Name}</Text>
                        <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '1.2rem' }} />
                    </button>
                })}
            </>}
        </div>
    </>
}

const PopupCardOptions = forwardRef(function PopupCardOptions(data, ref) {
    const [tab, setTab] = useState(0)

    const renderTabView = () => {
        switch (tab) {
            case 0:
                return <div />
            case 1:
                return <div></div>
            default:
                return <div />
        }
    }

    return <div className={`col ${styles['popup-card-options']}`} style={{ flex: 1 }}>
        <div className={`row ${styles['popup-header']}`}>
            <button type='button' className={`row semibold2 ${tab === 0 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(0) }}>Card</button>
            <button type='button' className={`row semibold2 ${tab === 1 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(1) }}>Template</button>
            <div style={{ flex: 1 }} />
            <button type='button' className='row icon-button40' onClick={() => { closePopup(ref) }}>
                <FontAwesomeIcon icon={faXmark} />
            </button>
        </div>
        {renderTabView()}
    </div>
})