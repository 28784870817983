import React from "react";

import ReactApexChart from "react-apexcharts";
import { DashBoardCardType } from "../da";

export default function RenderChartByType(props: { type: any, datasets: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined, pieColors?: Array<string>, labels?: Array<string>, legendPosition?: "left" | "top" | "right" | "bottom" | "center" }) {
    return <ReactApexChart type={props?.type}
        series={props.datasets ?? []}
        options={{
            chart: {
                type: props?.type,
                toolbar: {
                    show: false
                },
                events: {
                    click: function (chart: any, w: any, e: any) {
                        console.log('1', chart, w, e)
                    },
                }
            },
            colors: props?.pieColors ?? ['#2E93fA', '#66DA26', '#FF9800'],
            plotOptions: {
                line: {
                    isSlopeChart: false,
                },
                area: {
                    fillTo: 'origin',
                },
                bubble: {
                    zScaling: true,
                    minBubbleRadius: 16,
                    maxBubbleRadius: undefined,
                },
                bar: {
                    horizontal: false,
                    borderRadius: 2,
                    columnWidth: '30%',
                    borderRadiusApplication: 'end'
                },
            },
            legend: {
                position: props.legendPosition,
                horizontalAlign: 'left',
                fontSize: '14px',
                fontFamily: 'Inter',
                fontWeight: 400,
                itemMargin: {
                    horizontal: 12,
                    vertical: 0
                },
                markers: {
                    customHTML: props.datasets?.map((item: any) => () => `<div style="height: 16px;width: 16px;margin-right: 8px;border-radius: 4px;background-color: ${item.color};"></div>`) ?? [],
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            markers: {
                size: 0
            },
            labels: props?.labels ?? [],
            xaxis: {
                categories: props?.labels ?? [],
            },
            tooltip: {
                y: {
                    formatter: function (val: any) {
                        return val
                    }
                }
            }
        } as any}
    />
}