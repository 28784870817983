import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CellAlignItems, Checkbox, closePopup, Dialog, Pagination, Popup, showPopup, Table, TbBody, TbCell, TbHeader, TbRow, Text, TextField, ToastMessage } from 'wini-web-components'
import { faChevronRight, faEdit, faEllipsisH, faListUl, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import { NavLink, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { DataController } from '../controller'
import PopupAddEditData from './settingForm'
import PopupFormFilter from './formSearch'
import { TableController } from "../../home/table/controller";
import { getTableConfig } from '../config'
import { FilledGearSix, OutlineEnlargeVertical, OutlineMoveLayerLeft, OutlineMoveLayerRight, OutlineViewOff } from '../../../assets/icon'
import './view.css'
import { useForm } from 'react-hook-form'
import PopupManageTableFK from './tableFKManger'
import { PopupActions, PopupAddColumn } from './settingColumn'
import { FEDataType } from '../../home/table/da'

export default function ModuleListView({ module }) {
    const { moduleName, pModuleName } = useParams()
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _colController = new TableController(_pid, "column")
    const _relController = new TableController(_pid, "rel")
    const _dataController = new DataController({ pid: _pid, module: module })
    const ref = useRef()
    const dialogRef = useRef()
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 })
    const [data, setData] = useState({ data: [], total: undefined })
    const [cols, setCols] = useState([])
    const [rels, setRels] = useState([])
    const [actions, setActions] = useState([])
    const methods = useForm({ shouldFocusError: false })
    const [selected, setSelected] = useState([])

    const getData = async (page, size) => {
        let _query = []
        for (const [key, value] of searchParams) {
            if (key !== "pid") {
                const _col = cols.find(e => e.Name === key.replace('_min', "").replace('_max', ""))
                switch (_col?.DataType) {
                    case FEDataType.STRING:
                        _query.push(`@${key}:${value}`)
                        break;
                    case FEDataType.BOOLEAN:
                        _query.push(`@${key}:{${value}}`)
                        break;
                    default:
                        if (_col && [FEDataType.DATE, FEDataType.DATETIME, FEDataType.NUMBER, FEDataType.MONEY].includes(_col.DataType)) {
                            if (_query.every(e => !e.startsWith(`@${_col.Name}:`))) _query.push(`@${_col.Name}:[${searchParams.get(`${_col.Name}_min`)} ${searchParams.get(`${_col.Name}_max`)}]`)
                        }
                        break;
                }
            }
        }
        const res = await _dataController.aggregateList({
            page: page ?? 1,
            size: size ?? 10,
            searchRaw: _query.length ? _query.join(" ") : "*"
        })
        if (res.code === 200) {
            setData({ data: res.data, total: res.totalCount })
        } else {
            ToastMessage.errors(res.message)
        }
    }

    const deleteItem = async (item) => {
        const res = await _dataController.delete((selected.length ? selected : [item.Id]).map(id => id.toLowerCase().replaceAll('-', '')))
        if (res.code === 200) {
            ToastMessage.success(`Xóa ${(selected.length > 1 ? "các " : "") + module} thành công`)
            getData(pageDetails.page, pageDetails.size)
        } else {
            ToastMessage.errors(res.message)
        }
    }

    const showPopupSettingColumn = (ev, _col) => {
        const _tbCellRect = ev.target.closest(".tb-cell").getBoundingClientRect()
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', left: _tbCellRect.x, top: _tbCellRect.bottom },
            content: <div className='col popup-actions' style={{ borderRadius: '0.8rem', minWidth: '20rem' }}>
                <button type='button' className='row' style={{ padding: '0.6rem 1.2rem', gap: '0.8rem' }}>
                    <OutlineMoveLayerLeft />
                    <Text>Move to start</Text>
                </button>
                <button type='button' className='row' style={{ padding: '0.6rem 1.2rem', gap: '0.8rem' }}>
                    <OutlineMoveLayerRight />
                    <Text>Move to end</Text>
                </button>
                <button type='button' className='row' style={{ padding: '0.6rem 1.2rem', gap: '0.8rem' }}>
                    <OutlineViewOff />
                    <Text>Hide column</Text>
                </button>
            </div>
        })
    }

    const showPopupSettingAction = (ev) => {
        const _tbCellRect = ev.target.closest(".tb-cell").getBoundingClientRect()
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', right: 0, top: _tbCellRect.y, height: `calc(100% - ${_tbCellRect.y}px)`, borderRadius: 0, maxHeight: '100%' },
            content: <PopupAddColumn
                ref={ref}
                cols={cols}
                rels={rels}
                actions={actions}
                colController={_colController}
                relController={_relController}
                onChange={({ newCols, newRels, newActions }) => {
                    if (newCols) setCols(newCols)
                    if (newRels) setRels(newRels)
                    if (newActions) setActions(newActions)
                }}
            />
        })
    }

    const showPopupAddEdit = (item) => {
        showPopup({
            ref: ref,
            style: { width: '80rem' },
            content: <PopupAddEditData
                ref={ref}
                id={item?.Id?.toLowerCase()?.replaceAll("-", "")}
                pid={_pid}
                module={module}
                onSuccess={() => { getData(pageDetails.page, pageDetails.size) }}
            />
        })
    }

    const showPopupFilter = () => {
        showPopup({
            ref: ref,
            style: { width: '80rem' },
            content: <PopupFormFilter
                ref={ref}
                pid={_pid}
                module={module}
                cols={cols.filter(e => !e.Setting.IsHidden)}
                onSuccess={getData}
            />
        })
    }

    const showPopupAction = (ev, item) => {
        showPopup({
            ref: ref,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', left: ev.pageX, top: ev.pageY },
            content: <PopupActions
                ref={ref}
                pid={_pid}
                item={item}
                onEdit={() => { showPopupAddEdit(item) }}
                onDelete={() => { deleteItem(item) }}
                actions={actions.filter(e => !e.Shortcut.IsHidden)}
                onSelectAction={(_action) => {
                    closePopup(ref)
                    showPopup({
                        ref: ref,
                        style: { height: '80%', width: '80%' },
                        content: <PopupManageTableFK ref={ref} pid={_pid} item={item} rel={_action} />
                    })
                }}
            />
        })
    }

    useEffect(() => {
        const _rels = rels.filter(e => !e.Setting.IsHidden)
        if (_rels.length && data.total) {
            _rels.forEach((_rel) => {
                const _relItems = methods.getValues(_rel.Column) ?? []
                let _relIds = []
                data.data.map(e => e[_rel.Column]?.split(",") ?? []).reduce((a, b) => a.concat(b)).forEach(id => {
                    if (!_relIds.includes(id)) _relIds.push(id)
                })
                _relIds = _relIds.filter(id => _relItems.every(e => e.Id !== id))
                if (_relIds.length) {
                    const _relDataCotroller = new DataController({ pid: _pid, module: _rel.TablePK })
                    _relDataCotroller.getByListId(_relIds).then(res => {
                        if (res.code === 200) methods.setValue(_rel.Column, [..._relItems, ...res.data.filter(e => e != undefined)])
                    })
                }
            })
        }
    }, [rels.filter(e => !e.Setting.IsHidden).length, data])

    useEffect(() => {
        if (cols.length) getData()
    }, [cols.length, module])

    useEffect(() => {
        methods.reset()
        _colController.getListSimple(
            {
                page: 1,
                size: 100,
                query: `@TableName:{${module}}`,
                returns: ["Id", "Name", "Setting", "DataType"]
            }
        ).then(res => {
            if (res.code === 200) setCols(res.data.map((e, i) => {
                e.Setting = e.Setting ? JSON.parse(e.Setting) : { Title: e.Name, Sort: i }
                e.Form = e.Form ? JSON.parse(e.Form) : { Label: e.Name, Sort: i }
                return e
            }).sort((a, b) => a.Setting.Sort - b.Setting.Sort))
        })
        _relController.getListSimple(
            {
                page: 1,
                size: 100,
                query: `@TableFK:{${module}}`,
                returns: ["Id", "Column", "Setting", "TablePK"]
            }
        ).then(res => {
            if (res.code === 200) setRels(res.data.map((e, i) => {
                e.Setting = e.Setting ? JSON.parse(e.Setting) : { Title: e.Name, Sort: i }
                return e
            }).sort((a, b) => a.Setting.Sort - b.Setting.Sort))
        })
        _relController.getListSimple(
            {
                page: 1,
                size: 100,
                query: `@TablePK:{${module}}`,
                returns: ["Id", "Column", "Shortcut", "TableFK"]
            }
        ).then(res => {
            if (res.code === 200) setActions(res.data.map((e, i) => {
                e.Shortcut = e.Shortcut ? JSON.parse(e.Shortcut) : { Title: e.Name, Sort: i, IsHidden: true }
                return e
            }).sort((a, b) => a.Shortcut.Sort - b.Shortcut.Sort))
        })
    }, [_pid, module])

    return <div className="col view">
        <Popup ref={ref} />
        <Dialog ref={dialogRef} />
        <div className="col table-view">
            <div className='col' style={{ padding: '1.6rem 2.4rem', gap: '0.6rem' }}>
                <div className='row breadcrumb'>
                    <NavLink to={'/home'} className='body-3' style={{ opacity: 0.85 }}>Quản lý phần mềm</NavLink>
                    {
                        pModuleName ? <>
                            <FontAwesomeIcon icon={faChevronRight} />
                            <Text className='body-3' style={{ opacity: 0.85 }}>{pModuleName}</Text>
                        </> : null
                    }
                    <FontAwesomeIcon icon={faChevronRight} />
                    <Text className='label-3' style={{ opacity: 0.9 }}>{moduleName ?? "-"}</Text>
                </div>
                <div className='row' style={{ gap: '0.8rem' }}>
                    <Text className='heading-5' maxLine={2} style={{ flex: 1, width: '100%' }}>{moduleName ?? "-"}</Text>
                    <button type='button' className='row button-primary' onClick={() => { showPopupAddEdit() }}>
                        <FontAwesomeIcon icon={faPlus} />
                        <Text className='button-text-3'>Thêm mới</Text>
                    </button>
                </div>
            </div>
            <div className='row filter-table' style={{ padding: '1.2rem 2.4rem', gap: '0.8rem' }}>
                <TextField
                    placeholder='Tìm kiếm'
                    prefix={<FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.4rem', color: '#161D2470' }} />}
                    style={{ padding: '0.5rem 1.6rem', borderRadius: '0.6rem', height: '3.2rem' }}
                />
                <button className='row button-neutral' onClick={() => { showPopupFilter() }}>
                    <FontAwesomeIcon icon={faListUl} />
                    <Text className='button-text-3'>Bộ lọc</Text>
                </button>
            </div>
            <div className='col' style={{ padding: '0 2.4rem', width: '100%', flex: 1, height: '100%' }}>
                <div style={{ overflow: 'auto', width: '100%', flex: 1, height: '100%' }}>
                    <Table>
                        <TbHeader>
                            {
                                [
                                    ...cols.filter((e) => e.Name !== "Id" && !e.Setting.IsHidden).map((_col, i) => {
                                        const { _alignCell, _minW } = getTableConfig(_col)
                                        if (i > 0) {
                                            return <TbCell key={_col.Id} style={{ minWidth: _col.Setting?.Width ?? _minW }} className='setting-col-button' onClick={(ev) => { showPopupSettingColumn(ev, _col) }}>
                                                <div className='row' style={{ height: '100%', gap: '0.8rem' }}>
                                                    <Text className='heading-9'>{_col.Setting.Title}</Text>
                                                    <button type='button' className='row icon-button24' style={{}}>
                                                        <OutlineEnlargeVertical size={'1.4rem'} />
                                                    </button>
                                                </div>
                                            </TbCell>
                                        } else {
                                            return <TbCell key={_col.Id} style={{ minWidth: _col.Setting?.Width ?? _minW }} fixed className='setting-col-button' onClick={(ev) => { showPopupSettingColumn(ev, _col) }}>
                                                <div className='row' style={{ gap: '1.6rem' }}>
                                                    <Checkbox size={'1.6rem'} value={data.data.every(e => selected.includes(e.Id))} onChange={(v) => {
                                                        const _filter = selected.filter(id => data.data.every(e => id !== e.Id))
                                                        if (v) setSelected([..._filter, ...data.data.map(e => e.Id)])
                                                        else setSelected(_filter)
                                                    }} />
                                                    <div className='row' style={{ height: '100%', gap: '0.8rem' }}>
                                                        <Text className='heading-9'>{_col.Setting.Title}</Text>
                                                        <button type='button' className='row icon-button24'>
                                                            <OutlineEnlargeVertical size={'1.4rem'} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </TbCell>
                                        }
                                    }),
                                    ...rels.filter((e) => !e.Setting.IsHidden).map((_rel, i) => {
                                        return <TbCell key={`${_rel.Id}`} style={{ minWidth: '20rem' }}>
                                            <Text maxLine={3} className='heading-9'>{_rel.Setting?.Title ?? _rel.Column}</Text>
                                        </TbCell>
                                    }),
                                    <TbCell key={'hlast'} fixed style={{ minWidth: '8.4rem' }} className='setting-col-button' onClick={showPopupSettingAction}>
                                        <div className='row' style={{ paddingLeft: '1rem' }}><FilledGearSix size={'2rem'} /></div>
                                    </TbCell>
                                ]
                            }
                        </TbHeader>
                        <TbBody>
                            {
                                data.data.map((item, index) => <TbRow key={`${item.name}-${index}`}>
                                    {
                                        [
                                            ...cols.filter((e) => e.Name !== "Id" && !e.Setting.IsHidden).map((_col, i) => {
                                                const { _alignCell, _minW, _value } = getTableConfig(_col, item)
                                                if (i > 0) {
                                                    return <TbCell key={`${_col.Id}-${index}`} style={{ minWidth: _minW }}>
                                                        <Text maxLine={3} className='heading-9'>{_value ?? ""}</Text>
                                                    </TbCell>
                                                } else {
                                                    return <TbCell key={`${_col.Id}-${index}`} fixed style={{ minWidth: _minW }}>
                                                        <div className='row' style={{ gap: '1.6rem' }}>
                                                            <Checkbox size={'1.6rem'} value={selected.includes(item.Id)} onChange={(v) => {
                                                                if (v) setSelected([...selected, item.Id])
                                                                else setSelected(selected.filter(id => id !== item.Id))
                                                            }} />
                                                            <Text maxLine={3} className='heading-9'>{_value ?? ""}</Text>
                                                        </div>
                                                    </TbCell>
                                                }
                                            }),
                                            ...rels.filter((e) => !e.Setting.IsHidden).map((_rel, i) => {
                                                const _ids = item[_rel.Column]?.split(",") ?? []
                                                const _mapValue = _ids.length ? (methods.watch(_rel.Column) ?? []).filter(e => _ids.includes(e.Id)) : undefined
                                                return <TbCell key={`${_rel.Id}-${index}`} style={{ minWidth: '20rem' }}>
                                                    <Text maxLine={3} className='heading-9'>{_mapValue?.map(e => e.Name ?? "-")?.join(",") ?? ""}</Text>
                                                </TbCell>
                                            }),
                                            <TbCell key={'blast-' + index} fixed style={{ minWidth: '8.4rem', }} align={CellAlignItems.end} >
                                                <div className='row' style={{ gap: '0.6rem', justifyContent: 'center', visibility: selected.length === 0 || selected.includes(item.Id) ? "visible" : "hidden" }}>
                                                    <button type='button' className='row icon-button24'><FontAwesomeIcon icon={faEdit} onClick={() => { showPopupAddEdit(item) }} /></button>
                                                    <button type='button' className='row icon-button24'><FontAwesomeIcon icon={faEllipsisH} onClick={(ev) => { showPopupAction(ev, item) }} /></button>
                                                    {/* <button type='button' className='row icon-button24'><FontAwesomeIcon icon={faTrashCan} onClick={(ev) => {
                                                        showDialog({
                                                            ref: dialogRef,
                                                            alignment: DialogAlignment.center,
                                                            status: ComponentStatus.WARNING,
                                                            title: 'Bạn chắc chắn muốn xóa ' + (selected.length > 1 ? "các " : "") + module + " này",
                                                            content: `Mọi dữ liệu của ${(selected.length > 1 ? "các " : "") + module} này sẽ bị xóa vĩnh viễn`,
                                                            onSubmit: () => deleteItem(item)
                                                        })
                                                    }} /></button> */}
                                                </div>
                                            </TbCell>
                                        ]
                                    }
                                </TbRow>)
                            }
                        </TbBody>
                    </Table>
                </div>
            </div>
        </div>
        <div style={{ padding: '1.2rem 2.4rem' }}>
            <Pagination
                currentPage={pageDetails.page}
                itemPerPage={pageDetails.size}
                totalItem={data?.total ?? 0}
                onChangePage={(page, size) => {
                    if (pageDetails.page !== page || pageDetails.size !== size) {
                        setPageDetails({ page: page, size: size });
                        getData(page, size)
                        setSelected([])
                    }
                }}
            />
        </div>
    </div >
}