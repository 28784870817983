import { NavLink, useNavigate } from "react-router-dom"
import { closePopup, ComponentStatus, Dialog, DialogAlignment, Popup, showDialog, showPopup, Text, TextField, ToastMessage } from "wini-web-components"
import { ProjectController } from "./controller"
import { useEffect, useRef, useState } from "react"
import { randomGID, Ultis } from "../../../Utils"
import './view.css'
import ConfigApi from "../../../da/configApi"
import { faEdit, faEllipsisH, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { BaseDA } from "../../../da/baseDA"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SvgByLink } from "../../../project-component/svg"
import { TableController } from "../table/controller"

export function ListProject() {
    const [projects, setProjects] = useState([])
    const [selected, setSelected] = useState()
    const dialogRef = useRef()

    useEffect(() => {
        ProjectController.getAll().then(res => {
            setProjects(res.data)
        })
    }, [])

    return projects.length ? <div className="col view" style={{ gap: '2.4rem', padding: '2.4rem' }}>
        <Dialog ref={dialogRef} />
        <div className="row" style={{ paddingTop: '0.8rem' }}>
            <Text className="heading-6" style={{ flex: 1 }}>Quản lý phần mềm</Text>
            <NavLink to={'/home/project/add'} className="row button-primary" >
                <Text className="button-text-3">Tạo phần mềm</Text>
            </NavLink>
        </div>
        <div className="row" style={{ flexWrap: 'wrap', gap: '2.4rem' }}>
            {projects.map((item, i) => <ProjectCard
                key={item.Id}
                item={item}
                style={selected === item.Id ? { borderColor: 'var(--primary-color)' } : undefined}
                onClick={() => setSelected(item.Id)}
                onDelete={() => {
                    showDialog({
                        ref: dialogRef,
                        alignment: DialogAlignment.center,
                        status: ComponentStatus.WARNING,
                        title: 'Bạn có chắc chắn muốn phần mềm này',
                        content: 'Tất cả dữ liệu của phần mềm sẽ bị xóa khỏi hệ thống',
                        onSubmit: async () => {
                            const res = await ProjectController.delete([item.Id])
                            if (res.code !== 200) return ToastMessage.errors(res.message)
                            setProjects(projects.filter(e => e.Id !== item.Id))
                        }
                    })
                }}
                onEdit={async (_item) => {
                    const res = await ProjectController.edit([item])
                    if (res.code !== 200) ToastMessage.errors(res.message)
                    setProjects(projects.map(e => {
                        if (e.Id === _item.Id) return _item
                        else return e
                    }))
                }}
            />)}
        </div>
    </div> : <div className="col view" style={{ alignItems: 'center', justifyContent: 'center', height: '100%', gap: '4.8rem' }}>
        <Text className="semibold3">Bạn chưa có phần mềm nào được tạo. Bắt đầu tạo mới</Text>
        <NavLink to={'/create-project'} className="row button-primary" >
            <Text className="button-text-3">Tạo phần mềm</Text>
        </NavLink>
    </div>
}

const ProjectCard = ({ item, onEdit, onClick, style, onDelete }) => {
    const filePickerRef = useRef()
    const popupRef = useRef()
    const [onEditting, setOnEdit] = useState()
    const [data, setData] = useState()
    const navigate = useNavigate()

    const pickLogo = async (ev, item) => {
        if (ev.target.files && ev.target.files[0]) {
            const res = await BaseDA.uploadFiles(ev.target.files)
            if (res) {
                item.LogoId = res[0].id
                onEdit(item)
            }
        }
    }

    const showPopupAction = (ev) => {
        ev.preventDefault()
        showPopup({
            ref: popupRef,
            clickOverlayClosePopup: true,
            style: { position: 'absolute', top: ev.pageY, left: ev.pageX },
            content: <div className="col popup-actions" style={{ width: '14rem', borderRadius: '0.8rem' }}>
                <button type="button" onClick={async () => {
                    closePopup(popupRef)
                    const _pageController = new TableController(data.Id, "page")
                    const res = await _pageController.getListSimple({
                        page: 1,
                        size: 1,
                        returns: ["Id"]
                    })
                    let _pageid = res.data?.[0]?.Id
                    if (!_pageid) {
                        const _newPage = {
                            Id: randomGID(),
                            Name: 'Page 1',
                            DateCreated: (new Date()).getTime(),
                            Sort: 0
                        }
                        const addPageRes = await _pageController.add([_newPage])
                        if (addPageRes.code === 200) _pageid = _newPage.Id
                        else return ToastMessage.errors(addPageRes.message)
                    }
                    navigate(`/pages/${data.Name}?pid=${data.Id}&pageid=${_pageid}`)
                }}>
                    <Text className="button-text-3">Setting page</Text>
                </button>
                <button type="button" onClick={() => {
                    closePopup(popupRef)
                    filePickerRef.current.click()
                }}>
                    <Text className="button-text-3">Change logo</Text>
                </button>
                <button type="button" onClick={() => {
                    closePopup(popupRef)
                    onDelete(item)
                }}>
                    <Text className="button-text-3">Delete</Text>
                </button>
            </div>
        })
    }

    useEffect(() => { setData(item) }, [item])

    useEffect(() => {
        if (onEditting) onEditting.focus()
    }, [onEditting])

    return <>
        <Popup ref={popupRef} />
        <div className="col col6 project-card" onClick={onClick} style={style} onContextMenu={showPopupAction}>
            <button type="button" onDoubleClick={() => navigate(`/admin/table?pid=${data?.Id}`)} className={'row logo-img'} >
                {/* {data?.LogoId && <img src={ConfigApi.imgUrl + data?.LogoId} alt="" height={'100%'} style={{ aspectRatio: '1 / 1' }} />} */}
                {data?.LogoId && <SvgByLink link={ConfigApi.imgUrl + data?.LogoId} />}
            </button>
            <div style={{ padding: '0.8rem 1.6rem' }}>
                <div style={{ padding: '0.4rem 0' }} onDoubleClick={(ev) => setOnEdit(ev.target.querySelector("input"))}>
                    <TextField
                        disabled={!onEditting}
                        className="semibold3"
                        defaultValue={item.Name}
                        onBlur={async (ev) => {
                            if (ev.target.value.trim() !== data?.Name) {
                                data.Name = ev.target.value.trim()
                                onEdit(data)
                            }
                            setOnEdit(false)
                        }}
                    />
                </div>
                <Text className="subtitle-4" maxLine={2}>{Ultis.datetoString(new Date(data?.DateCreated))}</Text>
            </div>
            <button type='button' className='row icon-button28' onClick={showPopupAction}>
                <input accept={'image/svg+xml'} ref={filePickerRef} type='file' hidden onChange={(ev) => { pickLogo(ev, data) }} />
                <FontAwesomeIcon icon={faEllipsisH} style={{ fontSize: '1.8rem' }} />
            </button>
            {/* <button type='button' className='row icon-button28' onClick={onDelete}>
            <FontAwesomeIcon icon={faTrashCan} style={{ fontSize: '1.8rem' }} />
        </button> */}
        </div>
    </>
}