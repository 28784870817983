import { faListUl, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { forwardRef, useEffect, useRef, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { closePopup, ComponentStatus, Dialog, DialogAlignment, Pagination, Popup, showDialog, showPopup, Switch, Table, TbBody, TbCell, TbHeader, TbRow, Text, TextField, ToastMessage } from "wini-web-components"
import { Select1Form, TextAreaForm, TextFieldForm } from "../../../project-component/component-form"
import { TableController } from "../../home/table/controller"
import { EventStep, FEDataType } from "../../home/table/da"
import { Reducers } from "../da"
import { randomGID } from "../../../Utils"
import { DataController, SettingDataController } from "../controller"
import { getTableConfig } from "../config"

export default function SettingModel({ id, pid, item, onSelect }) {
    const _dataController = new DataController({ pid: pid, module: item.Name })
    const _modelDataController = new SettingDataController({ pid: pid, setting: "model" })
    const _reducerDataController = new SettingDataController({ pid: pid, setting: "reducer" })
    const _relController = new TableController(pid, "rel")
    const _colController = new TableController(pid, "column")
    const ref = useRef()
    const dialogRef = useRef()
    const methods = useForm({ shouldFocusError: false, defaultValues: { Name: "", Columns: ["Id", "Name"], Reducers: [] } })
    const _reducers = useFieldArray({
        control: methods.control,
        name: "Reducers",
        keyName: undefined
    })
    const [cols, setCols] = useState([])
    const [relatives, setRelatives] = useState([])
    const [data, setData] = useState({ data: [], total: undefined })

    const _onSubmit = (ev) => {
        showDialog({
            ref: dialogRef,
            alignment: DialogAlignment.center,
            status: ComponentStatus.WARNING,
            title: `Bạn chắc chắn muốn ${id ? "lưu" : 'tạo'} mẫu báo cáo`,
            content: "Mẫu báo cáo sẽ được lưu lại theo các thông tin đã nhập",
            onSubmit: async () => {
                ev.Id ??= randomGID()
                ev.DateCreated ??= (new Date()).getTime()
                ev.TbName = item.Name
                if (ev.Columns) ev.Columns = ev.Columns.join(",")
                const _deleteReducerIds = ev.Reducers.filter(e => e.isDeleted && e.ModelId).map(e => e.Id)
                if (_deleteReducerIds.length) {
                    _reducerDataController.action('delete', { ids: _deleteReducerIds }).then((res) => {
                        if (res.code !== 200) ToastMessage.errors(`delete reducer failed: ${res.message}`)
                    })
                }
                const _addReducers = ev.Reducers.filter(e => !e.isDeleted).map(e => { return { ...e, ModelId: ev.Id } })
                delete ev.Reducers
                const res = await _modelDataController.action('add', { data: [ev] })
                if (res.code !== 200) return ToastMessage.errors(res.message)
                if (_addReducers.length) {
                    const resReducers = await _reducerDataController.action('add', { data: _addReducers })
                    if (resReducers.code !== 200) return ToastMessage.errors(resReducers.message)
                }
                ToastMessage.success(`${id ? "Lưu" : "Tạo"} mẫu báo cáo thành công`)
                await _modelDataController.getListSimple({ page: 1, size: 1 })
                onSelect(undefined)
            }
        })
    }

    const showAddColGroupPopup = () => {
        showPopup({
            ref: ref,
            heading: <div className="heading-6 popup-header">Tạo cột dữ liệu</div>,
            style: { width: "60%" },
            content: <AddColGroup
                ref={ref}
                pid={pid}
                relatives={relatives}
                onSubmit={(_newItem) => {
                    const _ids = data.data.map(e => e.Id)
                    if (_ids.length) {
                        _dataController.groupByIds({
                            ids: _ids,
                            reducers: [..._reducers.fields, _newItem].map((e) => {
                                const _rel = relatives.find(r => r.Id === e.RelativeId)
                                return {
                                    Name: e.Name,
                                    Reducer: e.Reducer,
                                    ReducerBy: e.ReducerBy,
                                    TbName: _rel.TableFK,
                                    Column: _rel.Column
                                }
                            })
                        }).then((res) => {
                            if (res.code === 200) {
                                const _mapData = data.data.map((e) => {
                                    let _matchItem = res.data.find(e => e[`_${module}Id`] === e.Id)
                                    if (_matchItem) {
                                        delete _matchItem[`_${item.Name}Id`]
                                        return { ...e, ..._matchItem }
                                    } else {
                                        return e
                                    }
                                })
                                setData({
                                    total: data.total,
                                    data: _mapData,
                                })
                            } else {
                                ToastMessage.errors(res.message)
                            }
                        })
                    }
                    _reducers.append(_newItem)
                }}
            />
        })
    }

    const getData = async () => {
        const res = await _dataController.aggregateList({ page: 1, size: 10, searchRaw: "*" })
        if (res.code === 200) {
            setData({ data: res.data, total: res.totalCount })
        } else {
            ToastMessage.errors(res.message)
        }
    }

    const renderTableDemo = () => {
        const _listCol = cols.filter(e => e.Name.toLowerCase() !== "id" && methods.watch("Columns").some(n => e.Name === n))
        return <Table>
            <TbHeader>
                {
                    [
                        ..._listCol.map((_col, i) => {
                            const { _alignCell, _minW } = getTableConfig(_col)
                            return <TbCell key={'h0-' + _col.Id} fixed={i === 0} style={{ minWidth: _minW }} align={_alignCell}>
                                <Text className='heading-9' style={{ textAlign: _alignCell }}>{_col.Title ?? _col.Name}</Text>
                            </TbCell>
                        }),
                        ..._reducers.fields.filter(e => !e.isDeleted).map((_red, i) => {
                            const { _alignCell, _minW } = getTableConfig(_red)
                            return <TbCell key={'h0-' + _red.Id} style={{ minWidth: _minW }} align={_alignCell}>
                                <Text className='heading-9' style={{ textAlign: _alignCell }}>{_red.Title ?? _red.Name}</Text>
                            </TbCell>
                        })
                    ]
                }
            </TbHeader>
            <TbBody>
                {
                    data.data.map((item, index) => {
                        return <TbRow key={item.Id}>
                            {
                                [
                                    ..._listCol.map((_col, i) => {
                                        const { _alignCell, _minW, _value } = getTableConfig(_col, item)
                                        return <TbCell key={`${_col.Id}-${index}`} fixed={i === 0} style={{ minWidth: _minW }} align={_alignCell}>
                                            <Text maxLine={3} className='heading-9' style={{ textAlign: _alignCell }}>{_value ?? ""}</Text>
                                        </TbCell>
                                    }),
                                    ..._reducers.fields.filter(e => !e.isDeleted).map((_red, i) => {
                                        const { _alignCell, _minW, _value } = getTableConfig(_red, item)
                                        return <TbCell key={`${_red.Id}-${index}`} style={{ minWidth: _minW }} align={_alignCell}>
                                            <Text maxLine={3} className='heading-9' style={{ textAlign: _alignCell }}>{_value ?? ""}</Text>
                                        </TbCell>
                                    }),
                                ]
                            }
                        </TbRow>
                    })
                }
            </TbBody>
        </Table >
    }

    useEffect(() => {
        _relController.getListSimple(
            {
                page: 1,
                size: 50,
                query: `@TablePK:{${item.Name}}`,
            }
        ).then((_res) => {
            if (_res.code === 200) setRelatives(_res.data)
        })
        _colController.getListSimple(
            {
                page: 1,
                size: 100,
                query: `@TableName:{${item.Name}}`,
                returns: ["Id", "Name", "Setting", "DataType"]
            }
        ).then(res => {
            if (res.code === 200) setCols(res.data.map((e, i) => {
                e.Setting = e.Setting ? JSON.parse(e.Setting) : { Title: e.Name, Sort: i }
                e.Form = e.Form ? JSON.parse(e.Form) : { Label: e.Name, Sort: i }
                return e
            }).sort((a, b) => a.Setting.Sort - b.Setting.Sort))
        })
        if (id) {
            _modelDataController.getByIds([id]).then((res) => {
                if (res.code === 200) {
                    const _modelItem = res.data[0]
                    Object.keys(_modelItem).map((prop) => {
                        if (_modelItem[prop]) {
                            if (prop === "Columns") {
                                methods.setValue("Columns", _modelItem[prop].split(","))
                            } else {
                                methods.setValue(prop, _modelItem[prop])
                            }
                        }
                    })
                } else {
                    ToastMessage.errors(res.message)
                }
            })
        } // lấy thông tin mẫu báo cáo
    }, [id])

    useEffect(() => {
        if (cols.length) getData()
    }, [cols.length])

    return <div className="col" style={{ flex: 1 }}>
        <Popup ref={ref} />
        <Dialog ref={dialogRef} />
        <div className='col' style={{ padding: '1.2rem 2.4rem 0' }}>
            <Text className='heading-7'>{id ? "" : "Tạo mẫu báo cáo"}</Text>
            <div className="col divider" />
        </div>
        <div className="row" style={{ alignItems: 'start', flex: 1 }}>
            <div className="col" style={{ flex: 1, padding: '2.4rem', height: '100%', backgroundColor: 'var(--light-background)', borderRadius: '2.4rem' }}>
                <Text className="heading-5" style={{ color: "#161C2499" }}>Báo cáo: {methods.watch("Name")}</Text>
                <div className='row filter-table' style={{ padding: '1.2rem 0', gap: '0.8rem', pointerEvents: "none" }}>
                    <TextField
                        placeholder='Tìm kiếm'
                        prefix={<FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.4rem', color: '#161D2470' }} />}
                        style={{ padding: '0.4rem 1.6rem', borderRadius: '0.6rem', height: '3.2rem', backgroundColor: '#fff' }}
                    />
                    <button className='row button-neutral' style={{ padding: '0.5rem 1rem' }}>
                        <FontAwesomeIcon icon={faListUl} />
                        <Text className='button-text-3'>Bộ lọc</Text>
                    </button>
                </div>
                <div style={{ overflow: 'auto', width: '100%', flex: 1, height: '100%' }}>
                    {renderTableDemo()}
                </div>
                <div style={{ padding: '1.2rem 2.4rem', pointerEvents: "none" }}>
                    <Pagination
                        currentPage={1}
                        itemPerPage={10}
                        totalItem={data?.total ?? 0}
                    />
                </div>
            </div>
            <div className="col" style={{ height: '100%', width: '36rem', overflow: 'hidden auto', gap: '2rem', padding: '2.4rem' }}>
                <TextFieldForm
                    required
                    name="Name"
                    register={methods.register}
                    errors={methods.formState.errors}
                    label="Tên mẫu báo cáo"
                    style={{ gap: '1.2rem' }}
                    onBlur={(ev) => methods.setValue("Name", ev.target.value)}
                />
                <TextAreaForm
                    name="Description"
                    register={methods.register}
                    errors={methods.formState.errors}
                    label="Mô tả"
                    style={{ height: '16rem', gap: '1.2rem' }}
                />
                <button type="button" className="row" style={{ padding: '0.4rem', gap: '0.8rem' }} onClick={showAddColGroupPopup}>
                    <FontAwesomeIcon icon={faPlus} style={{ fontSize: '1.6rem', color: 'var(--primary-color)' }} />
                    <Text className="button-text-4" style={{ color: 'var(--primary-color)', fontWeight: '600' }}>Thêm cột dữ liệu</Text>
                </button>
                <div className="col">
                    {cols.map((_col) => {
                        return <div key={_col.Id} className="row" style={{ gap: '0.8rem', padding: "1rem 0" }}>
                            <Text className="label-3" style={{ flex: 1 }}>{_col.Title ?? _col.Name}</Text>
                            <Switch
                                disabled={_col.Name.toLowerCase() === "id"}
                                value={_col.Name.toLowerCase() === "id" ? false : methods.watch("Columns").includes(_col.Name)}
                                onChange={(v) => {
                                    let _columns = methods.getValues("Columns")
                                    if (v) methods.setValue("Columns", [..._columns, _col.Name])
                                    else methods.setValue("Columns", _columns.filter(e => e !== _col.Name))
                                }}
                            />
                        </div>
                    })}
                    {_reducers.fields.map((_red, i) => {
                        if (_red.isDeleted) return <div key={_red.Id} />
                        return <div key={_red.Id} className="row" style={{ gap: '0.8rem', padding: '1rem 0' }}>
                            <Text className="label-3" style={{ flex: 1 }}>{_red.Title ?? _red.Name}</Text>
                            {/* <Switch
                                disabled={_col.Name.toLowerCase() === "id"}
                                value={_col.Name.toLowerCase() === "id" ? false : methods.watch("Columns").includes(_col.Name)}
                                onChange={(v) => {
                                    let _columns = methods.getValues("Columns")
                                    if (v) methods.setValue("Columns", [..._columns, _col.Name])
                                    else methods.setValue("Columns", _columns.filter(e => e !== _col.Name))
                                }}
                            /> */}
                            <button type="button" className="row icon-button28" onClick={() => {
                                _reducers.update(i, { ..._red, isDeleted: true })
                                setData({
                                    total: data.total,
                                    data: data.data.map(e => {
                                        delete e[_red.Name]
                                        return e
                                    })
                                })
                            }}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    })}
                </div>
            </div>
        </div>
        <div className="row" style={{ padding: '1.2rem 2.4rem', borderTop: "var(--border-grey1)", gap: '0.8rem' }}>
            <button to="button" className="row button-grey" onClick={() => {
                showDialog({
                    ref: dialogRef,
                    alignment: DialogAlignment.center,
                    status: ComponentStatus.WARNING,
                    title: "Bạn có chắc chắn muốn thoát",
                    content: "Tất cả thông tin đã nhập sẽ không được lưu vào hệ thống.",
                    onSubmit: () => { onSelect(undefined) }
                })
            }}>
                <Text className="button-text-3" style={{ color: "#161C24D9" }}>Hủy</Text>
            </button>
            <div style={{ flex: 1 }} />
            <button type="button" className={`row button-primary`} onClick={methods.handleSubmit(_onSubmit)}>
                <Text className="button-text-3">Tạo mới</Text>
            </button>
        </div>
    </div>
}

const AddColGroup = forwardRef(function AddColGroup(data, ref) {
    const methods = useForm({ shouldFocusError: false })
    const [fkKeys, setFkKeys] = useState([])

    const _onSubmit = (ev) => {
        const newItem = {
            ...ev,
            Id: randomGID(),
        }
        data.onSubmit(newItem)
        closePopup(ref)
    }

    return <div className="col" style={{ flex: 1, height: '100%' }}>
        <div className="col" style={{ flex: 1, height: '100%', padding: '2.4rem', gap: '1.6rem' }}>
            <TextFieldForm
                required
                label="Key name"
                name="Name"
                style={{ gap: '0.8rem' }}
                errors={methods.formState.errors}
                register={methods.register}
            />
            <TextFieldForm
                required
                label="Title"
                name="Title"
                style={{ gap: '0.8rem' }}
                errors={methods.formState.errors}
                register={methods.register}
            />
            <div className="row" style={{ gap: '1.6rem' }}>
                <Select1Form
                    required
                    label="TableFK"
                    name="RelativeId"
                    style={{ gap: '0.8rem', flex: 1 }}
                    control={methods.control}
                    errors={methods.formState.errors}
                    options={data.relatives.map((el) => {
                        return {
                            id: el.Id,
                            name: el.TableFK
                        }
                    })}
                    onChange={(v) => {
                        if (v) {
                            const _colController = new TableController(data.pid, "column")
                            _colController.getListSimple(
                                {
                                    page: 1,
                                    size: 50,
                                    query: `@TableName:{${v.name}}`,
                                    returns: ['Id', 'Name', 'DataType']
                                }
                            ).then((res) => {
                                if (res.code === 200) setFkKeys(res.data)
                                else {
                                    setFkKeys([])
                                    ToastMessage.errors(res.message)
                                }
                            })
                        }
                    }}
                />
                <Select1Form
                    required
                    label="Reducer"
                    name="Reducer"
                    style={{ gap: '0.8rem', flex: 1 }}
                    control={methods.control}
                    errors={methods.formState.errors}
                    options={EventStep.REDUCE.EXPRESSION.map((el) => {
                        return {
                            id: el,
                            name: el
                        }
                    })}
                    onChange={(v) => { if (v && v.id === Reducers.COUNT) methods.setValue("DataType", FEDataType.NUMBER) }}
                />
                {methods.watch("Reducer") && methods.watch("Reducer") !== Reducers.COUNT ? <Select1Form
                    required
                    label="By"
                    name="ReducerBy"
                    style={{ gap: '0.8rem', flex: 1 }}
                    control={methods.control}
                    errors={methods.formState.errors}
                    options={fkKeys.map((el) => {
                        return {
                            id: `${el.Name}`,
                            name: el.Name,
                            DataType: el.DataType
                        }
                    })}
                    onChange={(v) => { if (v) methods.setValue("DataType", v.DataType) }}
                /> : <div style={{ flex: 1 }} />}
            </div>
        </div>
        <div className="row popup-footer">
            <Text className="button-text-3" onClick={() => { closePopup(ref) }}>Hủy</Text>
            <div style={{ flex: 1 }} />
            <button type="button" className="row button-primary" onClick={methods.handleSubmit(_onSubmit)}>
                <Text className="button-text-3">Xác nhận</Text>
            </button>
        </div>
    </div>
})