import { forwardRef, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { TableController } from "../screen/home/table/controller"
import { ModuleActions } from "../screen/module/reducer"
import { closePopup, Popup, Select1, showPopup, Text, TextField, ToastMessage } from "wini-web-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp, faFileCirclePlus } from "@fortawesome/free-solid-svg-icons"
import { randomGID, Ultis } from "../Utils"
import { Select1Form, TextAreaForm, TextFieldForm } from "../project-component/component-form"
import { useForm } from "react-hook-form"
import { ToolType } from "../screen/home/table/da"
import { ProjectActions } from "../screen/home/project/reducer"
import * as solidIcon from '@fortawesome/free-solid-svg-icons';
import * as brandIcon from '@fortawesome/free-brands-svg-icons';
import * as regularIcon from '@fortawesome/free-regular-svg-icons';

export default function ActionSidebar() {
    const project = useSelector((store) => store.project?.data)
    const _listModule = [
        {
            id: 'admin',
            name: 'Admin',
            Icon: "user-tie"
        },
        {
            id: 'table',
            parentId: 'admin',
            name: 'Table',
        },
    ]
    const { moduleName } = useParams()
    const [listModule, setListM] = useState([])
    const selectedM = useSelector((store) => store.module.data)
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _pageId = searchParams.get("pageid")
    const _menuController = new TableController(_pid, "menu")
    const [onEdit, setOnEdit] = useState()
    const popupRef = useRef()
    const dispatch = useDispatch()

    useEffect(() => {
        if (_pid) {
            ProjectActions.getInfor(dispatch, _pid)
            _menuController.getAll().then(res => {
                if (res.code === 200 && res.data) {
                    const _menu = sortMenu(res.data)
                    setListM([..._listModule, ..._menu.map((e) => {
                        return {
                            ...e,
                            id: e.Name,
                            name: e.Name,
                        }
                    })])
                } else navigate('/')
            })
        }
    }, [_pid])

    const sortMenu = (_list) => {
        return _list.sort((a, b) => {
            const _pA = a.ParentId ? _list.find(el => el.Id === a.ParentId) : undefined
            const _pB = a.ParentId === b.ParentId ? _pA : b.ParentId ? _list.find(el => el.Id === b.ParentId) : undefined
            a.parentId = _pA ? Ultis.toSlug(_pA.Name) : undefined
            b.parentId = _pB ? Ultis.toSlug(_pB.Name) : undefined
            if (a.ParentId && !b.ParentId) return -1
            else if (!a.ParentId && b.ParentId) return 1
            else if (a.ParentId === b.ParentId) return a.Sort - b.Sort
            else return _pA.Sort - _pB.Sort
        })
    }

    useEffect(() => {
        if (listModule.length) {
            const _m = listModule.find(e => {
                if (!_pageId) {
                    return moduleName === e.id
                } else if (window.location.pathname.startsWith("/manager/")) {
                    return _pageId === e.TableId
                } else {
                    return _pageId === e.PageId
                }
            })
            if (_m) ModuleActions.setSelectedM(dispatch, _m)
            else navigate('/')
        }
    }, [_pageId, listModule.length])

    const handleRightClick = (ev, item) => {
        const _sameLevel = listModule.filter(e => e.ParentId === item.ParentId && e.id !== "admin" && e.parentId !== "admin")
        const _index = _sameLevel.findIndex(e => e.Id === item.Id)
        switch (ev.type) {
            case 'contextmenu':
                ev.preventDefault()
                showPopup({
                    ref: popupRef,
                    clickOverlayClosePopup: true,
                    style: { left: ev.pageX, top: ev.pageY, borderRadius: '0.4rem' },
                    content: <div className="col popup-actions" style={{ maxHeight: 'fit-content' }}>
                        {!item.ParentId && !item.TableId ? <button type="button"
                            onClick={() => {
                                closePopup(popupRef)
                                addMenuItem(item.Id)
                            }}>
                            <Text className="button-text-3">Tạo module con</Text>
                        </button> : null}
                        {_index > 0 ? <button type="button" onClick={() => {
                            const _sortList = [item, ..._sameLevel.filter(e => e.Id !== item.Id)]
                            updateSort(_sortList)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Chuyển lên đầu</Text>
                        </button> : null}
                        {_index > 0 ? <button type="button" onClick={() => {
                            const _sortList = [..._sameLevel.slice(0, _index - 1), item, ..._sameLevel.slice(_index - 1).filter(e => e.Id !== item.Id)]
                            updateSort(_sortList)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Lên 1 bậc</Text>
                        </button> : null}
                        {(_index + 1) < _sameLevel.length ? <button type="button" onClick={() => {
                            const _sortList = [..._sameLevel.slice(0, _index), _sameLevel[_index + 1], item, ..._sameLevel.slice(_index + 2)]
                            updateSort(_sortList)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Xuống 1 bậc</Text>
                        </button> : null}
                        {(_index + 1) < _sameLevel.length ? <button type="button" onClick={() => {
                            const _sortList = [..._sameLevel.filter(e => e.Id !== item.Id), item]
                            updateSort(_sortList)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Chuyển xuống cuối</Text>
                        </button> : null}
                        <button type="button" onClick={() => {
                            setOnEdit({ id: item.id, target: ev.target.querySelector("input") })
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Đổi tên</Text>
                        </button>
                        <button type="button" onClick={() => {
                            deleteMennuItem(item)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Xóa</Text>
                        </button>
                    </div >
                })
                break;
            default:
                break;
        }
    }

    const updateSort = (sortList = []) => {
        const _sortList = sortList.map((e, i) => {
            let _item = { ...e, Sort: i }
            delete _item.id
            delete _item.name
            delete _item.parentId
            return _item
        })
        _menuController.edit(_sortList)
        setListM(sortMenu(listModule.map(e => {
            const _item = _sortList.find(el => el.Id === e.Id)
            return { ...e, ..._item }
        })))
    }

    const addMenuItem = (parentId) => {
        showPopup({
            ref: popupRef,
            heading: <div className="heading-7 popup-header">Tạo mới module</div>,
            content: <AddModulePopup
                ref={popupRef}
                pid={_pid}
                parentId={parentId}
                onSubmit={async (_newItem) => {
                    debugger
                    const _sameLevel = listModule.filter(e => e.ParentId === parentId && e.id !== "admin" && e.parentId !== "admin")
                    _newItem.Sort = _sameLevel.length
                    const res = await _menuController.add([
                        ..._sameLevel.map((e, i) => {
                            let _item = { ...e, Sort: i }
                            delete _item.id
                            delete _item.name
                            delete _item.parentId
                            return _item
                        }),
                        _newItem
                    ])
                    if (res.code !== 200) return
                    ToastMessage.success('Tạo mới module thành công')
                    _newItem.id = _newItem.Name
                    _newItem.parentId = _newItem.ParentId ? Ultis.toSlug(listModule.find(e => e.Id === _newItem.ParentId)?.Name) : undefined
                    _newItem.name = _newItem.Name
                    setListM([...listModule, _newItem])
                    setTimeout(() => {
                        if (_newItem.PageId === ToolType.Manager) {
                            navigate(`/manager/${_newItem.parentId ? `${_newItem.parentId}/${_newItem.id}` : _newItem.id}?pid=${project.Id}&pageid=${_newItem.TableId}`)
                        } else {
                            navigate(`/page/${_newItem.parentId ? `${_newItem.parentId}/${_newItem.id}` : _newItem.id}?pid=${project.Id}&pageid=${_newItem.PageId}`)
                        }
                    }, 800)
                }}
            />
        })
    }

    const deleteMennuItem = (item) => {
        const children = listModule.filter(e => e.ParentId === item.Id).map(e => e.Id)
        _menuController.delete([item.Id, ...children]).then(res => {
            if (res.code === 200) {
                const _sameLevel = listModule.filter(e => e.ParentId === item.ParentId)
                let _index = _sameLevel.findIndex(e => e.Id === item.Id)
                if (_index === 0) _index = 1
                else _index = _index - 1
                if (_index < 0) _index += 2
                let _selectedM = _sameLevel[_index]
                const _newMenu = listModule.filter((e) => e.Id !== item.Id && children.every(id => id !== e.Id))
                if (!_selectedM?.TableId) _selectedM = _newMenu.find(e => e.PageId)
                if (!_selectedM?.PageId) {
                    navigate(`/admin/table?pid=${project.Id}`)
                } else if (_selectedM.PageId === ToolType.Manager) {
                    navigate(`/manager/${_selectedM.parentId ? `${_selectedM.parentId}/${_selectedM.id}` : _selectedM.id}?pid=${project.Id}&pageid=${_selectedM.TableId}`)
                } else {
                    navigate(`/page/${_selectedM.parentId ? `${_selectedM.parentId}/${_selectedM.id}` : _selectedM.id}?pid=${project.Id}&pageid=${_selectedM.PageId}`)
                }
            } else {
                ToastMessage.errors(res.message)
            }
        })
    }

    useEffect(() => {
        if (onEdit?.target) onEdit.target.focus()
    }, [onEdit])

    const renderModuleTile = (item) => {
        if (!item.parentId) var children = listModule.filter(e => e.parentId === Ultis.toSlug(item.id))
        // 
        const isSelected = selectedM.id === item.id || children?.some(e => e.id === selectedM.id)
        if (children && item.isOpen == undefined) var _isOpen = isSelected
        return <div key={item.id} className="col">
            <button type="button" className={`row module-tile ${!children?.length && isSelected ? 'selected' : ''}`}
                onContextMenu={item.Id ? (ev) => { handleRightClick(ev, item) } : undefined}
                onClick={() => {
                    if (item.parentId || item.TableId || item.PageId) {
                        if (!item.PageId) {
                            navigate(`/admin/${item.id}?pid=${project.Id}`)
                        } else if (item.PageId === ToolType.Manager) {
                            navigate(`/manager/${item.parentId ? `${item.parentId}/${item.id}` : item.id}?pid=${project.Id}&pageid=${item.TableId}`)
                        } else {
                            navigate(`/page/${item.parentId ? `${item.parentId}/${item.id}` : item.id}?pid=${project.Id}&pageid=${item.PageId}`)
                        }
                    } else {
                        setListM(listModule.map(e => {
                            if (e.id === item.id) e.isOpen = !(item.isOpen ?? _isOpen)
                            return e
                        }))
                    }
                }}>
                {item.Icon ? <FontAwesomeIcon icon={item.Icon} style={{ width: '2rem' }} /> : <div className="prefix" />}
                <TextField
                    className="semibold2"
                    style={{ borderColor: item.id !== onEdit?.id ? "transparent" : undefined, textOverflow: 'ellipsis' }}
                    defaultValue={item.name}
                    autoFocus
                    disabled={item.id !== onEdit?.id}
                    onBlur={async (ev) => {
                        if (ev.target.value.trim().length) {
                            const _updateItem = {
                                Id: item.Id,
                                DateCreated: item.DateCreated,
                                Icon: item.Icon,
                                ParentId: item.ParentId,
                                TableId: item.TableId,
                                Sort: item.Sort,
                                Name: ev.target.value.trim(),
                                Type: item.Type
                            }
                            _menuController.edit([_updateItem]).then(res => {
                                if (res.code === 200) {
                                    setListM(listModule.map(e => {
                                        if (e.Id === item.Id) {
                                            e.Name = ev.target.value.trim()
                                            e.name = ev.target.value.trim()
                                        }
                                        return e
                                    }))
                                    if (_updateItem.Id === selectedM.Id) ModuleActions.setSelectedM(dispatch, { ...selectedM, ..._updateItem })
                                } else {
                                    ev.target.value = item.name
                                    ToastMessage.errors(res.message)
                                }
                            })
                        } else {
                            if (item.name !== ev.target.value) {
                                ev.target.value = item.name
                            } else {
                                setListM(listModule.filter(e => e.name.length))
                            }
                        }
                        if (onEdit) setOnEdit(undefined)
                    }}
                />
                {children?.length ? <FontAwesomeIcon icon={item.isOpen || _isOpen ? faChevronUp : faChevronDown} style={{ fontSize: '1.2rem' }} /> : undefined}
            </button>
            {item.isOpen || _isOpen ? children?.map(e => renderModuleTile(e)) : undefined}
        </div>
    }

    return <>
        <Popup ref={popupRef} />
        <div className="row" style={{ gap: '0.4rem' }}>
            <Text maxLine={1} className="semibold3" style={{ flex: 1 }}>{project?.Name ?? "-"}</Text>
            <button type="button" className="icon-button24 row" onClick={() => { addMenuItem() }} >
                <FontAwesomeIcon icon={faFileCirclePlus} />
            </button>
        </div>
        <div className="col divider" style={{ margin: '0' }} />
        {selectedM ? listModule.filter(e => !e.parentId).map(e => renderModuleTile(e)) : <div />}
    </>
}

const AddModulePopup = forwardRef(function AddModulePopup(data, ref) {
    const methods = useForm({ shouldFocusError: false, defaultValues: { PageId: ToolType.Manager } })
    const _tbController = new TableController(data.pid, "table")
    const _pageController = new TableController(data.pid, "page")
    const [tables, setTables] = useState([])
    const [pages, setPages] = useState([])
    // const iconNames = [...Object.keys(solidIcon), ...Object.keys(brandIcon), ...Object.keys(regularIcon)].filter(key => key.startsWith('fa')).map(icon => Ultis.convertToKebabCase(icon.replace('fa', '')))

    const onSubmit = async (ev) => {
        const _newItem = {
            Id: randomGID(),
            ...ev,
            DateCreated: (new Date()).getTime(),
            ParentId: data.parentId,
        }
        data.onSubmit(_newItem)
        closePopup(ref)
    }

    useEffect(() => {
        _pageController.getAll().then(res => {
            if (res.code === 200) setPages(res.data)
        })
    }, [])

    useEffect(() => {
        if (!tables.length && methods.getValues("PageId") === ToolType.Manager) {
            _tbController.getAll().then(res => {
                if (res.code === 200) {
                    setTables(res.data)
                } else {
                    ToastMessage.errors(res.message)
                }
            })
        }
    }, [methods.watch("PageId")])

    return <div className="col" style={{ flex: 1 }}>
        <div className="col" style={{ padding: '1.6rem', gap: '1.6rem', overflow: 'hidden auto', flex: 1, width: '48rem' }}>
            <div className="row" style={{ gap: '1.2rem' }}>
                <TextFieldForm
                    name={"Icon"}
                    style={{ width: '12rem' }}
                    placeholder={"Prefix icon"}
                    register={methods.register}
                    errors={methods.formState.errors}
                />
                <TextFieldForm
                    required
                    style={{ flex: 1 }}
                    name={"Name"}
                    placeholder={"Tên module"}
                    register={methods.register}
                    errors={methods.formState.errors}
                />
            </div>
            <Select1Form
                required
                name={"PageId"}
                placeholder={"Chọn page"}
                control={methods.control}
                errors={methods.formState.errors}
                hideSearch
                options={[{ id: ToolType.Manager, name: ToolType.Manager }, ...pages.map(e => {
                    return {
                        id: e.Id,
                        name: e.Name
                    }
                })]}
            />
            {methods.watch("PageId") === ToolType.Manager && <Select1Form
                required={data.parentId != undefined}
                name={"TableId"}
                placeholder={"Chọn table"}
                control={methods.control}
                errors={methods.formState.errors}
                options={tables.map(e => {
                    return {
                        id: e.Name,
                        name: e.Name
                    }
                })}
            />}
            <TextAreaForm
                name={"Description"}
                placeholder={"Mô tả ngắn gọn về module này..."}
                register={methods.register}
                errors={methods.formState.errors}
            />
        </div>
        <div className="row popup-footer">
            <Text className="button-text-3" onClick={() => { closePopup(ref) }}>Hủy</Text>
            <div style={{ flex: 1 }} />
            <button
                type="button"
                className={`row ${(methods.watch('Name')?.length && (!data.parentId || methods.watch('PageId')) ? 'button-primary' : 'button-disabled')}`}
                onClick={methods.handleSubmit(onSubmit)}
            >
                <Text className="button-text-3">Xác nhận</Text>
            </button>
        </div>
    </div>
})