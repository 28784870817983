import { BaseDA } from "../../../da/baseDA";
import ConfigApi from "../../../da/configApi";
import { LayerItem, PageItem } from "../../module/da";
import { TableItem, ColumnItem, RelativeItem, MenuItem } from "./da";

export class TableController {
    private pid: string;
    private module: "table" | "column" | "rel" | "menu" | "page" | "layer";
    constructor(pid: string, module: "table" | "column" | "rel" | "menu" | "page" | "layer") {
        this.pid = pid
        this.module = module
    }

    async getAll() {
        const res = await BaseDA.get(ConfigApi.url + 'setting/getAll', {
            headers: {
                pid: this.pid,
                module: this.module,
            },
        })
        return res
    }

    async getByListId(ids: Array<string>) {
        const res = await BaseDA.post(ConfigApi.url + 'setting/getByIds', {
            headers: {
                pid: this.pid,
                module: this.module,
            },
            body: { ids: ids }
        })
        return res
    }

    async getListSimple(options?: { page?: number, size?: number, query?: string, returns?: Array<string>, sortby?: { BY: string, DIRECTION?: "ASC" | "DESC" } }) {
        const res = await BaseDA.post(ConfigApi.url + 'setting/getListSimple', {
            headers: {
                pid: this.pid,
                module: this.module,
            },
            body: { searchRaw: options?.query ?? "*", page: options?.page ?? 1, size: options?.size ?? 10, returns: options?.returns, sortby: options?.sortby }
        })
        return res
    }

    async add(data: Array<TableItem | ColumnItem | RelativeItem | MenuItem | PageItem | LayerItem>) {
        const res = await BaseDA.post(ConfigApi.url + 'setting/action?action=add', {
            headers: {
                pid: this.pid,
                module: this.module
            },
            body: { data: data }
        })
        return res
    }

    async edit(data: Array<TableItem | ColumnItem | RelativeItem | MenuItem | PageItem | LayerItem>) {
        const res = await BaseDA.post(ConfigApi.url + 'setting/action?action=edit', {
            headers: {
                pid: this.pid,
                module: this.module
            },
            body: { data: data }
        })
        return res
    }

    async delete(ids: Array<string>) {
        const res = await BaseDA.post(ConfigApi.url + 'setting/action?action=delete', {
            headers: {
                pid: this.pid,
                module: this.module
            },
            body: { ids: ids }
        })
        return res
    }
}