import { faUserCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Route, Routes, useLocation, useParams } from "react-router-dom"
import { ListProject } from "../screen/home/project/view"
import CreateProject from "../screen/home/project/create"
import ModuleListView from "../screen/module/manager/view"
import { ComponentType, ToolType } from "../screen/home/table/da"
import { useDispatch, useSelector } from "react-redux"
import { CustomerActions } from "../screen/home/customer/reducer"
import ModuleDashboardView from "../screen/module/dashboard/view"
import ActionSidebar from "./menu"
import SettingPageView from "../screen/module/page/view"
import ListTable from "../screen/home/table/view"
import { TableController } from "../screen/home/table/controller"
import { PageActions } from "../screen/module/page/reducer"
import { Text } from "wini-web-components"
import RenderPageByLayers from "../screen/module/page/renderPageView"


export const Home = () => {
    const location = useLocation()

    return location.pathname === '/' ? <div></div> : <div className="col main-layout">
        <div className="body" >
            {location.pathname.startsWith('/home') ? <div className="col sidebar"><HomeSidebar /></div> : null}
            <Routes>
                <Route
                    path={'/admin/table/:pName'}
                    element={<AdminView />}
                    exact
                />
                <Route
                    path={'/pages/:pName'}
                    element={<SettingPageView />}
                    exact
                />
                <Route
                    path={'/home/project/add'}
                    element={<CreateProject />}
                    exact
                />
                <Route
                    path={'/home'}
                    element={<ListProject />}
                    exact
                />
                <Route
                    path={'/admin/:moduleName'}
                    element={<AdminView />}
                    exact
                />
                <Route
                    path={'/manager/:pModuleName?/:moduleName'}
                    element={<ManagerView />}
                    exact
                />
                <Route
                    path={'/page/:pageName'}
                    element={<PageView />}
                    exact
                />
            </Routes>
        </div>
    </div>
}

const HomeSidebar = () => {
    const user = useSelector((store) => store.customer.data)
    const dispatch = useDispatch()

    useEffect(() => {
        CustomerActions.getInfor(dispatch, "b081a926bb0c4318aebce949de0ce998")
    }, [])

    return <div className="col sidebar">
        <div className="row">
            <div className="icon-button60 row">
                <FontAwesomeIcon icon={faUserCircle} />
            </div>
            <div className="col" style={{ gap: '0.4rem' }}>
                <Text className="title-3">{user?.Name ?? "-"}</Text>
                <Text className="subtitle-4">{user?.Email ?? "-"}</Text>
            </div>
        </div>
    </div>
}

const AdminView = () => {
    return <>
        <div className="col sidebar"><ActionSidebar /></div>
        <ListTable />
    </>
}

const ManagerView = () => {
    const onLoading = useSelector((store) => store.module.onLoading)
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _pageId = searchParams.get("pageid")
    const [table, setTable] = useState()

    useEffect(() => {
        const _tbController = new TableController(_pid, "table")
        _tbController.getByListId([_pageId]).then(res => {
            if (res.code === 200) setTable(res.data[0])
            else setTable(undefined)
        })
    }, [_pid, _pageId])

    return <>
        <div className="col sidebar"><ActionSidebar /></div>
        {onLoading || !table ? <div /> : <ModuleListView module={table.Name} />}
    </>
}

const PageView = () => {
    const onLoading = useSelector((store) => store.page.onLoading)
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _pageId = searchParams.get("pageid")
    const dispatch = useDispatch()

    useEffect(() => {
        PageActions.onReset(dispatch)
        const _pageController = new TableController(_pid, "page")
        _pageController.getByListId([_pageId]).then(res => {
            if (res.code === 200) PageActions.setSelectedPage(dispatch, res.data[0])
        })
    }, [_pid])

    return <>
        <div className="col sidebar"><ActionSidebar /></div>
        {onLoading ? <div /> : <RenderPageByLayers />}
    </>
}

