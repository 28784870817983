import { forwardRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Text, closePopup } from "wini-web-components";
import { FEDataType } from "../../home/table/da";
import { RangeForm, TextFieldForm } from "../../../project-component/component-form";
import { Ultis } from "../../../Utils";

const PopupFormFilter = forwardRef(function PopupFormFilter(data, ref) {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const methods = useForm({ shouldFocusError: false, defaultValues: { pid: data.pid } })
    const navigate = useNavigate()

    const onSubmit = (ev) => {
        closePopup(ref)
        const params = new URLSearchParams();
        for (const key in ev) {
            if (ev[key]?.length) {
                if (key !== 'pid') {
                    const _col = data.cols.find(e => e.Name === key.replace('_min', "").replace('_max', ""))
                    switch (_col.DataType) {
                        case FEDataType.NUMBER:
                            params.append(key, typeof ev[key] === 'string' ? parseFloat(ev[key]) : ev[key])
                            break;
                        case FEDataType.DATE:
                            params.append(key, Ultis.stringToDate(ev[key]).getTime())
                            break;
                        case FEDataType.DATETIME:
                            params.append(key, Ultis.stringToDate(ev[key], 'dd/mm/yyyy hh:mm:ss').getTime())
                            break;
                        case FEDataType.MONEY:
                            params.append(key, parseInt(ev[key].replaceAll(',', '')))
                            break;
                        default:
                            params.append(key, ev[key])
                            break;
                    }
                } else {
                    params.append(key, ev[key])
                }
            }
        }
        navigate('?' + params.toString());
    }

    useEffect(() => {
        for (const [key, value] of searchParams) {
            if (key !== 'pid') {
                const _col = data.cols.find(e => e.Name === key.replace('_min', "").replace('_max', ""))
                switch (_col?.DataType) {
                    case FEDataType.NUMBER:
                        methods.setValue(key, typeof value === 'string' ? parseFloat(value) : value)
                        break;
                    case FEDataType.DATE:
                        methods.setValue(key, Ultis.datetoString(new Date(typeof value === 'string' ? parseInt(value) : value)))
                        break;
                    case FEDataType.DATETIME:
                        methods.setValue(key, Ultis.datetoString(new Date(typeof value === 'string' ? parseInt(value) : value), 'dd/mm/yyyy hh:mm:ss'))
                        break;
                    case FEDataType.MONEY:
                        methods.setValue(key, Ultis.money(value))
                        break;
                    case FEDataType.BOOLEAN:
                        methods.setValue(key, value == true)
                        break;
                    default:
                        methods.setValue(key, value)
                        break;
                }
            } else {
                methods.setValue(key, value)
            }
        }
    }, [])

    return <form className="col" style={{ flex: 1, width: '100%', height: '100%' }}>
        <div className="col" style={{ flex: 1, width: '100%', height: '100%', padding: '2.4rem', gap: '2rem', overflow: 'hidden auto' }}>
            {data.cols.map((e, i) => {
                switch (e.DataType) {
                    case FEDataType.STRING:
                        return <TextFieldForm
                            key={e.Id}
                            className={'row'}
                            label={e.Setting.Title}
                            name={e.Name}
                            style={e.DataType === FEDataType.MONEY}
                            type={e.DataType === FEDataType.PASSWORD ? 'password' : e.DataType === FEDataType.NUMBER ? 'number' : e.DataType === FEDataType.MONEY ? 'money' : 'text'}
                            register={methods.register}
                            errors={methods.formState.errors}
                        />
                    default:
                        if ([FEDataType.DATE, FEDataType.DATETIME, FEDataType.NUMBER, FEDataType.MONEY].includes(e.DataType)) {
                            return <RangeForm
                                key={e.Id}
                                className={'row'}
                                type={e.DataType === FEDataType.DATE ? 'date' : e.DataType === FEDataType.DATETIME ? 'date-time' : e.DataType === FEDataType.MONEY ? 'money' : 'number'}
                                startName={`${e.Name}_min`}
                                endName={`${e.Name}_max`}
                                control={methods.control}
                                label={e.Setting.Title}
                                errors={methods.formState.errors}
                                getValues={methods.getValues}
                            />
                        } else return <div key={e.Id} />
                }
            })}
        </div>
        <div className="row popup-footer">
            <button type="button" className="row button-grey" onClick={() => { methods.reset() }}>
                <Text className="button-text-3">Xóa tất cả</Text>
            </button>
            <button type="button" className="row button-primary" onClick={methods.handleSubmit(onSubmit)}>
                <Text className="button-text-3">Áp dụng</Text>
            </button>
        </div>
    </form>
})

export default PopupFormFilter