import { useEffect, useRef } from "react"
import { faEye, faEyeSlash, faMinus } from "@fortawesome/free-solid-svg-icons"
import styles from '../../componentProperties/functions/funtions.module.css'
import { Text, TextField } from "wini-web-components"
import { Ultis } from "../../../../../Utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


export const ColorPicker = ({ colorName, onDelete, colorOpcaityName, register, colorValue = '', opacity, onChange }) => {
    const colorPickerRef = useRef()

    useEffect(() => {
        const _colorPicker = colorPickerRef.current?.querySelector('input[type="color"]')
        if (colorValue && _colorPicker) _colorPicker.value = colorValue.substring(0, 7)
    }, [colorValue])

    return <div ref={colorPickerRef} className={`row ${styles['color-picker-container']}`} style={{ gap: '1.2rem' }}>
        <TextField
            className="regular1"
            name={colorName}
            register={register(colorName, {
                onBlur: (ev) => {
                    const newVl = ev.target.value.replaceAll("#", "").substring(0, 6)
                    const _opacityValue = colorPickerRef.current.querySelector('input[type="number"]')
                    onChange({ colorValue: `#${newVl}${Ultis.percentToHex(parseFloat(_opacityValue.value))}`, opacity: opacity })
                }
            })}
            style={{ flex: 1 }}
            placeholder={colorName}
            maxLength={7}
            prefix={<label className={styles['color-picker']} style={{ backgroundColor: colorValue }}>
                <input
                    type='color'
                    style={{ visibility: 'hidden' }}
                    onChange={(ev) => {
                        const _opacityValue = colorPickerRef.current.querySelector('input[type="number"]')
                        onChange({ colorValue: `${ev.target.value}${Ultis.percentToHex(parseFloat(_opacityValue.value))}`, opacity: opacity })
                    }}
                />
            </label>}
            suffix={<>
                <input
                    name={colorOpcaityName}
                    type='number'
                    className={styles['color-opacity']}
                    {...register(colorOpcaityName, {
                        onBlur: (ev) => {
                            let _vl = parseFloat(ev.target.value)
                            if (isNaN(_vl)) _vl = 10
                            const _colorValue = colorPickerRef.current?.querySelector('input[type="color"]').value
                            onChange({ colorValue: `${_colorValue}${Ultis.percentToHex(_vl)}`, opacity: opacity })
                        }
                    })}
                />
                <Text className='regular1'>%</Text>
            </>}
        />
        {opacity != undefined &&
            <button type='button' className='row icon-button24' onClick={() => { onChange({ colorValue: colorValue, opacity: opacity ? 0 : 1 }) }}>
                <FontAwesomeIcon icon={opacity ? faEye : faEyeSlash} style={{ fontSize: '1.2rem' }} />
            </button>}

        {onDelete &&
            <button type='button' className='row icon-button24' onClick={onDelete}>
                <FontAwesomeIcon icon={faMinus} style={{ fontSize: '1.2rem' }} />
            </button>}
    </div>
}
