import './view.css'
import PageView from './pageView'
import LeftView from './leftSidebar'
import RightView from './rightSidebar'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { PageActions } from './reducer'
import { TableController } from '../../home/table/controller'

export default function SettingPageView() {
    const { page, layers } = useSelector((store) => store.page.data)
    const _onLoading = useSelector((store) => store.page.onLoading)
    const [listPage, setListPages] = useState([])
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _pageId = searchParams.get("pageid")
    const dispatch = useDispatch()

    useEffect(() => {
        PageActions.onReset(dispatch)
        const _pageController = new TableController(_pid, "page")
        _pageController.getAll().then(res => {
            if (res.code === 200) setListPages(res.data)
        })
    }, [_pid])

    useEffect(() => {
        if (_pageId && listPage.length && page?.Id !== _pageId) {
            PageActions.setSelectedPage(dispatch, listPage.find(e => e.Id === _pageId))
        }
    }, [_pageId, page, listPage.length])

    return <div className="col setting-pages-view" style={{ flex: 1, width: '100%', height: '100%', backgroundColor: '#1d2428' }}>
        <div className="row setting-page-header"></div>
        <div className="row" style={{ flex: 1, height: '100%' }}>
            <LeftView listPage={listPage} />
            <PageView />
            <RightView />
        </div>
    </div>
}