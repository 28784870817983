import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { PageActions } from "../reducer"
import { onSelectedLayer } from "../pageView"
import { Select1, Text, TextField } from "wini-web-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faChevronDown, faPlus } from "@fortawesome/free-solid-svg-icons"
import { OutlineRadius4Angle, OutlineRadiusAll } from "../../../../assets/icon"
import { regexResponsiveClass } from "../../config"
import { Ultis } from "../../../../Utils"
import { ColorPicker } from "./functions/funtions"

export default function SettingChart({ selectedLayer }) {
    const methods = useForm({ shouldFocusError: false })
    const layers = useSelector((store) => store.page.data?.layers)
    const dispatch = useDispatch()
    const [radiusAll, setRadiusAll] = useState(true)

    const updateStyle = (style) => {
        let _newSettingStyle = { ...selectedLayer.Setting.style, ...style }
        for (const prop in _newSettingStyle) {
            if (_newSettingStyle[prop] == undefined) delete _newSettingStyle[prop]
        }
        PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: _newSettingStyle } }])
    }

    useEffect(() => {
        const _newElement = document.getElementById(selectedLayer?.Id)
        if (_newElement) {
            const _newStyle = window.getComputedStyle(_newElement)
            const _rootFz = parseFloat(window.getComputedStyle(document.documentElement)['font-size'].replace("px", ""))
            const _clN = [..._newElement.classList].find(e => regexResponsiveClass.test(e))

            const _borderColor = Ultis.rgbToHex(_newStyle.borderColor)
            methods.setValue('border-color', _borderColor.substring(0, 7))
            methods.setValue('border-color-opacity', `${Ultis.hexToPercent(_borderColor.substring(7))}`)
            if (selectedLayer.Setting.style.borderWidth?.includes("px")) {
                methods.setValue('border-width', _newStyle['border-width'].replace("px", ""))
                methods.setValue('border-unit', "px")
            } else if (selectedLayer.Setting.style.borderWidth?.includes("%")) {
                methods.setValue('border-width', parseFloat(_newStyle['border-width'].replace("%", "")))
                methods.setValue('border-unit', "%")
            } else {
                methods.setValue('border-width', (parseFloat(_newStyle['border-width'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                methods.setValue('border-unit', "rem")
            }
            methods.setValue('font-size', selectedLayer.Setting.style?.fontSize?.includes("px") ? _newStyle['font-size'].replace("px", "") : (parseFloat(_newStyle['font-size'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
            methods.setValue('font-size-unit', selectedLayer.Setting.style?.fontSize?.includes("px") ? "px" : "rem")
            methods.setValue('line-height', selectedLayer.Setting.style?.lineHeight?.includes("px") ? _newStyle['line-height'].replace("px", "") : _newStyle['line-height'] === "normal" ? "normal" : (parseFloat(_newStyle['line-height'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
            methods.setValue('line-height-unit', selectedLayer.Setting.style?.lineHeight?.includes("px") ? "px" : "rem")
            methods.setValue('letter-spacing', selectedLayer.Setting.style?.letterSpacing?.includes("px") ? _newStyle['letter-spacing'].replace("px", "") : _newStyle['letter-spacing'] === "normal" ? "normal" : (parseFloat(_newStyle['letter-spacing'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
            methods.setValue('letter-spacing-unit', selectedLayer.Setting.style?.letterSpacing?.includes("px") ? "px" : "rem")

            if (_clN) {
                methods.setValue('select-col', _clN)
                methods.setValue('width-unit', "flex")
            } else if (selectedLayer.Setting.style?.width?.includes("px")) {
                methods.setValue('width', _newStyle['width'].replace("px", ""))
                methods.setValue('width-unit', "px")
            } else if (selectedLayer.Setting.style?.width?.includes("%")) {
                methods.setValue('width', parseFloat(_newStyle['width'].replace("%", "")))
                methods.setValue('width-unit', "%")
            } else {
                methods.setValue('width', (parseFloat(_newStyle['width'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                methods.setValue('width-unit', "rem")
            }
            if (selectedLayer.Setting.style?.height?.includes("px")) {
                methods.setValue('height', _newStyle['height'].replace("px", ""))
                methods.setValue('height-unit', "px")
            } else if (selectedLayer.Setting.style?.height?.includes("%")) {
                methods.setValue('height', parseFloat(_newStyle['height'].replace("%", "")))
                methods.setValue('height-unit', "%")
            } else {
                methods.setValue('height', (parseFloat(_newStyle['height'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                methods.setValue('height-unit', "rem")
            }
            if (['borderTopLeftRadius', 'borderTopRightRadius', 'borderBottomLeftRadius', 'borderBottomRightRadius'].some((v) => selectedLayer.Setting.style[v] != undefined)) {
                if (selectedLayer.Setting.style?.borderTopLeftRadius?.includes("px")) {
                    var _vTL = _newStyle['border-top-left-radius'].replace("px", "")
                    var _vUnitTL = 'px'
                } else if (selectedLayer.Setting.style?.borderTopLeftRadius?.includes("%")) {
                    _vTL = parseFloat(_newStyle['border-top-left-radius'].replace("%", ""))
                    _vUnitTL = '%'
                } else {
                    _vTL = (parseFloat(_newStyle['border-top-left-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                    _vUnitTL = "rem"
                }
                if (selectedLayer.Setting.style?.borderTopRightRadius?.includes("px")) {
                    var _vTR = _newStyle['border-top-right-radius'].replace("px", "")
                    var _vUnitTR = 'px'
                } else if (selectedLayer.Setting.style?.borderTopRightRadius?.includes("%")) {
                    _vTR = parseFloat(_newStyle['border-top-right-radius'].replace("%", ""))
                    _vUnitTR = '%'
                } else {
                    _vTR = (parseFloat(_newStyle['border-top-right-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                    _vUnitTR = "rem"
                }
                if (selectedLayer.Setting.style?.borderBottomLeftRadius?.includes("px")) {
                    var _vBL = _newStyle['border-bottom-left-radius'].replace("px", "")
                    var _vUnitBL = 'px'
                } else if (selectedLayer.Setting.style?.borderBottomLeftRadius?.includes("%")) {
                    _vBL = parseFloat(_newStyle['border-bottom-left-radius'].replace("%", ""))
                    _vUnitBL = '%'
                } else {
                    _vBL = (parseFloat(_newStyle['border-bottom-left-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                    _vUnitBL = "rem"
                }
                if (selectedLayer.Setting.style?.borderBottomRightRadius?.includes("px")) {
                    var _vBR = _newStyle['border-bottom-right-radius'].replace("px", "")
                    var _vUnitBR = 'px'
                } else if (selectedLayer.Setting.style?.borderBottomRightRadius?.includes("%")) {
                    _vBR = parseFloat(_newStyle['border-bottom-right-radius'].replace("%", ""))
                    _vUnitBR = '%'
                } else {
                    _vBR = (parseFloat(_newStyle['border-bottom-right-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                    _vUnitBR = "rem"
                }
                methods.setValue('border-top-left-radius', _vTL)
                methods.setValue('border-top-left-radius-unit', _vUnitTL)
                methods.setValue('border-top-right-radius', _vTR)
                methods.setValue('border-top-right-radius-unit', _vUnitTR)
                methods.setValue('border-bottom-left-radius', _vBL)
                methods.setValue('border-bottom-left-radius-unit', _vUnitBL)
                methods.setValue('border-bottom-right-radius', _vBR)
                methods.setValue('border-bottom-right-radius-unit', _vUnitBR)
                methods.setValue('border-radius', _vTL)
                methods.setValue('border-radius-unit', _vUnitTL)
                if (radiusAll) setRadiusAll(false)
            } else {
                if (selectedLayer.Setting.style?.borderRadius?.includes("px")) {
                    var _v = _newStyle['border-radius'].replace("px", "")
                    var _vUnit = 'px'
                } else if (selectedLayer.Setting.style?.borderRadius?.includes("%")) {
                    _v = parseFloat(_newStyle['border-radius'].replace("%", ""))
                    _vUnit = '%'
                } else {
                    _v = (parseFloat(_newStyle['border-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                    _vUnit = "rem"
                }
                methods.setValue('border-radius', _v)
                methods.setValue('border-radius-unit', _vUnit)
                methods.setValue('border-top-left-radius', _v)
                methods.setValue('border-top-left-radius-unit', _vUnit)
                methods.setValue('border-top-right-radius', _v)
                methods.setValue('border-top-right-radius-unit', _vUnit)
                methods.setValue('border-bottom-left-radius', _v)
                methods.setValue('border-bottom-left-radius-unit', _vUnit)
                methods.setValue('border-bottom-right-radius', _v)
                methods.setValue('border-bottom-right-radius-unit', _vUnit)
                if (!radiusAll) setRadiusAll(true)
            }

            const _bgColor = Ultis.rgbToHex(_newStyle.backgroundColor ?? '#ffffffff')
            methods.setValue('background-color-value', _bgColor.substring(0, 7))
            methods.setValue('background-color-opacity', `${Ultis.hexToPercent(_bgColor.substring(7))}`)
            methods.setValue('opacity', parseFloat(_newStyle.opacity))


        }
        setTimeout(() => { onSelectedLayer(selectedLayer?.Id) }, 100)
    }, [selectedLayer])

    return <>
        <div className="row" style={{ borderBottom: '1px solid #444444', padding: '0.8rem 1.2rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>{selectedLayer.Name}</Text>
            <button type="button" className="row icon-button16"><FontAwesomeIcon icon={faBars} /></button>
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Box Properties</Text>
            <button type="button" className="row icon-button16"><FontAwesomeIcon icon={faChevronDown} /></button>
        </div>
        <div className="col" style={{ padding: '0.4rem 1.2rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>

            <div className="row" style={{ gap: '0.8rem' }}>
                <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                    <Text className="semibold11">Width</Text>
                    {methods.watch('width-unit') === 'flex' ? <Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-col"
                        value={methods.watch('select-col')}
                        options={['fixed', 'col-rest', 'col0', 'col1', 'col2'].map(e => {
                            return {
                                id: e,
                                name: e
                            }
                        })}
                        onChange={(ev) => {
                            const _tmp = methods.getValues('select-col')
                            if (_tmp == 'fixed') {
                                PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, className: selectedLayer.Setting.className.split(" ").filter(e => e !== _tmp).join(" ") } }])
                            } else if (_tmp !== ev.id) {
                                const { columnGap, gap } = layers.find(e => e.Id === selectedLayer.ParentId)?.Setting?.style
                                PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, className: selectedLayer.Setting.className.split(" ").map(e => e === _tmp ? ev.id : e).join(" "), style: { ...selectedLayer.Setting.style, '--gutter': columnGap ?? gap } } }])
                            }
                        }}
                    /> : <TextField
                        className="regular1"
                        name="width"
                        register={methods.register('width', { onBlur: (ev) => { updateStyle({ width: ev.target.value.trim() + methods.getValues('width-unit') }) } })}
                        placeholder="width"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('width-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }, { id: 'flex', name: 'flex' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('width-unit')
                                if (v.id === 'flex') {
                                    PageActions.updateLayerAll(dispatch, layers.map(e => {
                                        if (e.Id === selectedLayer.Id) return { ...e, Setting: { ...e.Setting, className: [...(e.Setting.className ?? "").split(" "), 'col-rest'].join(" ") } }
                                        else return e
                                    }))
                                } else
                                    if (_tmp !== v.id) {
                                        updateStyle({ width: v.id === 'rem' ? `${parseFloat(methods.getValues('width')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('width')) * 10}${v.id}` : `${parseFloat(methods.getValues('width'))}${v.id}` })
                                    }
                            }}
                        />}
                    />}


                </div>
                <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                    <Text className="semibold11">Height</Text>
                    <TextField
                        className="regular1"
                        name="height"
                        register={methods.register('height', { onBlur: (ev) => { updateStyle({ height: ev.target.value.trim() + methods.getValues('height-unit') }) } })}
                        placeholder="height"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('height-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('height-unit')
                                if (_tmp !== v.id) {
                                    updateStyle({ height: v.id === 'rem' ? `${parseFloat(methods.getValues('height')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('height')) * 10}${v.id}` : `${parseFloat(methods.getValues('height'))}${v.id}` })
                                }
                            }}
                        />}
                    />
                </div>
            </div>
            <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                <div className="row" style={{ gap: '0.8rem' }}>
                    <Text className="semibold11">Border radius</Text>
                    <div className="row" style={{ borderRadius: '0.6rem', border: '1px solid #444444' }}>
                        <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: radiusAll ? undefined : "#9c6334" }} onClick={() => setRadiusAll(false)}>
                            <OutlineRadius4Angle size={'1.1rem'} color={'#fff'} />
                        </button>
                        <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: radiusAll ? "#9c6334" : undefined }} onClick={() => setRadiusAll(true)}>
                            <OutlineRadiusAll size={'1.1rem'} color={'#fff'} />
                        </button>
                    </div>
                </div>
                {radiusAll ? <TextField
                    className="regular1"
                    name="border-radius"
                    register={methods.register('border-radius', { onBlur: (ev) => { updateStyle({ borderRadius: ev.target.value.trim() + methods.getValues('border-radius-unit') }) } })}
                    placeholder="Border radius"
                    type="number"
                    suffix={<Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('border-radius-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('border-radius-unit')
                            if (_tmp !== v.id) {
                                updateStyle({
                                    borderRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-radius'))}${v.id}`,
                                    borderTopLeftRadius: undefined,
                                    borderBottomLeftRadius: undefined,
                                    borderTopRightRadius: undefined,
                                    borderBottomRightRadius: undefined,
                                })
                            }
                        }}
                    />}
                /> : <div className="row" style={{ padding: '0.4rem 0', gap: '0.8rem', height: '8rem' }}>
                    <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                        <TextField
                            className="regular1"
                            name="border-top-left-radius"
                            register={methods.register('border-top-left-radius', {
                                onBlur: (ev) => {
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderTopLeftRadius: ev.target.value.trim() + methods.getValues('border-top-left-radius-unit')
                                    })
                                }
                            })}
                            placeholder="TL radius"
                            type="number"
                            suffix={<Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-unit-options"
                                value={methods.watch('border-top-left-radius-unit')}
                                options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                onChange={(v) => {
                                    const _tmp = methods.getValues('border-top-left-radius-unit')
                                    if (_tmp !== v.id) {
                                        updateStyle({
                                            borderRadius: undefined,
                                            borderTopLeftRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-top-left-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-top-left-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-top-left-radius'))}${v.id}`
                                        })
                                    }
                                }}
                            />}
                        />
                        <TextField
                            className="regular1"
                            name="border-bottom-left-radius"
                            register={methods.register('border-bottom-left-radius', {
                                onBlur: (ev) => {
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderBottomLeftRadius: ev.target.value.trim() + methods.getValues('border-bottom-left-radius-unit')
                                    })
                                }
                            })}
                            placeholder="BL radius"
                            type="number"
                            suffix={<Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-unit-options"
                                value={methods.watch('border-bottom-left-radius-unit')}
                                options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                onChange={(v) => {
                                    const _tmp = methods.getValues('border-bottom-left-radius-unit')
                                    if (_tmp !== v.id) {
                                        updateStyle({
                                            borderRadius: undefined,
                                            borderBottomLeftRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-bottom-left-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-bottom-left-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-bottom-left-radius'))}${v.id}`
                                        })
                                    }
                                }}
                            />}
                        />
                    </div>
                    <OutlineRadius4Angle size={'3.2rem'} color={'#fff'} />
                    <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                        <TextField
                            className="regular1"
                            name="border-top-right-radius"
                            register={methods.register('border-top-right-radius', {
                                onBlur: (ev) => {
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderTopRightRadius: ev.target.value.trim() + methods.getValues('border-top-right-radius-unit')
                                    })
                                }
                            })}
                            placeholder="TR radius"
                            type="number"
                            suffix={<Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-unit-options"
                                value={methods.watch('border-top-right-radius-unit')}
                                options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                onChange={(v) => {
                                    const _tmp = methods.getValues('border-top-right-radius-unit')
                                    if (_tmp !== v.id)
                                        updateStyle({
                                            borderRadius: undefined,
                                            borderTopRightRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-top-right-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-top-right-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-top-right-radius'))}${v.id}`
                                        })
                                }}
                            />}
                        />
                        <TextField
                            className="regular1"
                            name="border-bottom-right-radius"
                            register={methods.register('border-bottom-right-radius', {
                                onBlur: (ev) => {
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderBottomRightRadius: ev.target.value.trim() + methods.getValues('border-bottom-right-radius-unit')
                                    })
                                }
                            })}
                            placeholder="BR radius"
                            type="number"
                            suffix={<Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-unit-options"
                                value={methods.watch('border-bottom-right-radius-unit')}
                                options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                onChange={(v) => {
                                    const _tmp = methods.getValues('border-bottom-right-radius-unit')
                                    if (_tmp !== v.id)
                                        updateStyle({
                                            borderRadius: undefined,
                                            borderBottomRightRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-bottom-right-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-bottom-right-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-bottom-right-radius'))}${v.id}`
                                        })
                                }}
                            />}
                        />
                    </div>
                </div>}
            </div>
            <div style={{ paddingTop: '0.4rem ', paddingBottom: '0.4rem ' }}>
                <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                    <Text className="semibold11" style={{ color: '#999999' }}>Border width</Text>
                    <TextField
                        className="regular1"
                        name="border-width"
                        register={methods.register('border-width', { onBlur: (ev) => { updateStyle({ borderWidth: ev.target.value.trim() + methods.getValues('border-unit') }) } })}
                        placeholder="border width"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('border-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('border-unit')
                                if (_tmp !== v.id) {
                                    updateStyle({ borderWidth: v.id === 'rem' ? `${parseFloat(methods.getValues('border-width')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-width')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-width'))}${v.id}` })
                                }
                            }}
                        />}
                    />
                </div>
                <div className="col" style={{ gap: '0.4rem', flex: 1, paddingTop: '0.8rem' }}>
                    <Text className="semibold11" style={{ color: '#999999' }}>Border color</Text>
                    {methods.watch("border-color") ? <ColorPicker
                        colorName={'border-color'}
                        colorOpcaityName={'border-color-opacity'}
                        register={methods.register}
                        colorValue={methods.watch("border-color") + Ultis.percentToHex(parseFloat(methods.watch('border-color-opacity')))}
                        onChange={({ colorValue, opacity }) => updateStyle({ borderColor: colorValue })}
                    /> : null}
                </div>
            </div>
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Background</Text>
            {methods.watch("background-color-value") ? <button type="button" className="row icon-button16">
                <FontAwesomeIcon icon={faChevronDown} />
            </button> : <button type="button" className="row icon-button16">
                <FontAwesomeIcon icon={faPlus} />
            </button>}
        </div>
        <div className="col" style={{ padding: '0.4rem 0.8rem 0.4rem 1.2rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            {methods.watch("background-color-value") ? <ColorPicker
                colorName={'background-color-value'}
                colorOpcaityName={'background-color-opacity'}
                register={methods.register}
                colorValue={methods.watch("background-color-value") + Ultis.percentToHex(parseFloat(methods.watch('background-color-opacity')))}
                opacity={methods.watch('opacity')}
                onChange={({ colorValue, opacity }) => updateStyle({ backgroundColor: colorValue, opacity: opacity ? undefined : 0 })}
            /> : null}
        </div>
    </>
}