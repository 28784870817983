import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { PageActions } from "../reducer"
import { onSelectedLayer } from "../pageView"
import { Select1, Text, TextField } from "wini-web-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { useEffect } from "react"
import { Ultis } from "../../../../Utils"
import { ColorPicker } from "./functions/funtions"

export default function SettingText({ selectedLayer }) {
    const methods = useForm({ shouldFocusError: false })
    const layers = useSelector((store) => store.page.data?.layers)
    const dispatch = useDispatch()

    const updateStyle = (style) => {
        let _newSettingStyle = { ...selectedLayer.Setting.style, ...style }
        for (const prop in _newSettingStyle) {
            if (_newSettingStyle[prop] == undefined) delete _newSettingStyle[prop]
        }
        PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: _newSettingStyle } }])
        setTimeout(() => { onSelectedLayer(selectedLayer?.Id) }, 100)
    }

    useEffect(() => {
        const _newElement = document.getElementById(selectedLayer?.Id)
        const _newStyle = window.getComputedStyle(_newElement)
        if (_newElement) {
            const _rootFz = parseFloat(window.getComputedStyle(document.documentElement)['font-size'].replace("px", ""))
            methods.setValue('font-family', _newStyle['font-family'])
            methods.setValue('font-weight', _newStyle['font-weight'])
            methods.setValue('font-size', selectedLayer.Setting.style?.fontSize?.includes("px") ? _newStyle['font-size'].replace("px", "") : (parseFloat(_newStyle['font-size'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
            methods.setValue('font-size-unit', selectedLayer.Setting.style?.fontSize?.includes("px") ? "px" : "rem")
            methods.setValue('line-height', selectedLayer.Setting.style?.lineHeight?.includes("px") ? _newStyle['line-height'].replace("px", "") : _newStyle['line-height'] === "normal" ? "normal" : (parseFloat(_newStyle['line-height'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
            methods.setValue('line-height-unit', selectedLayer.Setting.style?.lineHeight?.includes("px") ? "px" : "rem")
            methods.setValue('letter-spacing', selectedLayer.Setting.style?.letterSpacing?.includes("px") ? _newStyle['letter-spacing'].replace("px", "") : _newStyle['letter-spacing'] === "normal" ? "normal" : (parseFloat(_newStyle['letter-spacing'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
            methods.setValue('letter-spacing-unit', selectedLayer.Setting.style?.letterSpacing?.includes("px") ? "px" : "rem")
            const _textColor = Ultis.rgbToHex(_newStyle.color)
            methods.setValue('text-color', _textColor.substring(0, 7))
            methods.setValue('text-color-opacity', `${Ultis.hexToPercent(_textColor.substring(7))}`)
        }
    }, [selectedLayer])

    return <>
        <div className="row" style={{ borderBottom: '1px solid #444444', padding: '0.8rem 1.2rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>{selectedLayer.Name}</Text>
            <button type="button" className="row icon-button16"><FontAwesomeIcon icon={faBars} /></button>
        </div>
        <div className="col" style={{ padding: '0.6rem 1.2rem', borderBottom: '1px solid #444444' }}>
            <TextField
                key={'textfield-' + selectedLayer.Id}
                className="regular1"
                defaultValue={selectedLayer.Setting.value}
                placeholder="Type something..."
                onBlur={(ev) => {
                    PageActions.updateLayerAll(dispatch, { ...selectedLayer, Setting: { ...selectedLayer.Setting, value: ev.target.value } })
                }}
            />
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Font</Text>
            <button type="button" className="row icon-button16"><FontAwesomeIcon icon={faChevronDown} /></button>
        </div>
        <div className="col" style={{ padding: '0.4rem 1.2rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            <Select1
                readOnly
                placeholder={'Font family'}
                className="regular1"
                value={methods.watch('font-family')}
                options={['Inter, sans-serif', 'Arial', 'Times New Roman'].map(e => {
                    return {
                        id: e,
                        name: e
                    }
                })}
                onChange={(v) => { updateStyle({ fontFamily: v.id }) }}
            />
            <div className="row" style={{ gap: '0.8rem' }}>
                <Select1
                    readOnly
                    placeholder={'Font weight'}
                    className="regular1"
                    style={{ flex: 1 }}
                    value={methods.watch('font-weight')}
                    options={['200', '400', '600', '800'].map(e => {
                        return {
                            id: e,
                            name: e
                        }
                    })}
                    onChange={(v) => { updateStyle({ fontWeight: v.id }) }}
                />
                <TextField
                    className="regular1"
                    name="font-size"
                    register={methods.register('font-size', {
                        onBlur: (ev) => { updateStyle({ fontSize: ev.target.value.trim() + methods.getValues('font-size-unit') }) }
                    })}
                    style={{ flex: 1 }}
                    placeholder="Font size"
                    type="number"
                    suffix={<Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('font-size-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }]}
                        style={{ border: 'none', padding: 0, width: '2.8rem', textAlign: 'right' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('font-size-unit')
                            if (_tmp !== v.id) {
                                methods.setValue('font-size-unit', v.id)
                                updateStyle({ fontSize: v.id === 'rem' ? `${parseFloat(methods.getValues('font-size')) / 10}rem` : `${parseFloat(methods.getValues('font-size')) * 10}px` })
                            }
                        }}
                    />}
                />
            </div>
            <div className="row" style={{ gap: '0.8rem' }}>
                <TextField
                    className="regular1"
                    name="line-height"
                    register={methods.register('line-height', {
                        onBlur: (ev) => { updateStyle({ lineHeight: ev.target.value.trim().toLowerCase() === "normal" ? undefined : (ev.target.value.trim() + methods.getValues('line-height-unit')) }) }
                    })}
                    style={{ flex: 1 }}
                    placeholder="Line height"
                    suffix={<Select1
                        readOnly
                        disabled={methods.watch('line-height') === "normal"}
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('line-height-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }]}
                        style={{ border: 'none', padding: 0, width: '2.8rem', textAlign: 'right', '--disabled-background': '#00000000' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('line-height-unit')
                            if (_tmp !== v.id) {
                                methods.setValue('line-height-unit', v.id)
                                updateStyle({ lineHeight: v.id === 'rem' ? `${parseFloat(methods.getValues('line-height')) / 10}rem` : `${parseFloat(methods.getValues('line-height')) * 10}px` })
                            }
                        }}
                    />}
                />
                <TextField
                    className="regular1"
                    name="letter-spacing"
                    register={methods.register('letter-spacing', {
                        onBlur: (ev) => { updateStyle({ letterSpacing: ev.target.value.trim().toLowerCase() === "normal" ? undefined : (ev.target.value.trim() + methods.getValues('letter-spacing-unit')) }) }
                    })}
                    style={{ flex: 1 }}
                    placeholder="Letter spacing"
                    suffix={<Select1
                        readOnly
                        disabled={methods.watch('letter-spacing') === "normal"}
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('letter-spacing-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }]}
                        style={{ border: 'none', padding: 0, width: '2.8rem', textAlign: 'right', '--disabled-background': 'transparent' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('letter-spacing-unit')
                            if (_tmp !== v.id) {
                                methods.setValue('letter-spacing-unit', v.id)
                                updateStyle({ letterSpacing: v.id === 'rem' ? `${parseFloat(methods.getValues('letter-spacing')) / 10}rem` : `${parseFloat(methods.getValues('letter-spacing')) * 10}px` })
                            }
                        }}
                    />}
                />
            </div>
            <div className="col" style={{ gap: '0.8rem' }}>
                {methods.watch("text-color") ? <ColorPicker
                    colorName={'text-color'}
                    colorOpcaityName={'text-color-opacity'}
                    register={methods.register}
                    colorValue={methods.watch("text-color") + Ultis.percentToHex(parseFloat(methods.watch('text-color-opacity')))}
                    onChange={({ colorValue, opacity }) =>
                        PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: { ...selectedLayer.Setting.style, color: colorValue } } }])
                    }
                /> : null}
            </div>
        </div>
    </>
}