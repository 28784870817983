import { Dispatch, PayloadAction, UnknownAction, createSlice } from '@reduxjs/toolkit'
import { LayerItem, PageItem } from '../da'
import { TableController } from '../../home/table/controller'
import { ToastMessage } from 'wini-web-components'
import { ComponentType } from '../../home/table/da'
import { randomGID } from '../../../Utils'
import { CSSProperties } from 'react'
import { store } from '../../../store'

interface PageSimpleResponse {
    data: {
        page?: PageItem,
        layers?: Array<LayerItem>,
        selectedLayerId?: string
    },
    onLoading?: boolean,
    type?: string
}

const initState: PageSimpleResponse = {
    data: {},
    onLoading: false
}

export const pageSlice = createSlice({
    name: 'page',
    initialState: initState,
    reducers: {
        handleActions: (state, action: PayloadAction<any>) => {
            switch (action.payload.type) {
                case 'GETDATA':
                    state.data = action.payload.data
                    break;
                case 'SETLAYERID':
                    state.data.selectedLayerId = action.payload.data
                    break;
                case 'SETLAYERS':
                    state.data.layers = state.data.layers?.map(e => {
                        const _tmp = action.payload.data.find((el: any) => el.Id === e.Id)
                        return _tmp ?? e
                    })
                    break;
                case 'ADD':
                    state.data = {
                        ...state.data,
                        layers: [...(state.data.layers ?? []), ...action.payload.data],
                        selectedLayerId: action.payload.data[0].Id
                    }
                    break;
                case 'EDIT':
                    const _updateId: Array<string> = []
                    const _newLayers = (state.data.layers ?? []).map(e => {
                        const _tmp = action.payload.data.find((el: LayerItem) => el.Id === e.Id)
                        if (_tmp) _updateId.push(_tmp.Id)
                        return _tmp ?? e
                    })
                    const _addLayers = action.payload.data.filter((e: LayerItem) => _updateId.every((id: string) => e.Id !== id))
                    state.data = {
                        ...state.data,
                        layers: [..._newLayers, ..._addLayers],
                        selectedLayerId: _addLayers[0].Id
                    }
                    break;
                case "DELETE":
                    state.data.layers = state.data.layers?.filter(e => action.payload.data.every((id: string) => e.Id !== id))
                    break;
                default:
                    break;
            }
            state.onLoading = false
        },
        onFetching: (state) => {
            state.onLoading = true
        },
        onReset: (state) => {
            state.data = {}
            state.onLoading = true
        },
    },
})

const { handleActions, onFetching, onReset } = pageSlice.actions

export default pageSlice.reducer

export class PageActions {
    static setSelectedPage = async (dispatch: Dispatch<UnknownAction>, item: PageItem) => {
        dispatch(onFetching())
        const _urlQuery = new URLSearchParams(window.location.search)
        const _layerController = new TableController(_urlQuery.get("pid") ?? "", "layer")
        const res = await _layerController.getListSimple({
            page: 1,
            size: 1000,
            query: `@PageId:{${item.Id}}`
        })
        if (res.code !== 200) return ToastMessage.errors(res.message)
        const _layers: Array<LayerItem> = res.data ?? []
        if (!res.count) {
            _layers.push({
                Id: randomGID(),
                Name: "Container",
                Type: "Container",
                PageId: item.Id,
                DateCreated: (new Date()).getTime(),
                Setting: JSON.stringify({
                    className: 'col page-container',
                    style: { gap: '1.6rem', padding: '2.4rem', alignItems: 'center', backgroundColor: '#fff' }
                })
            })
            _layers.push(...Object.values(ComponentType).slice(0, 6).map((prop, i) => {
                let _newLayer: LayerItem = {
                    Id: randomGID(),
                    Name: prop,
                    Type: prop,
                    PageId: item.Id,
                    DateCreated: (new Date()).getTime(),
                    ParentId: _layers[0].Id,
                    Setting: settingByType(prop, { style: { order: i } })
                }
                return _newLayer
            }))
            const _addLayerRes = await _layerController.add(_layers)
            if (_addLayerRes.code !== 200) return ToastMessage.errors(_addLayerRes.message)
        }
        dispatch(handleActions({
            type: 'GETDATA',
            data: {
                page: item,
                layers: _layers.map(e => {
                    return {
                        ...e,
                        Setting: e.Setting ? JSON.parse(e.Setting) : undefined
                    }
                }),
                selectedLayerId: _layers.find(e => !e.ParentId)?.Id
            },
        }))
    }

    static setSelectedLayerId = (dispatch: Dispatch<UnknownAction>, layerId?: string) => {
        dispatch(handleActions({
            type: 'SETLAYERID',
            data: layerId,
        }))
    }

    static addLayers = (dispatch: Dispatch<UnknownAction>, layers: Array<LayerItem>) => {
        const _urlQuery = new URLSearchParams(window.location.search)
        const _layerController = new TableController(_urlQuery.get("pid") ?? "", "layer")
        const _layers: Array<LayerItem> = layers.map(e => {
            let _newLayer: LayerItem = { ...e, Setting: settingByType(e.Type, e.Setting as any) }
            return _newLayer
        })
        _layerController.add(_layers).then((res) => {
            if (res.code !== 200) return ToastMessage.errors(res.message)
        })
        dispatch(handleActions({
            type: 'ADD',
            data: _layers.map(e => {
                return {
                    ...e,
                    Setting: e.Setting ? JSON.parse(e.Setting) : undefined
                }
            }),
        }))
    }

    static editLayers = (dispatch: Dispatch<UnknownAction>, layers: Array<LayerItem>) => {
        const _urlQuery = new URLSearchParams(window.location.search)
        const _layerController = new TableController(_urlQuery.get("pid") ?? "", "layer")
        const _newLayers = layers.map(e => {
            if (e.Id) return e
            let _newLayer: LayerItem = { ...e, Id: randomGID(), Setting: settingByType(e.Type, e.Setting as any) }
            return _newLayer
        })
        _layerController.add(_newLayers).then((res) => {
            if (res.code !== 200) return ToastMessage.errors(res.message)
        })
        dispatch(handleActions({
            type: 'EDIT',
            data: _newLayers.map(e => {
                return {
                    ...e,
                    Setting: e.Setting ? JSON.parse(e.Setting) : undefined
                }
            }),
        }))
    }

    static updateLayerAll = (dispatch: Dispatch<UnknownAction>, layers: Array<LayerItem>) => {
        const _urlQuery = new URLSearchParams(window.location.search)
        const _layerController = new TableController(_urlQuery.get("pid") ?? "", "layer")
        _layerController.edit(layers.map(e => {
            return {
                ...e,
                Setting: JSON.stringify(e.Setting)
            }
        })).then((res) => {
            if (res.code !== 200) return ToastMessage.errors(res.message)
        })
        dispatch(handleActions({
            type: 'SETLAYERS',
            data: layers,
        }))
    }

    static deleteLayers = (dispatch: Dispatch<UnknownAction>, layerIds: Array<string>) => {
        const _urlQuery = new URLSearchParams(window.location.search)
        const _layerController = new TableController(_urlQuery.get("pid") ?? "", "layer")
        _layerController.delete(layerIds).then((res) => {
            if (res.code !== 200) return ToastMessage.errors(res.message)
        })
        dispatch(handleActions({
            type: 'DELETE',
            data: layerIds,
        }))
    }

    static onReset = (dispatch: Dispatch<UnknownAction>) => dispatch(onReset())
}

const settingByType = (type: string, setting?: { className?: string, style?: CSSProperties, [p: string]: any }) => {
    setting ??= {}
    switch (type) {
        case ComponentType.text:
            return JSON.stringify({ className: 'heading-6', value: "Text", ...setting, style: { ...(setting.style ?? {}) } })
        case ComponentType.img:
            return JSON.stringify({ src: "https://file-mamager.wini.vn/Upload/2024/09/yuy_81f2.jpg", ...setting, style: { borderRadius: '0.8rem', width: '2.4rem', height: '2.4rem', ...(setting.style ?? {}) } })
        case ComponentType.checkbox:
            return JSON.stringify({ size: '2rem', ...setting, style: { ...(setting.style ?? {}) } })
        case ComponentType.radio:
            return JSON.stringify({ size: '2rem', ...setting, style: { ...(setting.style ?? {}) } })
        case ComponentType.switch:
            return JSON.stringify({ size: '2.4rem', ...setting, style: { ...(setting.style ?? {}) } })
        case ComponentType.textField:
            return JSON.stringify({ placeholder: "input something...", ...setting, style: { padding: '0.6rem 1rem', ...(setting.style ?? {}) } })
        case ComponentType.textArea:
            return JSON.stringify({ placeholder: "input paragragh...", ...setting, style: { height: '12rem', padding: '0.6rem 1rem', ...(setting.style ?? {}) } })
        case ComponentType.button: 
            return JSON.stringify({ label: "Button primary", ...setting, style: { ...(setting.style ?? {}) } })
        case ComponentType.container:
            return JSON.stringify({ className: "row", ...setting, style: { height: '4.8rem', width: '100%', backgroundColor: "#c5c5c5", ...(setting.style ?? {}) } })
        case ComponentType.chart:
            return JSON.stringify({ ...setting, style: { padding: '0.8rem', borderRadius: '0.4rem', border: "1px solid #00358014", ...(setting.style ?? {}) } })
        case ComponentType.form:
            return JSON.stringify({ ...setting, style: { padding: '2rem', backgroundColor: "#efeff0", gap: '2.4rem', borderRadius: '0.8rem', border: "1px solid #d7d7db", ...(setting.style ?? {}) } })
        default:
            return undefined
    }
}