import styles from './view.module.css'
import { forwardRef, useEffect, useRef, useState } from "react"
import { Button, closePopup, ComponentStatus, Dialog, DialogAlignment, showDialog, Text, ToastMessage } from "wini-web-components"
import { TableController } from "./controller"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMaximize, faMinimize, faXmark } from "@fortawesome/free-solid-svg-icons"
import SettingKeysTab from './settingKeys'
import SettingFormTab from './settingForm'
import { randomGID } from '../../../Utils'
import { DataType, FEDataType } from './da'
import SettingChartTab from './settingChart'
import { DataController, SettingDataController } from '../../module/controller'
import SettingReportTab from './settingReport'

const PopupAddEditTable = forwardRef(function PopupAddEditTable(data, ref) {
    const conatinerRef = useRef()
    const [isExpand, setExpannd] = useState(false)
    const dialogRef = useRef()
    const [tab, setTab] = useState(0)
    const _tbController = new TableController(data.pid, "table")
    const _colController = new TableController(data.pid, "column")
    const _relController = new TableController(data.pid, "rel")
    const [initCols, setInitCols] = useState([])
    const [initRels, setInitRels] = useState([])
    const [cols, setCols] = useState([{ Id: randomGID(), Name: 'Id', DataType: FEDataType.GID }, { Id: randomGID(), Name: 'DateCreated', DataType: FEDataType.DATETIME }, { Id: randomGID(), Name: 'Name', DataType: FEDataType.STRING }])
    const [rels, setRels] = useState([])
    const [item, setItem] = useState()

    const onSubmit = () => {
        showDialog({
            ref: dialogRef,
            alignment: DialogAlignment.center,
            status: ComponentStatus.WARNING,
            title: `Bạn chắc chắn muốn ${data.item ? "sửa" : "tạo"} bảng này`,
            onSubmit: async () => {
                const _newCols = cols.filter(e => e.Name?.length)
                let _props = {}
                const res = await _tbController.add([item])
                if (res.code !== 200) return ToastMessage.errors(res.message)
                if (data.item) {
                    if (initCols.length) {
                        const _deleteIds = initCols.filter(id => {
                            const _col = _newCols.find(el => id === el.Id)
                            const _isDelete = !_col || _col.Query?.length // so sánh list column ban đầu vs list column hiện tại, nếu phần tử có tồn tại ở list ban đầu mà ko tồn tại ở list hiện tại hoặc phần tử có tồn tại query(caculate) thì call api xóa phần tử đó đi
                            return _isDelete
                        })
                        if (_deleteIds.length) {
                            const deleteRes = await _colController.delete(_deleteIds)
                            if (deleteRes.code !== 200) ToastMessage.errors(deleteRes.message)
                        }
                    }
                }
                // 
                const colRes = await _colController.add(_newCols.map((e, i) => {
                    delete e.id
                    switch (e.DataType) {
                        case FEDataType.GID:
                            _props[e.Name] = DataType.TAG
                            break;
                        case FEDataType.STRING:
                            _props[e.Name] = DataType.TEXT
                            break;
                        case FEDataType.BOOLEAN:
                            _props[e.Name] = DataType.TAG
                            break;
                        case FEDataType.NUMBER:
                            _props[e.Name] = DataType.NUMERIC
                            break;
                        case FEDataType.DATE:
                            _props[e.Name] = DataType.NUMERIC
                            break;
                        case FEDataType.DATETIME:
                            _props[e.Name] = DataType.NUMERIC
                            break;
                        case FEDataType.MONEY:
                            _props[e.Name] = DataType.NUMERIC
                            break;
                        default:
                            if (e.Name === "DateCreated") {
                                e.DataType = FEDataType.DATETIME
                                _props[e.Name] = DataType.NUMERIC
                            }
                            break;
                    }
                    e.Setting ??= { Title: e.Name, IsHidden: false, Sort: i }
                    e.Form ??= { Label: e.Name, IsHidden: false, Sort: i }
                    if (typeof e.Setting !== "string") e.Setting = JSON.stringify(e.Setting)
                    if (typeof e.Form !== "string") e.Form = JSON.stringify(e.Form)
                    return { ...e, TableName: item.Name }
                }))
                if (colRes.code !== 200) return ToastMessage.errors(colRes.message)
                if (initRels.length) {
                    const _deleteIds = initRels.filter(id => !rels.some(el => id === el.Id))  // so sánh list relative ban đầu vs list relative hiện tại, nếu phần tử có tồn tại ở list ban đầu mà ko tồn tại ở list hiện tại thì call api xóa phần tử đó đi
                    if (_deleteIds.length) {
                        const deleteRes = await _relController.delete(_deleteIds)
                        if (deleteRes.code !== 200) ToastMessage.errors(deleteRes.message)
                    }
                }
                const rellRes = await _relController.edit(rels.map((e, i) => {
                    _props[e.Column] = DataType.TAG
                    e.Setting ??= { Title: e.TablePK, IsHidden: true, Sort: i }
                    e.Form ??= { Label: e.Column, Sort: i, Required: true }
                    if (typeof e.Setting !== "string") e.Setting = JSON.stringify(e.Setting)
                    if (typeof e.Form !== "string") e.Form = JSON.stringify(e.Form)
                    return { ...e, TableFK: item.Name }
                }))
                if (rellRes.code !== 200) return ToastMessage.errors(rellRes.message)
                // rebuildIndex search
                const _dataController = new DataController({ pid: data.pid, module: item.Name })
                const idxRes = await _dataController.buildIndex(_props)
                if (idxRes.code !== 200) return ToastMessage.errors(idxRes.message)
                // 
                const _settingFormController = new SettingDataController({ pid: data.pid, setting: "form" })
                const _checkExistForm = await _settingFormController.getListSimple({ page: 1, size: 1 })
                if (_checkExistForm.code !== 200) {
                    const newFormRes = await _settingFormController.action("add", {
                        data: [{
                            Id: randomGID(),
                            Name: "Default form",
                            TbName: item.Name,
                            DateCreated: (new Date()).getTime(),
                            Description: "default description...",
                            Type: "default"
                        }]
                    })
                    if (newFormRes.code !== 200) return ToastMessage.errors(newFormRes.message)
                }
                ToastMessage.success(`${data.item ? "Cập nhật" : "Thêm"} bảng ${item.Name} thành công`)
                closePopup(ref)
                data.onSuccess()
            }
        })
    }

    const getData = async () => {
        const _relRes = await _relController.getListSimple({ page: 1, size: 100, query: `@TableFK:{${data.item.Name}}` })
        if (_relRes.count) {
            setRels(_relRes.data)
            setInitRels(_relRes.data.map(e => e.Id))
        }
        const _colRes = await _colController.getListSimple({ page: 1, size: 100, query: `@TableName:{${data.item.Name}}` })
        if (_colRes.count) {
            _colRes.data = _colRes.data.sort((a, b) => a.Name.length - b.Name.length)
            setCols(_colRes.data)
            setInitCols(_colRes.data.map(e => e.Id))
        }
    }

    const renderTabView = () => {
        if (data.item && !initCols.length) return <div />
        switch (tab) {
            case 0:
                return <SettingKeysTab
                    pid={data.pid}
                    item={data.item}
                    cols={cols}
                    initCols={initCols}
                    relatives={rels}
                    onChange={({ newItem, newCols, newRels }) => {
                        if (newItem) setItem(newItem)
                        if (newCols) setCols(newCols)
                        if (newRels) setRels(newRels)
                    }}
                />
            case 1:
                return <SettingFormTab
                    pid={data.pid}
                    cols={cols.filter((e) => e.Name !== "Id" && e.Name !== "DateCreated" && !e.Query)}
                    rels={rels}
                    onChange={({ newCols, newRels }) => {
                        if (newCols?.length) setCols(cols.map(e => newCols.find(el => el.Id === e.Id) ?? e))
                        if (newRels?.length) setRels(newRels)
                    }}
                />
            case 2:
                return <SettingChartTab
                    item={data.item}
                    pid={data.pid}
                />
            case 3:
                return <SettingReportTab
                    item={data.item}
                    pid={data.pid}
                />
            default:
                return <div />
        }
    }

    useEffect(() => {
        if (data.item) {
            getData()
            setItem(data.item)
        }
    }, [])

    return <div ref={conatinerRef} className="col" style={{ flex: 1, width: '100%', height: '100%' }}>
        <Dialog ref={dialogRef} />
        <div className='col popup-header' style={{ padding: 0 }}>
            <div className="row" style={{ padding: '0.4rem 0.8rem 0 1.6rem', width: '100%' }} >
                <Text className='heading-6' style={{ flex: 1 }}>Bảng {data.item?.Name ?? "mới"}</Text>
                <button type='button' className='row icon-button32' onClick={() => {
                    if (isExpand) conatinerRef.current.parentNode.style.cssText = "width: 68%; height: 80%"
                    else conatinerRef.current.parentNode.style.cssText = "width: 100%; height: 100%; max-width: 100%; max-height: 100%; border-radius: 0"
                    setExpannd(!isExpand)
                }}>
                    <FontAwesomeIcon icon={isExpand ? faMinimize : faMaximize} />
                </button>
                <button type='button' className='row icon-button48' onClick={() => { closePopup(ref) }}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </div>
            <div className={`row ${styles['tab-setting-container']}`}>
                <button type='button' className={`row semibold2 ${tab === 0 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(0) }}>Key </button>
                <button type='button' className={`row semibold2 ${tab === 1 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(1) }}>Form</button>
                {data.item ? <>
                    <button type='button' className={`row semibold2 ${tab === 2 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(2) }}>Chart</button>
                    <button type='button' className={`row semibold2 ${tab === 3 ? styles["selected"] : ""} ${styles['btn-tab']}`} onClick={() => { setTab(3) }}>Report</button>
                </> : null}
            </div>
        </div>

        {renderTabView()}
        {tab < 2 ? <div className="row popup-footer">
            <Text className="button-text-3" onClick={() => {
                showDialog({
                    ref: dialogRef,
                    alignment: DialogAlignment.center,
                    status: ComponentStatus.WARNING,
                    title: 'Hủy ' + (data.item ? 'chỉnh sửa' : 'thêm mới'),
                    onSubmit: () => { closePopup(ref) }
                })
            }}>Hủy</Text>
            <div style={{ flex: 1 }}></div>
            <Button
                label={data.item ? 'Lưu' : 'Tạo mới'}
                disabled={!item?.Name?.trim()?.length}
                style={{ padding: '0.6rem 1rem', color: !item?.Name?.trim()?.length ? undefined : "#fff" }}
                onClick={onSubmit}
            />
        </div> : null}
    </div>
})

export default PopupAddEditTable



