import { faCaretDown, faCaretRight, faGripVertical, faPencil, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { forwardRef, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { closePopup, Switch, Text, TextField } from "wini-web-components"
import { DataController } from "../controller"

const PopupAddColumn = forwardRef(function PopupAddColumn(data, ref) {
    const [cols, setCols] = useState([])
    const [rels, setRels] = useState([])
    const [actions, setActions] = useState([])
    const [toggleCol, setToggleCol] = useState(true)
    const [toggleAction, setToggleAction] = useState(true)

    const updateColumn = (item) => {
        data.colController.edit([{ ...item, Setting: JSON.stringify(item.Setting) }])
        const _newCols = cols.map(e => {
            if (e.Id === item.Id) return item
            else return e
        })
        setCols(_newCols)
        data.onChange({ newCols: _newCols })
    }

    const updateRel = (item) => {
        data.relController.edit([{ ...item, Setting: JSON.stringify(item.Setting) }])
        const _newRels = rels.map(e => {
            if (e.Id === item.Id) return item
            else return e
        })
        setRels(_newRels)
        data.onChange({ newRels: _newRels })
    }

    const updateAction = (item) => {
        data.relController.edit([{ ...item, Shortcut: JSON.stringify(item.Shortcut) }])
        const _newActions = actions.map(e => {
            if (e.Id === item.Id) return item
            else return e
        })
        setActions(_newActions)
        data.onChange({ newActions: _newActions })
    }

    useEffect(() => {
        setCols(data.cols)
        setRels(data.rels)
        setActions(data.actions)
    }, [])

    return <div className='col' style={{ width: '40rem', backgroundColor: '#fff', flex: 1 }}>
        <div className='row popup-header' style={{ paddingRight: '1.2rem' }}>
            <Text className='heading-7' style={{ flex: 1 }}>Setting</Text>
            <button type='button' className='row icon-button32' onClick={() => { closePopup(ref) }}>
                <FontAwesomeIcon icon={faXmark} />
            </button>
        </div>
        <div className='col' style={{ flex: 1, overflow: "hidden auto", padding: '1.6rem 0', gap: '0.4rem' }}>
            <div className='row' style={{ gap: '0.8rem', padding: '0.4rem 1.6rem' }}>
                <button type='button' className='row icon-button20' onClick={() => { setToggleCol(!toggleCol) }}>
                    <FontAwesomeIcon icon={toggleCol ? faCaretDown : faCaretRight} />
                </button>
                <Text className='heading-7' style={{ flex: 1 }}>Column</Text>
            </div>
            <div className='col' style={{ gap: '0.4rem', display: toggleCol ? 'flex' : 'none' }}>
                {cols.filter(e => e.Name !== "Id").map((item) => {
                    return <div key={item.Id} className='row setting-column-tile' style={{ backgroundColor: item.Name === "Name" ? 'var(--disabled-background)' : undefined }}>
                        <button type='button' className='row icon-button24' style={item.Name === "Name" ? { pointerEvents: 'none' } : undefined}>
                            <FontAwesomeIcon icon={faGripVertical} style={{ color: '#87909e' }} />
                        </button>
                        <TextField
                            style={{ flex: 1, textOverflow: 'ellipsis' }}
                            defaultValue={item.Setting?.Title ?? item.Name}
                            onBlur={(ev) => {
                                const _newTitle = ev.target.value.trim()
                                if (_newTitle !== item.Setting?.Title) {
                                    updateColumn({ ...item, Setting: { ...item.Setting, Title: _newTitle.length ? _newTitle : item.Name } })
                                }
                            }}
                        />
                        <Switch disabled={item.Name === "Name"} value={!item.Setting?.IsHidden} onChange={(v) => { updateColumn({ ...item, Setting: { ...item.Setting, IsHidden: !v } }) }} />
                    </div>
                })}
                {rels.map((item) => {
                    return <div key={item.Id} className='row setting-column-tile'>
                        <button type='button' className='row icon-button24'>
                            <FontAwesomeIcon icon={faGripVertical} style={{ color: '#87909e' }} />
                        </button>
                        <TextField
                            style={{ flex: 1, textOverflow: 'ellipsis' }}
                            defaultValue={item.Setting?.Title ?? item.Column}
                            onBlur={(ev) => {
                                const _newTitle = ev.target.value.trim()
                                if (_newTitle !== item.Setting?.Title) {
                                    updateRel({ ...item, Setting: { ...item.Setting, Title: _newTitle.length ? _newTitle : item.Column } })
                                }
                            }}
                        />
                        <Switch value={!item.Setting?.IsHidden} onChange={(v) => { updateRel({ ...item, Setting: { ...item.Setting, IsHidden: !v } }) }} />
                    </div>
                })}
            </div>
            <div className='row' style={{ gap: '0.8rem', padding: '0.4rem 1.6rem' }}>
                <button type='button' className='row icon-button20' onClick={() => { setToggleAction(!toggleAction) }}>
                    <FontAwesomeIcon icon={toggleAction ? faCaretDown : faCaretRight} />
                </button>
                <Text className='heading-7' style={{ flex: 1 }}>Column</Text>
            </div>
            <div className='col' style={{ gap: '0.4rem', display: toggleAction ? 'flex' : 'none' }}>
                {actions.map((item) => {
                    return <div key={item.Id} className='row setting-column-tile'>
                        <button type='button' className='row icon-button24'>
                            <FontAwesomeIcon icon={faGripVertical} style={{ color: '#87909e' }} />
                        </button>
                        <TextField
                            style={{ flex: 1, textOverflow: 'ellipsis' }}
                            defaultValue={item.Shortcut?.Title ?? item.TableFK}
                            onBlur={(ev) => {
                                const _newTitle = ev.target.value.trim()
                                if (_newTitle !== item.Shortcut?.Title) {
                                    updateAction({ ...item, Shortcut: { ...item.Shortcut, Title: _newTitle.length ? _newTitle : item.TableFK } })
                                }
                            }}
                        />
                        <Switch value={item.Shortcut?.IsHidden === false} onChange={(v) => { updateAction({ ...item, Shortcut: { ...item.Shortcut, IsHidden: !v } }) }} />
                    </div>
                })}
            </div>
        </div>
    </div>
})

const PopupActions = forwardRef(function PopupActions(data, ref) {
    const methods = useForm({ shouldFocusError: false })

    useEffect(() => {
        if (data.actions?.length) {
            data.actions.forEach((_rel) => {
                const _relDataCotroller = new DataController({ pid: data.pid, module: _rel.TableFK })
                _relDataCotroller.getListSimple({
                    page: 1,
                    size: 1,
                    query: `@${_rel.Column}:{${data.item.Id}*}`,
                    returns: ["Id"]
                }).then((res) => {
                    if (res.code === 200) methods.setValue(_rel.Id, res.totalCount)
                })
            })
        }
    }, [data.actions])

    return <div className='col popup-actions' style={{ borderRadius: '0.8rem', minWidth: '16rem' }}>
        <button type='button' className='row' onClick={data.onEdit}>
            <FontAwesomeIcon icon={faPencil} />
            <Text>Edit</Text>
        </button>
        {data.actions.map(e => {
            return <button key={e.Id} type='button' className='row' onClick={() => { data.onSelectAction(e) }}>
                <div style={{ width: '1.4rem' }} />
                <Text>{e.Shortcut.Title ?? e.TableFK}({methods.watch(e.Id)})</Text>
            </button>
        })}
        <button type='button' className='row' onClick={data.onDelete}>
            <FontAwesomeIcon icon={faTrashCan} />
            <Text>Delete</Text>
        </button>
    </div>
})

export { PopupAddColumn, PopupActions }