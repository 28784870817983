import $ from 'jquery'
import styles from './setting-page.module.css'
import { useDispatch, useSelector } from "react-redux"
import { ComponentType } from "../../home/table/da"
import { Button, Checkbox, RadioButton, Switch, Text, TextArea, TextField } from "wini-web-components"
import { useEffect, useRef, useState } from "react"
import { PageActions } from "./reducer"
import { ChartCard, FormCard } from '../../../project-component/cards'
import { getElementByPattern } from '../controller'
import { TableController } from '../../home/table/controller'

class LayerAction {
    static onSelected = () => undefined
    static onHover = () => undefined
}

export default function PageView() {
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const { page, layers, selectedLayerId } = useSelector((store) => store.page.data)
    const dispatch = useDispatch()
    const settingPageRef = useRef()
    const [hoverId, setHoverId] = useState()

    const _onMouseOver = (ev) => {
        ev.stopPropagation()
        ev.preventDefault()
        const _layerId = ev.target.id ? ev.target.id : ev.target.closest('*[id]')?.id
        if (hoverId !== _layerId) LayerAction.onHover(_layerId)
    }

    const _onMouseDown = (ev) => {
        ev.stopPropagation()
        ev.preventDefault()
        const _layerId = ev.target.id || ev.target.closest('*[id]')?.id
        if (_layerId && _layerId !== selectedLayerId) PageActions.setSelectedLayerId(dispatch, _layerId)
    }

    useEffect(() => {
        if (layers?.length)
            LayerAction.onSelected = (_layerId = '') => {
                let _layerItem;
                if (_layerId?.length === 32) _layerItem = document.getElementById(_layerId)
                if (_layerItem) {
                    function setSelectedRect() {
                        if (settingPageRef?.current) {
                            const _pageRect = settingPageRef.current.getBoundingClientRect()
                            const _layerRect = _layerItem.getBoundingClientRect()
                            settingPageRef.current.style.setProperty('--selectedX', `${_layerRect.x - _pageRect.x}px`)
                            settingPageRef.current.style.setProperty('--selectedY', `${_layerRect.y - _pageRect.y}px`)
                            settingPageRef.current.style.setProperty('--selectedW', `${_layerRect.width}px`)
                            settingPageRef.current.style.setProperty('--selectedH', `${_layerRect.height}px`)
                        } else window.onresize = undefined
                    }
                    setSelectedRect()
                    window.onresize = () => {
                        setSelectedRect()
                        LayerAction.onHover()
                    }
                    window.onkeydown = (ev) => {
                        if (ev.target.localName !== 'input') {
                            switch (ev.key.toLowerCase()) {
                                case 'delete':
                                    if (!_layerItem.classList.contains('page-container')) PageActions.deleteLayers(dispatch, layers.filter(e => e.ParentId === _layerId || e.Id === _layerId).map(e => e.Id))
                                    break;
                                case 'backspace':
                                    if (!_layerItem.classList.contains('page-container')) PageActions.deleteLayers(dispatch, layers.filter(e => e.ParentId === _layerId || e.Id === _layerId).map(e => e.Id))
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                } else {
                    settingPageRef.current.style.removeProperty('--selectedX')
                    settingPageRef.current.style.removeProperty('--selectedY')
                    settingPageRef.current.style.removeProperty('--selectedW')
                    settingPageRef.current.style.removeProperty('--selectedH')
                    window.onresize = undefined
                    window.onkeydown = undefined
                }
            }
    }, [layers?.length])

    useEffect(() => {
        LayerAction.onSelected(selectedLayerId)
        LayerAction.onHover = (_layerId = '') => {
            if (_layerId === selectedLayerId) return setHoverId(undefined)
            let _layerItem;
            if (_layerId) _layerItem = document.getElementById(_layerId)
            if (_layerItem) {
                setHoverId(_layerId)
                const _pageRect = settingPageRef.current.firstChild.getBoundingClientRect()
                const _layerRect = _layerItem.getBoundingClientRect()
                settingPageRef.current.firstChild.style.setProperty('--hoverX', `calc(${_layerRect.x - _pageRect.x}px - 1.6rem)`)
                settingPageRef.current.firstChild.style.setProperty('--hoverY', `calc(${_layerRect.y - _pageRect.y}px - 1.6rem)`)
                settingPageRef.current.firstChild.style.setProperty('--hoverW', `${_layerRect.width}px`)
                settingPageRef.current.firstChild.style.setProperty('--hoverH', `${_layerRect.height}px`)
            } else {
                setHoverId(undefined)
                settingPageRef.current.firstChild.style.removeProperty('--hoverX')
                settingPageRef.current.firstChild.style.removeProperty('--hoverY')
                settingPageRef.current.firstChild.style.removeProperty('--hoverW')
                settingPageRef.current.firstChild.style.removeProperty('--hoverH')
            }
        }
    }, [selectedLayerId])

    useEffect(() => {
        $(settingPageRef.current).on('mousewheel DOMMouseScroll', `div[component-type="Container"]`, function (event) {
            if (document.body.querySelectorAll(".setting-pages-view")) event.preventDefault()
            else $(settingPageRef.current).off()
        });
    }, [])

    const renderPageView = (item) => {
        const children = (layers ?? []).filter(e => e.ParentId === item.Id)
        switch (item.Type) {
            case ComponentType.container:
                return <div id={item.Id} key={item.Id} className={item.Setting.className} component-type={item.Type} style={item.Setting.style}>{children.map(e => renderPageView(e))}</div>
            case ComponentType.text:
                return <Text id={item.Id} key={item.Id} className={item.Setting.className} style={item.Setting.style}>{item.Setting.value}</Text>
            case ComponentType.button:
                return <Button type="button" id={item.Id} key={item.Id} {...item.Setting} />
            case ComponentType.img:
                return <img id={item.Id} key={item.Id} alt="" {...item.Setting} />
            case ComponentType.chart:
                return <ChartCardById id={item.Id} pid={_pid} key={item.Id} {...item.Setting} />
            case ComponentType.form:
                return <FormCardById id={item.Id} pid={_pid} key={item.Id} {...item.Setting} />
            case ComponentType.checkbox:
                return <Checkbox id={item.Id} value key={item.Id} {...item.Setting} />
            case ComponentType.switch:
                return <Switch id={item.Id} value key={item.Id} {...item.Setting} />
            case ComponentType.textField:
                return <TextField id={item.Id} key={item.Id} {...item.Setting} />
            case ComponentType.textArea:
                return <TextArea id={item.Id} key={item.Id} {...item.Setting} />
            case ComponentType.radio:
                return <RadioButton id={item.Id} key={item.Id} {...item.Setting} />
            default:
                return <div id={item.Id} key={item.Id} />
        }
    }

    return <div ref={settingPageRef} className={`col ${styles['setting-page-body']}`} onMouseOver={_onMouseOver} onMouseDown={_onMouseDown} {...(selectedLayerId ? { 'layer-name': layers?.find(e => e.Id === selectedLayerId)?.Name } : {})}>
        <div style={{ position: 'relative', flex: 1, width: '100%', height: '100%', border: '1.6rem solid #14181b', borderRadius: '2.4rem' }} {...(hoverId ? { 'layer-name': layers?.find(e => e.Id === hoverId)?.Name } : {})}>
            {(layers ?? []).filter(e => !e.ParentId).map(e => renderPageView(e))}
        </div>
        {/* <iframe style={{ width: '100%', height: '100%' }} srcDoc={settingPageRef.current?.firstChild?.innerHTML}  /> */}
    </div>
}

export function onSelectedLayer(layerId) { LayerAction.onSelected(layerId) }
export function onHoverLayer(layerId) { LayerAction.onHover(layerId) }

const ChartCardById = ({ id, pid, cardId, style, className }) => {
    const [data, setData] = useState()

    useEffect(() => {
        if (cardId && pid) {
            getElementByPattern({ pid: pid, pattern: `data_chart*:${cardId}` }).then(async (res) => {
                if (res.code === 200) {
                    const _relController = new TableController(pid, "rel")
                    const relRes = await _relController.getByListId(res.data.map(e => e.RelativeId))
                    if (relRes.code === 200 && relRes.data[0]) {
                        setData({
                            ...res.data[0],
                            TableFK: relRes.data[0].TableFK
                        })
                    }
                }
            })
        }
    }, [cardId, pid])

    return data ? <ChartCard
        id={id}
        pid={pid}
        item={data}
        timeRannge={120}
        style={style}
        className={className}
    /> : <div id={id} />
}

const FormCardById = ({ id, pid, formId, style, className }) => {
    const [data, setData] = useState()

    useEffect(() => {
        if (formId && pid) {
            getElementByPattern({ pid: pid, pattern: `data_form*:${formId}` }).then(async (res) => {
                if (res.code === 200) setData(res.data[0])
            })
        }
    }, [formId, pid])

    return data ? <FormCard
        id={id}
        pid={pid}
        item={data}
        style={style}
        className={className}
    /> : <div id={id} />
}