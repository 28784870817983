import { Button, Checkbox, RadioButton, Switch, Text, TextArea, TextField } from "wini-web-components"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { ChartCard, FormCard } from "../../../project-component/cards"
import { TableController } from "../../home/table/controller"
import { ComponentType } from "../../home/table/da"
import { getElementByPattern } from "../controller"


export default function RenderPageByLayers() {
    const { layers } = useSelector((store) => store.page.data)
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")

    const renderPageView = (item) => {
        const children = (layers ?? []).filter(e => e.ParentId === item.Id)
        switch (item.Type) {
            case ComponentType.container:
                return <div id={item.Id} key={item.Id} className={item.Setting.className} style={item.Setting.style}>{children.map(e => renderPageView(e))}</div>
            case ComponentType.text:
                return <Text id={item.Id} key={item.Id} className={item.Setting.className} style={item.Setting.style}>{item.Setting.value}</Text>
            case ComponentType.button:
                return <Button type="button" id={item.Id} key={item.Id} {...item.Setting} />
            case ComponentType.img:
                return <img id={item.Id} key={item.Id} alt="" {...item.Setting} />
            case ComponentType.chart:
                return <ChartCardById id={item.Id} pid={_pid} key={item.Id} {...item.Setting} />
            case ComponentType.form:
                return <FormCardById id={item.Id} pid={_pid} key={item.Id} {...item.Setting} />
            case ComponentType.checkbox:
                return <Checkbox id={item.Id} value key={item.Id} {...item.Setting} />
            case ComponentType.switch:
                return <Switch id={item.Id} value key={item.Id} {...item.Setting} />
            case ComponentType.textField:
                return <TextField id={item.Id} key={item.Id} {...item.Setting} />
            case ComponentType.textArea:
                return <TextArea id={item.Id} key={item.Id} {...item.Setting} />
            case ComponentType.radio:
                return <RadioButton id={item.Id} key={item.Id} {...item.Setting} />
            default:
                return <div id={item.Id} key={item.Id} />
        }
    }

    return <div className="col view">
        {(layers ?? []).filter(e => !e.ParentId).map(e => renderPageView(e))}
    </div>
}

const ChartCardById = ({ id, pid, cardId, style, className }) => {
    const [data, setData] = useState()

    useEffect(() => {
        if (cardId && pid) {
            getElementByPattern({ pid: pid, pattern: `data_chart*:${cardId}` }).then(async (res) => {
                if (res.code === 200) {
                    const _relController = new TableController(pid, "rel")
                    const relRes = await _relController.getByListId(res.data.map(e => e.RelativeId))
                    if (relRes.code === 200 && relRes.data[0]) {
                        setData({
                            ...res.data[0],
                            TableFK: relRes.data[0].TableFK
                        })
                    }
                }
            })
        }
    }, [cardId, pid])

    return data ? <ChartCard
        id={id}
        pid={pid}
        item={data}
        timeRannge={120}
        style={style}
        className={className}
    /> : <div id={id} />
}

const FormCardById = ({ id, pid, formId, style, className }) => {
    const [data, setData] = useState()

    useEffect(() => {
        if (formId && pid) {
            getElementByPattern({ pid: pid, pattern: `data_form*:${formId}` }).then(async (res) => {
                if (res.code === 200) setData(res.data[0])
            })
        }
    }, [formId, pid])

    return data ? <FormCard
        id={id}
        pid={pid}
        item={data}
        style={style}
        className={className}
        isDemo={false}
    /> : <div id={id} />
}