import { ToastMessage } from 'wini-web-components'
import styles from '../assest.module.css'
import { useDispatch, useSelector } from "react-redux"
import { DataController, SettingDataController } from '../../controller'
import { TableController } from '../../../home/table/controller'
import { ChartCard } from '../../../../project-component/cards'
import { useEffect, useState } from 'react'
import { ComponentType } from '../../../home/table/da'
import { onSelectedLayer } from '../pageView'
import { PageActions } from '../reducer'

export default function TbAssetsChart({ tbName = "" }) {
    const { page, layers, selectedLayerId } = useSelector((store) => store.page.data)
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _settingDataController = new SettingDataController({ pid: _pid, setting: "chart" })
    const _relController = new TableController(_pid, "rel")
    const [cards, setCards] = useState({ data: [] })
    const [relatives, setRelatives] = useState([])
    const dispatch = useDispatch()

    const getCards = async () => {
        const res = await _settingDataController.getListSimple({ page: 1, size: 10, query: `@TbName:{${tbName}}` })
        if (res.code === 200) {
            const _relativeIds = res.data.map(e => e.RelativeId).filter(id => relatives.every(e => e.Id !== id))
            if (_relativeIds.length) {
                const relResponse = await _relController.getByListId(_relativeIds)
                if (relResponse.code === 200) {
                    var _newRels = [...relatives, ...relResponse.data].filter(e => e != undefined)
                    setRelatives(_newRels)
                }
            }
            setCards({
                data: [
                    ...cards.data,
                    ...res.data.map(e => {
                        e.TableFK ??= (_newRels ?? relatives).find((_rel) => _rel.Id === e.RelativeId)?.TableFK
                        return e
                    })
                ],
                totalCount: res.totalCount
            })
        } else ToastMessage.errors(res.message)
    }

    const handleDragComponent = (ev, cardId) => {
        ev.preventDefault()
        function onDrag(event) {
            const _component = document.body.querySelector(`:scope > div[class*="component-options"]`)
            if (_component) {
                _component.style.left = `${event.pageX}px`
                _component.style.top = `${event.pageY}px`
                handleDragToTarget(event)
            } else {
                const _cloneNode = ev.target.cloneNode(true)
                document.body.appendChild(_cloneNode)
                onSelectedLayer(undefined)
            }
        }
        document.body.addEventListener('mousemove', onDrag)
        document.body.onmouseup = () => {
            let _demo = document.body.querySelector(`div[class*="demo-component-in-container"]`)
            const _component = document.body.querySelector(`:scope > div[class*="component-options"]`)
            if (_component) document.body.removeChild(_component)
            document.body.removeEventListener('mousemove', onDrag)
            document.body.onmouseup = undefined
            if (_demo) {
                const _parent = _demo.parentElement
                const _children = [..._parent.children].sort((a, b) => parseInt(window.getComputedStyle(a).order) - parseInt(window.getComputedStyle(b).order))
                const _layerChildren = layers.filter(e => e.ParentId === _parent.id)
                PageActions.editLayers(dispatch, _children.map((e, i) => {
                    let _tmp;
                    if (e.id) {
                        _tmp = _layerChildren.find(el => el.Id === e.id)
                        if (_tmp) return { ..._tmp, Setting: JSON.stringify({ ..._tmp.Setting, style: { ..._tmp.Setting.style, order: i } }) }
                    }
                    _tmp = {
                        Name: ComponentType.chart,
                        Type: ComponentType.chart,
                        PageId: page.Id,
                        DateCreated: (new Date()).getTime(),
                        ParentId: _parent.id,
                        Setting: { cardId: cardId, style: { order: i } }
                    }
                    return _tmp
                }))
                _parent.removeChild(_demo)
            }
        }
    }

    const handleDragToTarget = (ev) => {
        const _parent = ev.target
        let _demo = document.body.querySelector(`div[class*="demo-component-in-container"]`)
        if (_parent.id?.length === 32 && _parent.getAttribute("component-type") === ComponentType.container) {
            let _children = [..._parent.children].filter(e => !e.classList.contains(styles['demo-component-in-container'])).sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0))
            if (!_demo) {
                _demo = document.createElement("div")
                _demo.className = styles['demo-component-in-container']
            }
            const _direction = window.getComputedStyle(_parent).flexDirection
            let _order = 0
            let _distance = 0
            if (_direction === "column") {
                _demo.style.height = '0.3rem'
                _demo.style.width = "2rem"
                _demo.style.maxWidth = "100%"
                if (_children.length) {
                    let closestHTML = [..._children].sort((aHTML, bHTML) => {
                        let aRect = aHTML.getBoundingClientRect()
                        let bRect = bHTML.getBoundingClientRect()
                        let a_center_oy = Math.abs(ev.pageY - (aRect.y + aRect.height / 2))
                        let b_center_oy = Math.abs(ev.pageY - (bRect.y + bRect.height / 2))
                        return a_center_oy - b_center_oy
                    })[0]
                    if (closestHTML) {
                        let htmlRect = closestHTML.getBoundingClientRect()
                        _order = _children.indexOf(closestHTML)
                        _distance = ev.pageY - (htmlRect.y + htmlRect.height / 2)
                        if (_distance < 0) _order--
                    } else _order = _children.length - 1
                }
            } else {
                _demo.style.width = '0.3rem'
                _demo.style.height = "2rem"
                _demo.style.maxHeight = "100%"
                if (_children.length) {
                    let isWrap = window.getComputedStyle(_parent).flexWrap === 'wrap'
                    let closestHTML = [..._children].sort((aHTML, bHTML) => {
                        let aRect = aHTML.getBoundingClientRect()
                        let bRect = bHTML.getBoundingClientRect()
                        let a_center_ox = Math.abs(ev.pageX - (aRect.x + aRect.width / 2))
                        let b_center_ox = Math.abs(ev.pageX - (bRect.x + bRect.width / 2))
                        if (isWrap) {
                            a_center_ox = Math.sqrt(Math.pow(ev.pageX - (aRect.x + aRect.width / 2), 2) + Math.pow(ev.pageY - (aRect.y + aRect.height / 2), 2))
                            b_center_ox = Math.sqrt(Math.pow(ev.pageX - (bRect.x + bRect.width / 2), 2) + Math.pow(ev.pageY - (bRect.y + bRect.height / 2), 2))
                        }
                        return a_center_ox - b_center_ox
                    })[0]
                    if (isWrap) closestHTML = _children.find(childHTML => childHTML.getBoundingClientRect().bottom >= ev.pageY)
                    if (closestHTML) {
                        let htmlRect = closestHTML.getBoundingClientRect()
                        _order = _children.indexOf(closestHTML)
                        _distance = ev.pageX - (htmlRect.x + htmlRect.width / 2)
                        if (_distance < 0) _order--
                    } else _order = _children.length - 1
                }
            }
            _demo.style.order = _order
            if (_demo.parentElement !== _parent) _parent.appendChild(_demo)
        } else if (_demo) _demo.parentElement.removeChild(_demo)
    }

    useEffect(() => {
        document.body.querySelector(".setting-page-sidebar.left").style.width = "448px"
        getCards()
    }, [])

    return <div className='row' style={{ flexWrap: "wrap", padding: '0.8rem 1.2rem', gap: '1.2rem', width: "100%" }}>
        {cards.data.map((e, j) => {
            return <div key={e.Id} className={`col col12 ${styles['component-options']} ${styles['card-option-demo']}`} onMouseDown={(ev) => { handleDragComponent(ev, e.Id) }}>
                <div className='col'><ChartCard pid={_pid} style={{ width: '40rem', scale: '0.5', transform: "translate(-48%, -46%)" }} item={e} timeRannge={120} /></div>
            </div>
        })}
    </div>
}