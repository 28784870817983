import React, { CSSProperties, ReactNode, useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { Text } from "wini-web-components"
import './cards.css'
import MapGroupData from "../screen/module/dashboard/mapGroupData"
import { ChartItem, DashBoardCardType, FormItem } from "../screen/module/da"
import { DataController } from "../screen/module/controller"
import { TableController } from "../screen/home/table/controller"
import { RenderComponentByType } from "../screen/module/config"
import { useForm } from "react-hook-form"
import { ComponentType } from "../screen/home/table/da"
import { Select1Form, SelectMultipleForm } from "./component-form"

export const CardInformationVertical = (params: {
    thumbnail?: string,
    media?: ReactNode,
    title?: string,
    titleElement?: ReactNode,
    content?: string,
    contentElement?: ReactNode,
    bottomAction?: ReactNode,
    to?: string,
    className?: string,
    style?: CSSProperties,
}) => {
    return <div className={`col card-container ${params.className ?? ''}`} style={params.style}>
        {params.thumbnail ? params.to ? <NavLink to={params.to} className="row card-thumbnail-container">
            <img src={params.thumbnail} style={{ width: '100%', height: '100%' }} alt="" />
        </NavLink> : <div className="row card-thumbnail-container">
            <img src={params.thumbnail} style={{ width: '100%', height: '100%' }} alt="" />
        </div> : undefined}
        {params.media}
        <div className="col" style={{ gap: '0.4rem', textAlign: 'inherit' }}>
            {params.titleElement ?? <Text className="heading-6" maxLine={2} style={{ textAlign: 'inherit', color: params.style?.color ?? '#00204D' }}>{params.title}</Text>}
            {params.contentElement ?? <Text className="subtitle-2" maxLine={4} style={{ color: params.style?.color ?? '#00204D99', textAlign: 'inherit' }}>{params.content}</Text>}
        </div>
        {params.bottomAction ? params.bottomAction : params.to ? <NavLink to={params.to} className={'row'} style={{ gap: '0.8rem' }}>
            <Text className="button-text-3" style={{ color: 'var(--primary-color)' }}>Learn more</Text>
            <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '1.6rem', color: 'var(--primary-color)' }} />
        </NavLink> : undefined}
    </div>
}

export const ChartCard = (params: { id: string, pid: string, item: ChartItem, timeRannge?: number, className?: string, style?: CSSProperties }) => {
    const [_result, setResult] = useState([])
    const [cardItem, setCardItem] = useState<any>()
    const _groupByTimeChart = [DashBoardCardType.bar, DashBoardCardType.horizontalBar, DashBoardCardType.bubble, DashBoardCardType.line, DashBoardCardType.scatter, DashBoardCardType.radar]

    useEffect(() => {
        if (params.item) {
            const _reducers: Array<any> = [{ Name: params.item.Name, GroupBy: params.item.GroupBy, Reducer: params.item.Reducer, ReducerBy: params.item.ReducerBy }]
            if (params.item.Type && _groupByTimeChart.includes(params.item.Type)) {
                if ((params.timeRannge ?? 120) > 30) {
                    _reducers.push({ Name: "_month", GroupBy: "_month", Query: `"timefmt(month(@DateCreated/1000), '%m')"` })
                } else {
                    _reducers.push({ Name: "_day", GroupBy: "_day", Query: `"timefmt(@DateCreated/1000, '%d-%m')"` })
                }
            }
            const _tbRelController = new DataController({ pid: params.pid, module: (params.item as any).TableFK })
            const _now = new Date()
            const _startDate = new Date(_now.getFullYear(), _now.getMonth(), _now.getDate() - (params.timeRannge ?? 120))
            _tbRelController.groupBy({
                searchRaw: `@DateCreated:[${_startDate.getTime()} ${_now.getTime()}]`,
                reducers: _reducers
            }).then((res) => {
                if (res.code === 200) {
                    setResult(res.data)
                    setCardItem({ ...params.item, Setting: JSON.parse(params.item.Setting as any) })
                }
            })
        }
    }, [params.item, (params.timeRannge ?? 120)])

    return <div id={params.id} className={`col chart-container ${params.className ?? ""}`} style={params.style}>
        <Text className='heading-7' style={{ flex: 1 }}>{cardItem?.Title}</Text>
        {
            cardItem && _result.length && <MapGroupData
                listData={_result}
                cardItem={cardItem}
                filterTime={(params.timeRannge ?? 120) > 30 ? "_month" : "_day"}
            />
        }
    </div>
}

export const FormCard = (params: { id: string, pid: string, item: FormItem, className?: string, style?: CSSProperties, isDemo: boolean }) => {
    const methodOptions = useForm({ shouldFocusError: false })
    const methods = useForm({ shouldFocusError: false })
    const [cols, setCols] = useState<Array<any>>([])
    const [rels, setRels] = useState<Array<any>>([])
    const _colController = new TableController(params.pid, "column")
    const _relController = new TableController(params.pid, "rel")

    useEffect(() => {
        if (params.item) {
            _relController.getListSimple({ page: 1, size: 100, query: `@TableFK:{${params.item.TbName}}` }).then(res => {
                if (res.code === 200) setRels(res.data.map((e: any) => {
                    return { ...e, Form: e.Form && typeof e.Form === "string" ? JSON.parse(e.Form) : { Required: true } }
                }))
            })
            _colController.getListSimple({ page: 1, size: 100, query: `@TableName:{${params.item.TbName}}` }).then(res => {
                if (res.code === 200) setCols(res.data.map((e: any) => {
                    return { ...e, Form: e.Form && typeof e.Form === "string" ? JSON.parse(e.Form) : { Required: true } }
                }))

            })
        }
    }, [params.item])

    useEffect(() => {
        if (rels.length && params.isDemo === false)
            rels.forEach((_rel) => {
                const dataController = new DataController({ pid: params.pid, module: _rel.TablePK })
                dataController.getListSimple({ page: 1, size: 50, query: _rel.Query ?? "*" }).then((res) => {
                    methodOptions.setValue(`${_rel.TablePK}_Options`, res?.data ?? [])
                })
            })
    }, [rels.length, params.isDemo])

    return <div id={params.id} className={`col ${params.className ?? ""}`} style={params.style}>
        <div className="row">
            <div className="col" style={{ gap: '0.4rem' }}>
                <Text className='heading-7' style={{ flex: 1 }}>{params.item.Name}</Text>
                <Text className='subtitle-4' style={{ flex: 1 }}>{params.item.Description}</Text>
            </div>
        </div>
        <form className="col" style={{ gap: '2rem' }}>
            {cols.filter(e => e.Name !== "Id" && e.Name !== "DateCreated").map((_col) => <RenderComponentByType key={_col.Id} methods={methods} fieldItem={_col} style={{ order: _col.Form.Sort }} className={undefined} />)}
            {rels.map((_rel) => {
                switch (_rel.ComponentType) {
                    case ComponentType.selectMultiple:
                        return <SelectMultipleForm
                            key={_rel.Id}
                            required={_rel.Form.Required}
                            control={methods.control}
                            errors={methods.formState.errors}
                            name={_rel.Column}
                            label={_rel.Form.Label ?? _rel.Column}
                            options={(methodOptions.watch(`${_rel.TablePK}_Options`) ?? []).map((e: any) => {
                                return {
                                    id: e.Id,
                                    name: e.Name
                                }
                            })}
                            style={{ order: _rel.Form.Sort }}
                        />
                    default:
                        return <Select1Form
                            key={_rel.Id}
                            required={_rel.Form.Required}
                            control={methods.control}
                            errors={methods.formState.errors}
                            name={_rel.Column}
                            label={_rel.Form.Label ?? _rel.Column}
                            options={(methodOptions.watch(`${_rel.TablePK}_Options`) ?? []).map((e: any) => {
                                return {
                                    id: e.Id,
                                    name: e.Name
                                }
                            })}
                            style={{ order: _rel.Form.Sort }}
                        />
                }
            })}
        </form>
    </div>
}

