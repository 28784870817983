import { faChevronRight, faEdit, faGear, faListUl, faPlus, faSearch, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { CellAlignItems, Checkbox, ComponentStatus, Dialog, DialogAlignment,  Pagination,  Popup, showDialog, showPopup, Table, TbBody, TbCell, TbHeader, TbRow, Text, TextField, ToastMessage } from "wini-web-components";
import { TableController } from "./controller";
import PopupAddEditTable from "./createTb";
import { Ultis } from "../../../Utils";

export default function ListTable() {
    const location = useLocation()
    const { pName } = useParams()
    const searchParams = new URLSearchParams(location.search)
    const _pid = searchParams.get("pid")
    const _tbController = new TableController(_pid, 'table')
    const ref = useRef()
    const dialogRef = useRef()
    const [tables, setTables] = useState({ data: [] })
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 })

    const showPopupAddEdit = (item) => {
        showPopup({
            ref: ref,
            hideButtonClose: true,
            style: { width: '68%', height: '80%' },
            content: <PopupAddEditTable
                ref={ref}
                pid={_pid}
                item={item}
                onSuccess={getTables}
            />
        })
    }

    const getTables = async (page, size) => {
        const res = await _tbController.getListSimple({ page: page ?? pageDetails.page, size: size ?? pageDetails.size })
        setTables({ data: res.data, count: res.count })
    }

    useEffect(() => {
        if (_pid) getTables()
    }, [_pid])

    return <div className="col view">
        <Popup ref={ref} />
        <Dialog ref={dialogRef} />
        <div className="col table-view">
            <div className='col' style={{ padding: '1.6rem 2.4rem', gap: '0.6rem' }}>
                <div className='row breadcrumb'>
                    <NavLink to={'/home'} className='body-3' style={{ opacity: 0.85 }}>Quản lý phần mềm</NavLink>
                    <FontAwesomeIcon icon={faChevronRight} />
                    <Text className='body-3' style={{ opacity: 0.85 }}>{pName ?? "-"}</Text>
                    <FontAwesomeIcon icon={faChevronRight} />
                    <Text className='label-3' style={{ opacity: 0.9 }}>Danh sách bảng</Text>
                </div>
                <div className='row' style={{ gap: '0.8rem' }}>
                    <Text className='heading-5' maxLine={2} style={{ flex: 1, width: '100%' }}>{pName ?? "-"}</Text>
                    <button type='button' className='row button-primary' onClick={() => { showPopupAddEdit() }}>
                        <FontAwesomeIcon icon={faPlus} />
                        <Text className='button-text-3'>Thêm mới</Text>
                    </button>
                </div>
            </div>
            <div className='row filter-table' style={{ padding: '1.2rem 2.4rem', gap: '0.8rem' }}>
                <TextField
                    placeholder='Tìm kiếm'
                    prefix={<FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.4rem', color: '#161D2470' }} />}
                    style={{ padding: '0.5rem 1.6rem', borderRadius: '0.6rem', height: '3.2rem' }}
                />
                <button className='row button-neutral' onClick={() => { }}>
                    <FontAwesomeIcon icon={faListUl} />
                    <Text className='button-text-3'>Bộ lọc</Text>
                </button>
            </div>
            <div className='col' style={{ padding: '0 2.4rem', width: '100%', flex: 1, height: '100%' }}>
                <div style={{ overflow: 'auto', width: '100%', flex: 1, height: '100%' }}>
                    <Table>
                        <TbHeader>
                            {
                                [
                                    <TbCell key={'h0'} fixed style={{ minWidth: '4rem' }} align={CellAlignItems.start}>
                                        <div className='row' style={{ width: '100%', gap: '1.6rem' }}>
                                            <Checkbox size={'1.6rem'} value={false} onChange={(v) => { }} />
                                            <Text className='heading-9'>STT</Text>
                                        </div>
                                    </TbCell>,
                                    <TbCell key={'h1'} style={{ minWidth: '10rem' }} align={CellAlignItems.start}>
                                        <Text className='heading-9' style={{ textAlign: CellAlignItems.start }}>Tên </Text>
                                    </TbCell>,
                                    <TbCell key={'h2'} style={{ minWidth: '6rem' }} align={CellAlignItems.center}>
                                        <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>Ngày tạo</Text>
                                    </TbCell>,
                                    <TbCell key={'h3'} style={{ minWidth: '8rem' }} align={CellAlignItems.center}>
                                        <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>Mô tả</Text>
                                    </TbCell>,
                                    <TbCell key={'hlast'} fixed style={{ minWidth: '8.4rem', }} align={CellAlignItems.center} >
                                        <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>Action</Text>
                                    </TbCell>
                                ]
                            }
                        </TbHeader>
                        <TbBody>
                            {
                                tables.data.map((item, index) => <TbRow key={`${item.Id}`}>
                                    {
                                        [
                                            <TbCell key={'b0-' + index} fixed style={{ minWidth: '4rem' }} align={CellAlignItems.start}>
                                                <div className='row' style={{ width: '100%', gap: '1.6rem' }}>
                                                    <Checkbox size={'1.6rem'} value={false} onChange={(v) => { }} />
                                                    <Text className='heading-9'>{pageDetails.size * (pageDetails.page - 1) + index + 1}</Text>
                                                </div>
                                            </TbCell>,
                                            <TbCell key={'b1-' + index} style={{ minWidth: '10rem' }} align={CellAlignItems.start}>
                                                <Text className='heading-9' style={{ textAlign: CellAlignItems.start }}>{item.Name}</Text>
                                            </TbCell>,
                                            <TbCell key={'b2-' + index} style={{ minWidth: '6rem' }} align={CellAlignItems.center}>
                                                <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>{item.DateCreated ? Ultis.datetoString(new Date(item.DateCreated)) : "-"}</Text>
                                            </TbCell>,
                                            <TbCell key={'b3-' + index} style={{ minWidth: '6rem' }} align={CellAlignItems.center}>
                                                <Text className='heading-9' style={{ textAlign: CellAlignItems.center }}>{item.Description ?? "-"}</Text>
                                            </TbCell>,
                                            <TbCell key={'blast-' + index} fixed style={{ minWidth: '8.4rem', }} align={CellAlignItems.center} >
                                                <div className='row' style={{ gap: '0.6rem', justifyContent: 'center' }}>
                                                    <button type='button' className='row icon-button24'><FontAwesomeIcon icon={faEdit} onClick={() => { showPopupAddEdit(item) }} /></button>
                                                    <button type='button' className='row icon-button24'><FontAwesomeIcon icon={faTrashCan} onClick={(ev) => {
                                                        showDialog({
                                                            ref: dialogRef,
                                                            alignment: DialogAlignment.center,
                                                            status: ComponentStatus.WARNING,
                                                            title: 'Bạn chắc chắn muốn xóa bảng ' + item.Name,
                                                            content: `Mọi dữ liệu của bảng ${item.Name} sẽ bị xóa vĩnh viễn`,
                                                            onSubmit: async () => {
                                                                const res = await _tbController.delete([item.Id])
                                                                if (res.code === 200) {
                                                                    ToastMessage.success(`Xóa bảng ${item.Name} thành công`)
                                                                    setTables({ data: tables.data.filter(e => e.Id !== item.Id), count: tables.data.filter(e => e.Id !== item.Id).length })
                                                                } else {
                                                                    ToastMessage.errors(res.message)
                                                                }
                                                            }
                                                        })
                                                    }} /></button>
                                                </div>
                                            </TbCell>
                                        ]
                                    }
                                </TbRow>)
                            }
                        </TbBody>
                    </Table>
                </div>
            </div>
            <div style={{ padding: '1.2rem 2.4rem' }}>
                <Pagination
                    /// Size
                    currentPage={pageDetails.page}
                    /// pageSize
                    itemPerPage={pageDetails.size}
                    // data.total
                    totalItem={tables?.count ?? 0}
                    /// action
                    onChangePage={(page, size) => {
                        if (pageDetails.page !== page || pageDetails.size !== size) {
                            setPageDetails({ page: page, size: size });
                            getTables(page, size)
                        }
                    }}
                />
            </div>
        </div>
    </div>
}