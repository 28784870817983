import { forwardRef, useEffect, useRef, useState } from "react"
import { TableController } from "../../home/table/controller"
import { DataController } from "../controller"
import { Pagination, Popup, showPopup, Table, TbBody, TbCell, TbHeader, TbRow, Text, TextField, ToastMessage } from "wini-web-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faListUl, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"
import { getTableConfig } from "../config"
import { OutlineEnlargeVertical } from "../../../assets/icon"
import PopupAddEditData from "./settingForm"

const PopupManageTableFK = forwardRef(function PopupManageTableFK(data, ref) {
    const _colController = new TableController(data.pid, "column")
    const _dataController = new DataController({ pid: data.pid, module: data.rel.TableFK })
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 })
    const [_data, setData] = useState({ data: [], total: undefined })
    const [cols, setCols] = useState([])
    const popupRef = useRef()

    const getData = async (page, size) => {
        const res = await _dataController.aggregateList({
            page: page ?? pageDetails.page,
            size: size ?? pageDetails.size,
            searchRaw: `@${data.rel.Column}:{${data.item.Id}*}`
        })
        if (res.code === 200) {
            setData({ data: res.data, total: res.totalCount })
        } else {
            ToastMessage.errors(res.message)
        }
    }

    useEffect(() => {
        getData()
        _colController.getListSimple(
            {
                page: 1,
                size: 100,
                query: `@TableName:{${data.rel.TableFK}}`,
                returns: ["Id", "Name", "Setting", "DataType"]
            }
        ).then(res => {
            if (res.code === 200) setCols(res.data.map((e, i) => {
                e.Setting = e.Setting ? JSON.parse(e.Setting) : { Title: e.Name, Sort: i }
                return e
            }).filter((e) => e.Name !== "Id" && !e.Setting.IsHidden).sort((a, b) => a.Setting.Sort - b.Setting.Sort))
        })
    }, [])

    const showPopupAddEdit = (item) => {
        showPopup({
            ref: popupRef,
            style: { width: '80rem' },
            content: <PopupAddEditData
                ref={popupRef}
                id={item?.Id?.toLowerCase()?.replaceAll("-", "")}
                rel={{ ...data.rel, value: data.item.Id }}
                pid={data.pid}
                module={data.rel.TableFK}
                onSuccess={getData}
            />
        })
    }

    return <div className='col' style={{ flex: 1, height: '100%', width: '100%' }}>
        <Popup ref={popupRef} />
        <div className="col table-view">
            <div className='row' style={{ gap: '0.8rem', padding: '1.6rem 2.4rem', }}>
                <Text className='heading-5' maxLine={2} style={{ flex: 1, width: '100%' }}>Danh sách {data.rel.TableFK}</Text>
                <button type='button' className='row button-primary' onClick={() => { showPopupAddEdit() }}>
                    <FontAwesomeIcon icon={faPlus} />
                    <Text className='button-text-3'>Thêm mới</Text>
                </button>
            </div>
            <div className='row filter-table' style={{ padding: '1.2rem 2.4rem', gap: '0.8rem' }}>
                <TextField
                    placeholder='Tìm kiếm'
                    prefix={<FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.4rem', color: '#161D2470' }} />}
                    style={{ padding: '0.5rem 1.6rem', borderRadius: '0.6rem', height: '3.2rem' }}
                />
                <button className='row button-neutral' onClick={() => { }}>
                    <FontAwesomeIcon icon={faListUl} />
                    <Text className='button-text-3'>Bộ lọc</Text>
                </button>
            </div>
            <div className='col' style={{ padding: '0 2.4rem', width: '100%', flex: 1, height: '100%' }}>
                <div style={{ overflow: 'auto', width: '100%', flex: 1, height: '100%' }}>
                    <Table>
                        <TbHeader>
                            {
                                cols.map((_col, i) => {
                                    const { _alignCell, _minW } = getTableConfig(_col)
                                    return <TbCell key={_col.Id} fixed={i === 0} style={{ minWidth: _minW }} className='setting-col-button'>
                                        <div className='row' style={{ height: '100%', gap: '0.8rem' }}>
                                            <Text className='heading-9'>{_col.Setting.Title}</Text>
                                            <button type='button' className='row icon-button24' style={{}}>
                                                <OutlineEnlargeVertical size={'1.4rem'} />
                                            </button>
                                        </div>
                                    </TbCell>
                                })
                            }
                        </TbHeader>
                        <TbBody>
                            {
                                _data.data.map((item, index) => <TbRow key={`${item.name}-${index}`}>
                                    {
                                        cols.map((_col, i) => {
                                            const { _alignCell, _minW, _value } = getTableConfig(_col, item)
                                            return <TbCell key={`${_col.Id}-${index}`} fixed={i === 0} style={{ minWidth: _minW }}>
                                                <Text maxLine={3} className='heading-9'>{_value ?? ""}</Text>
                                            </TbCell>
                                        })
                                    }
                                </TbRow>)
                            }
                        </TbBody>
                    </Table>
                </div>
            </div>
        </div>
        <div style={{ padding: '1.2rem 2.4rem' }}>
            <Pagination
                currentPage={pageDetails.page}
                itemPerPage={pageDetails.size}
                totalItem={data?.total ?? 0}
                onChangePage={(page, size) => {
                    if (pageDetails.page !== page || pageDetails.size !== size) {
                        setPageDetails({ page: page, size: size });
                        getData(page, size)
                    }
                }}
            />
        </div>
    </div>
})

export default PopupManageTableFK