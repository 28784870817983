import './view.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ComponentStatus, Dialog, DialogAlignment, Popup, Select1, Switch, Text, ToastMessage, showDialog, showPopup } from "wini-web-components";
import { faChevronRight, faPencilAlt, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { DataController, SettingDataController } from '../controller';
import { TableController } from '../../home/table/controller';
import { DashBoardCardType } from '../da';
import MapGroupData from './mapGroupData';
import PopupAddCard from './addCard';

export default function ModuleDashboardView() {
    const selectedM = useSelector((store) => store.module.data)
    const { module, pModule } = useParams()
    const searchParams = new URLSearchParams(window.location.search)
    const _pid = searchParams.get("pid")
    const _settingDataController = new SettingDataController({ pid: _pid, setting: "chart" })
    const _relController = new TableController(_pid, "rel")
    const ref = useRef()
    const dialogRef = useRef()
    const [settingMode, setMode] = useState(false)
    const rangeOptions = [{ id: 7, name: 'Last 7 days' }, { id: 30, name: 'Last 30 days' }, { id: 45, name: 'Last 45 days' }, { id: 60, name: 'Last 60 days' }, { id: 90, name: 'Last 90 days' }, { id: 120, name: 'Last 120 days' }]
    const [filterDate, setFilter] = useState(rangeOptions[0].id)
    const [cards, setCards] = useState({ data: [] })
    const [relatives, setRelatives] = useState([])

    const showPopupAddCard = () => {
        showPopup({
            ref: ref,
            heading: <div className="heading-6 popup-header">Thêm card</div>,
            style: { width: '120rem', height: '80%' },
            content: <PopupAddCard
                ref={ref}
                colController={new TableController(_pid, "column")}
                relController={_relController}
                onSuccess={async (_newCard) => {
                    const res = await _settingDataController.action("add", { data: [_newCard] })
                    if (res.code === 200) {
                        if (relatives.every(e => e.Id !== _newCard.RelativeId)) {
                            const relResponse = await _relController.getByListId([_newCard.RelativeId])
                            if (relResponse.code === 200) {
                                var _newRels = [...relatives, ...relResponse.data]
                                setRelatives(_newRels)
                            }
                        }
                        _newCard.TbName = (_newRels ?? relatives).find((_rel) => _rel.Id === _newCard.RelativeId)?.TableFK
                        setCards({ data: [_newCard, ...cards.data], total: cards.total + 1 })
                    } else {
                        ToastMessage.errors(res.message)
                    }
                }}
            />
        })
    }

    const getData = async (page) => {
        const res = await _settingDataController.getListSimple({ page: page ?? 1, size: 8 })
        if (res.code === 200) {
            const _relativeIds = res.data.map(e => e.RelativeId).filter(id => relatives.every(e => e.Id !== id))
            if (_relativeIds.length) {
                const relResponse = await _relController.getByListId(_relativeIds)
                if (relResponse.code === 200) {
                    var _newRels = [...relatives, ...relResponse.data].filter(e => e != undefined)
                    setRelatives(_newRels)
                }
            }
            const _newCards = []
            if (page) {
                _newCards.push(...cards.data, ...res.data.map(e => {
                    e.TbName ??= (_newRels ?? relatives).find((_rel) => _rel.Id === e.RelativeId)?.TableFK
                    return e
                }))
            } else {
                _newCards.push(...res.data.map(e => {
                    e.TbName ??= (_newRels ?? relatives).find((_rel) => _rel.Id === e.RelativeId)?.TableFK
                    return e
                }))
            }
            setCards({ data: _newCards, total: res.totalCount })
        } else {
            ToastMessage.errors(res.message)
        }
    }

    useEffect(() => {
        if (module === selectedM?.TableId) getData()
    }, [_pid, module, selectedM])

    return <div className="col view">
        <Popup ref={ref} />
        <Dialog ref={dialogRef} />
        <div className="col table-view">
            <div className='col' style={{ padding: '1.6rem 0', gap: '0.6rem', borderBottom: 'var(--border-grey1)', margin: '0 2.4rem', width: 'calc(100% - 4.8rem)' }}>
                <div className='row breadcrumb'>
                    <NavLink to={'/home'} className='body-3' style={{ opacity: 0.85 }}>Quản lý phần mềm</NavLink>
                    {
                        pModule ? <>
                            <FontAwesomeIcon icon={faChevronRight} />
                            <Text className='body-3' style={{ opacity: 0.85 }}>{pModule}</Text>
                        </> : null
                    }
                    <FontAwesomeIcon icon={faChevronRight} />
                    <Text className='label-3' style={{ opacity: 0.9 }}>{selectedM.name ?? "-"}</Text>
                </div>
                <div className='row' style={{ gap: '0.8rem' }}>
                    <Text className='heading-5' maxLine={2} style={{ flex: 1, width: '100%' }}>{selectedM.name ?? "-"}</Text>
                    <button type='button' className='row button-primary' onClick={showPopupAddCard}>
                        <FontAwesomeIcon icon={faPlus} />
                        <Text className='button-text-3'>Thêm card</Text>
                    </button>
                </div>
            </div>
            <div className='row' style={{ padding: '1.2rem 0', gap: '0.4rem', borderBottom: 'var(--border-grey1)', margin: '0 2.4rem', width: 'calc(100% - 4.8rem)' }}>
                <div className='row' style={{ gap: '0.8rem' }}>
                    <Switch value={settingMode} />
                    <Text className='label-4'>Chế độ chỉnh sửa</Text>
                </div>
                <div style={{ flex: 1 }} />
                <div className='row divider' style={{ height: '2.8rem' }} />
                <div className='row' >
                    <Select1
                        readOnly
                        options={rangeOptions}
                        value={filterDate}
                        onChange={(v) => { setFilter(v.id) }}
                        style={{ height: '3.6rem', padding: '0.4rem 1rem' }}
                    />
                </div>
            </div>
            <div className='row' style={{ padding: '2.4rem', width: '100%', flex: 1, height: '100%', flexWrap: 'wrap', overflow: 'hidden auto', gap: '2.4rem', alignItems: 'start' }}>
                {cards.data.map(e => <CardItem
                    key={e.Id}
                    pid={_pid}
                    item={e}
                    timeRannge={filterDate}
                    onDelete={async () => {
                        const res = await _settingDataController.action("delete", { ids: [e.Id] })
                        if (res.code === 200) {
                            setCards({
                                data: cards.data.filter(el => el.Id !== e.Id),
                                total: cards.total - 1
                            })
                        } else {
                            ToastMessage.errors(res.message)
                        }
                    }}
                />)}
            </div>
        </div>
    </div>
}

export const CardItem = ({ item, pid, timeRannge = 7, onDelete, onEdit }) => {
    const [_result, setResult] = useState([])
    const [cardItem, setCardItem] = useState()
    const ref = useRef()
    const dialogRef = useRef()
    const _groupByTimeChart = [DashBoardCardType.bar, DashBoardCardType.horizontalBar, DashBoardCardType.bubble, DashBoardCardType.line, DashBoardCardType.scatter, DashBoardCardType.radar]

    useEffect(() => {
        if (item) {
            const _reducers = [{ Name: item.Name, GroupBy: item.GroupBy, Reducer: item.Reducer, ReducerBy: item.ReducerBy }]
            if (_groupByTimeChart.includes(item.Type)) {
                if (timeRannge > 30) {
                    _reducers.push({ Name: "_month", GroupBy: "_month", Query: `"timefmt(month(@DateCreated/1000), '%m')"` })
                } else {
                    _reducers.push({ Name: "_day", GroupBy: "_day", Query: `"timefmt(@DateCreated/1000, '%d-%m')"` })
                }
            }
            const _tbRelController = new DataController({ pid: pid, module: item.TableFK })
            const _now = new Date()
            const _startDate = new Date(_now.getFullYear(), _now.getMonth(), _now.getDate() - timeRannge)
            _tbRelController.groupBy({
                searchRaw: `@DateCreated:[${_startDate.getTime()} ${_now.getTime()}]`,
                reducers: _reducers
            }).then((res) => {
                if (res.code === 200) {
                    setResult(res.data)
                    setCardItem({ ...item, Setting: JSON.parse(item.Setting) })
                }
            })
        }
    }, [item, timeRannge])

    return <div className={`col ${_groupByTimeChart.includes(cardItem?.Type) ? "col12-xxl col12-xl col24" : "col4-xxl col6-xl col12"}`} style={{ '--gutter': '2.4rem', padding: '0.8rem', borderRadius: '1.2rem', border: 'var(--border-grey1)' }}>
        <Popup ref={ref} />
        <Dialog ref={dialogRef} />
        <div className='row' style={{ gap: '0.8rem', padding: '1rem 1.6rem 0 2rem', marginBottom: '1.6rem' }}>
            <Text className='semibold4' style={{ flex: 1 }}>{cardItem?.Title}</Text>
            <button type='button' className='row icon-button28' onClick={onEdit}>
                <FontAwesomeIcon icon={faPencilAlt} />
            </button>
            <button type='button' className='row icon-button28' onClick={() => {
                showDialog({
                    ref: dialogRef,
                    alignment: DialogAlignment.center,
                    status: ComponentStatus.WARNING,
                    title: "Bạn chắc chắn muốn xóa chart này",
                    onSubmit: onDelete
                })
            }}>
                <FontAwesomeIcon icon={faTrashCan} />
            </button>
        </div>
        {cardItem && _result.length && <MapGroupData
            listData={_result}
            cardItem={cardItem}
            filterTime={timeRannge > 30 ? "_month" : "_day"}
        />}
    </div>
}