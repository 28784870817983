import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Text, TextField } from 'wini-web-components'
import { faCaretDown, faChartSimple, faPlus, faRectangleList, faSearch, faT, faToggleOff } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ComponentType } from '../../home/table/da'
import { faCircleDot, faImage, faSquareCheck } from '@fortawesome/free-regular-svg-icons'
import { OutlineButton, OutlineContainer, OutlineInputMultiLine, OutlineInputPipe } from '../../../assets/icon'
import { PageActions } from './reducer'
import { onSelectedLayer, onHoverLayer } from './pageView'
import AssetsTab from './assets'

export default function LeftView({ pid, listPage = [] }) {
    const { page, layers, selectedLayerId } = useSelector((store) => store.page.data)
    const [tab, setTab] = useState(0)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleResizeH = (ev) => {
        ev.preventDefault()
        const pageListElement = ev.target.closest('.page-list-conatiner')
        function onResize(event) {
            const pageListRect = pageListElement.getBoundingClientRect()
            pageListElement.style.height = `${pageListRect.height - pageListRect.bottom + event.pageY}px`
        }
        document.body.addEventListener('mousemove', onResize)
        document.body.onmouseup = () => {
            document.body.removeEventListener('mousemove', onResize)
            document.body.onmouseup = undefined
        }
    }

    const handleResizeW = (ev) => {
        ev.preventDefault()
        const _sidebar = ev.target.closest('.setting-page-sidebar.left')
        onSelectedLayer(undefined)
        PageActions.setSelectedLayerId(dispatch, undefined)
        function onResize(event) {
            const pageListRect = _sidebar.getBoundingClientRect()
            _sidebar.style.width = `${pageListRect.width - pageListRect.right + event.pageX}px`
        }
        document.body.addEventListener('mousemove', onResize)
        document.body.onmouseup = () => {
            document.body.removeEventListener('mousemove', onResize)
            document.body.onmouseup = undefined
        }
    }

    const renderLayerTile = (item, space = 0.4) => {    
        const children = (layers ?? []).filter(e => e.ParentId === item.Id)
        switch (item.Type) {
            case ComponentType.text:
                var icon = <FontAwesomeIcon icon={faT} style={{ fontSize: '1.4rem' }} />
                break;
            case ComponentType.img:
                icon = <FontAwesomeIcon icon={faImage} style={{ fontSize: '1.4rem' }} />
                break;
            case ComponentType.checkbox:
                icon = <FontAwesomeIcon icon={faSquareCheck} style={{ fontSize: '1.6rem' }} />
                break;
            case ComponentType.radio:
                icon = <FontAwesomeIcon icon={faCircleDot} style={{ fontSize: '1.6rem' }} />
                break;
            case ComponentType.switch:
                icon = <FontAwesomeIcon icon={faToggleOff} style={{ fontSize: '1.6rem' }} />
                break;
            case ComponentType.textField:
                icon = <OutlineInputPipe color={'#fff'} size={'1.8rem'} />
                break;
            case ComponentType.textArea:
                icon = <OutlineInputMultiLine color={'#fff'} size={'1.8rem'} />
                break;
            case ComponentType.button:
                icon = <OutlineButton color={'#fff'} size={'1.8rem'} />
                break;
            case ComponentType.container:
                icon = <OutlineContainer color={'#fff'} size={'1.8rem'} />
                break;
            case ComponentType.chart:
                icon = <FontAwesomeIcon icon={faChartSimple} style={{ fontSize: '1.4rem' }} />
                break;
            case ComponentType.form:
                icon = <FontAwesomeIcon icon={faRectangleList} style={{ fontSize: '1.4rem' }} />
                break;
            default:
                break;
        }
        return <div key={item.Id} className='col' style={{ gap: '0.4rem' }}>
            <div className={`row layer-tile ${item.Id === selectedLayerId ? "selected" : ""}`} style={{ paddingLeft: `${space}rem` }} onMouseEnter={() => onHoverLayer(item.Id)} onMouseOut={() => onHoverLayer()} onClick={() => { PageActions.setSelectedLayerId(dispatch, item.Id) }}>
                <div className='row icon-button24'>{icon}</div>
                <TextField disabled defaultValue={item.Name} className='semibold1' />
                {children.length ? <div className='row icon-button20'><FontAwesomeIcon icon={faCaretDown} style={{ fontSize: '1.2rem' }} /></div> : null}
            </div>
            {children.map(e => renderLayerTile(e, space + 2.8))}
        </div>
    }

    const renderTabView = () => {
        switch (tab) {
            case 0:
                return <>
                    <div className='col page-list-conatiner'>
                        <div className='row' style={{ padding: '0.8rem 0.8rem 0.8rem 1.2rem' }}>
                            <Text className='semibold1' style={{ flex: 1 }}>Pages</Text>
                            <button type='button' className='row icon-button20' onClick={() => { }}>
                                <FontAwesomeIcon icon={faPlus} style={{ fontSize: '1.2rem' }} />
                            </button>
                        </div>
                        <div className='col' style={{ padding: '0.4rem' }}>
                            {listPage.map((item, i) => {
                                return <button key={item.Id} type='button' className={`row page-tile ${page?.Id === item.Id ? "selected" : ""}`}
                                    onClick={() => {
                                        const params = new URLSearchParams();
                                        params.append("pid", pid)
                                        params.append("pageid", item.Id)
                                        navigate('?' + params.toString());
                                    }}>
                                    <TextField disabled className='semibold1' defaultValue={item.Name} />
                                </button>
                            })}
                        </div>
                        <div className='row' onMouseDown={handleResizeH} />
                    </div>
                    <div className='col layer-list-container'>
                        {(layers ?? []).filter(e => !e.ParentId).map(e => renderLayerTile(e))}
                    </div>
                </>
            case 1:
                return <AssetsTab />
            default:
                return <div />
        }
    }

    return <div className="row setting-page-sidebar left">
        <div className='col' style={{ flex: 1, height: '100%' }}>
            <div className='row action-tab'>
                <button type='button' className={`row ${tab === 0 ? "selected" : ""}`} onClick={() => { setTab(0) }}>
                    <Text className='semibold1'>Screen</Text>
                </button>
                <button type='button' className={`row ${tab === 1 ? "selected" : ""}`} onClick={() => { setTab(1) }}>
                    <Text className='semibold1'>Assets</Text>
                </button>
                <div style={{ flex: 1 }} />
                <button type='button' className={`row icon-button20 ${tab === 2 ? "selected" : ""}`} onClick={() => { setTab(2) }}>
                    <FontAwesomeIcon icon={faSearch} style={{ fontSize: '1.2rem' }} />
                </button>
            </div>
            {renderTabView()}
        </div>
        <div className='col' onMouseDown={handleResizeW} />
    </div>
}