import { forwardRef, useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Checkbox, closePopup, ComponentStatus, Dialog, DialogAlignment, showDialog, Text, ToastMessage } from "wini-web-components";
import { RenderComponentByType, validateForm } from "../config";
import { ComponentType, FEDataType, ValidateType } from "../../home/table/da";
import { randomGID, Ultis } from "../../../Utils";
import { DataController } from "../controller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faGripVertical, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { TableController } from "../../home/table/controller";
import { Select1Form, SelectMultipleForm, TextFieldForm } from "../../../project-component/component-form";

const PopupAddEditData = forwardRef(function PopupAddEditData(data, ref) {
    const _dataController = new DataController({ pid: data.pid, module: data.module })
    const [item, setItem] = useState()
    const [settingMode, setMode] = useState(false)
    const [column, setColumn] = useState([])
    const [relative, setRelative] = useState([])
    const dialogRef = useRef()

    const getSetting = async () => {
        const _colController = new TableController(data.pid, "column")
        const _relController = new TableController(data.pid, "rel")
        _colController.getListSimple({
            page: 1,
            size: 100,
            query: `@TableName:{${data.module}} -@Name:{Id | DateCreated}`,
            returns: ["Id", "Name", "DataType", "Query", "Form"]
        }).then(res => {
            if (res.count) setColumn(res.data.map((e, i) => {
                e.Form = e.Form ? JSON.parse(e.Form) : {}
                e.Form.Sort ??= i
                if (!e.Form.Placeholder?.length) e.Form.Label ??= e.Name
                switch (e.DataType) {
                    case FEDataType.GID:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.STRING:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.BOOLEAN:
                        e.Form.ComponentType ??= ComponentType.checkbox
                        break;
                    case FEDataType.NUMBER:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.DATE:
                        e.Form.ComponentType ??= ComponentType.datePicker
                        break;
                    case FEDataType.DATETIME:
                        e.Form.ComponentType ??= ComponentType.dateTimePicker
                        break;
                    case FEDataType.MONEY:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    case FEDataType.PASSWORD:
                        e.Form.ComponentType ??= ComponentType.textField
                        break;
                    default:
                        break;
                }
                return e
            }).sort((a, b) => a.Form.Sort - b.Form.Sort))
        })
        _relController.getListSimple({
            page: 1,
            size: 100,
            query: `@TableFK:{${data.module}}${data.rel ? ` -@Column:{${data.rel.Column}}` : ""}`,
            returns: ["Id", "Column", "Form", "TablePK", "Query"]
        }).then(res => {
            if (res.count) setRelative(res.data.map((e, i) => {
                e.Form = e.Form ? JSON.parse(e.Form) : { Label: e.Column, ComponentType: ComponentType.select1, Required: true }
                e.Form.Sort ??= i
                return e
            }).sort((a, b) => a.Form.Sort - b.Form.Sort))
        })
    }

    const getData = async () => {
        if (data.id) {
            _dataController.getById(data.id).then(res => {
                if (res.code === 200) setItem(res.data)
                else ToastMessage.errors(res.message)
            })
        } else if (data.rel) {
            const defaultItem = {}
            defaultItem[data.rel.Column] = data.rel.value
            setItem(defaultItem)
        }
    }

    useEffect(() => {
        getSetting()
        getData()
    }, [])

    return <div className="col" style={{ flex: 1, width: '100%', height: '100%' }}>
        <Dialog ref={dialogRef} />
        <div className='popup-header row' style={{ gap: '0.8rem' }}>
            <Text className="heading-6">{data.item?.Name ?? `${data.module} mới`}</Text>
            {settingMode || data.rel ? null : <button type="button" className="row icon-button28" onClick={() => { setMode(true) }}>
                <FontAwesomeIcon icon={faPenToSquare} />
            </button>}
        </div>
        {settingMode ?
            <SettingFormMode
                cols={column.filter(e => !e.Query?.length)}
                rels={relative}
                pid={data.pid}
                module={data.module}
                onCancel={() => { setMode(false) }}
                onSuccess={({ newCols, newRels }) => {
                    setColumn(column.map(e => newCols.find(el => e.Id === el.Id) ?? e))
                    setRelative(relative.map(e => newRels.find(el => e.Id === el.Id) ?? e))
                    setMode(false)
                }}
            /> :
            <ViewMode
                cols={column.filter(e => !e.Form.IsHidden && !e.Query?.length)}
                rels={relative}
                item={item}
                pid={data.pid}
                module={data.module}
                onCancel={() => {
                    showDialog({
                        ref: dialogRef,
                        alignment: DialogAlignment.center,
                        status: ComponentStatus.WARNING,
                        title: 'Hủy ' + (data.item ? 'chỉnh sửa' : 'thêm mới'),
                        onSubmit: () => { closePopup(ref) }
                    })
                }}
                onSuccess={() => {
                    closePopup(ref)
                    ToastMessage.success(`${data.item ? 'Chỉnh sửa' : 'Tạo mới'} ${data.module} thành công`)
                    data.onSuccess()
                }}
            />}
    </div>
})

export default PopupAddEditData

const ViewMode = ({ cols = [], rels = [], item, pid, module, onCancel, onSuccess }) => {
    const _dataController = new DataController({ pid: pid, module: module })
    const methods = useForm({ shouldFocusError: false, defaultValues: { Id: randomGID() } })
    const methodOptions = useForm({ shouldFocusError: false })

    const onSubmit = async (ev) => {
        let dataItem = { ...ev }
        dataItem.DateCreated ??= (new Date()).getTime()
        cols.forEach((_col) => {
            if (_col.Name === "DateCreated") {
                dataItem[_col.Name] ??= (new Date()).getTime()
            } else if (dataItem[_col.Name] != undefined) {
                if (!_col.Query) {
                    switch (_col.DataType) {
                        case FEDataType.GID:
                            break;
                        case FEDataType.STRING:
                            break;
                        case FEDataType.BOOLEAN:
                            break;
                        case FEDataType.NUMBER:
                            dataItem[_col.Name] = typeof dataItem[_col.Name] === 'string' ? parseFloat(dataItem[_col.Name]) : dataItem[_col.Name]
                            break;
                        case FEDataType.DATE:
                            dataItem[_col.Name] = Ultis.stringToDate(dataItem[_col.Name]).getTime()
                            break;
                        case FEDataType.DATETIME:
                            dataItem[_col.Name] = Ultis.stringToDate(dataItem[_col.Name], 'dd/mm/yyyy hh:mm:ss').getTime()
                            break;
                        case FEDataType.MONEY:
                            dataItem[_col.Name] = parseInt(dataItem[_col.Name].replaceAll(',', ''))
                            break;
                        case FEDataType.PASSWORD:
                            break;
                        default:
                            break;
                    }
                }
            }
        })
        rels.forEach((_rel) => {
            if (dataItem[_rel.Column] && typeof dataItem[_rel.Column] !== 'string')
                dataItem[_rel.Column] = dataItem[_rel.Column].join(",")
        })
        const _val = await validateForm({
            list: cols.filter(e => e.Validate?.length),
            formdata: dataItem
        })
        // Cập nhật lỗi vào React Hook Form
        if (_val && Object.keys(_val).length > 0) {
            Object.keys(_val).forEach((field) => {
                methods.setError(field, { message: _val[field].join(', ') });
            });
            return;
        }

        // Nếu có lỗi, dừng lại không thực hiện submit
        const res = await _dataController.add([dataItem])
        if (res.code !== 200) return ToastMessage.errors(res.message)
        onSuccess()
    }

    const onError = (ev) => { }

    useEffect(() => {
        if (item) {
            Object.keys(item).forEach(prop => {
                const _col = cols.find(e => e.Name === prop)
                const _rel = rels.find(e => e.Column === prop)
                if (_col) {
                    switch (_col.DataType) {
                        case FEDataType.GID:
                            methods.setValue(prop, item[prop])
                            break;
                        case FEDataType.STRING:
                            methods.setValue(prop, item[prop])
                            break;
                        case FEDataType.BOOLEAN:
                            methods.setValue(prop, item[prop])
                            break;
                        case FEDataType.NUMBER:
                            methods.setValue(prop, typeof item[prop] === 'string' ? parseFloat(item[prop]) : item[prop])
                            break;
                        case FEDataType.DATE:
                            methods.setValue(prop, Ultis.datetoString(new Date(typeof item[prop] === 'string' ? parseInt(item[prop]) : item[prop])))
                            break;
                        case FEDataType.DATETIME:
                            methods.setValue(prop, Ultis.datetoString(new Date(typeof item[prop] === 'string' ? parseInt(item[prop]) : item[prop]), 'dd/mm/yyyy hh:mm:ss'))
                            break;
                        case FEDataType.MONEY:
                            methods.setValue(prop, Ultis.money(item[prop]))
                            break;
                        case FEDataType.PASSWORD:
                            methods.setValue(prop, item[prop])
                            break;
                        default:
                            break;
                    }
                } else if (_rel) {
                    const _tmpParse = item[prop]?.length ? item[prop].split(",") : []
                    methods.setValue(prop, _rel.ComponentType === ComponentType.selectMultiple ? _tmpParse : _tmpParse[0])
                } else {
                    methods.setValue(prop, item[prop])
                }
            })
        } else {
            cols.filter((e) => e.DefaultValue != undefined).forEach((_col) => {
                switch (_col.DataType) {
                    case FEDataType.GID:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    case FEDataType.STRING:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    case FEDataType.BOOLEAN:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    case FEDataType.NUMBER:
                        methods.setValue(_col.Name, typeof _col.DefaultValue === 'string' ? parseFloat(_col.DefaultValue) : _col.DefaultValue)
                        break;
                    case FEDataType.DATE:
                        methods.setValue(_col.Name, Ultis.datetoString(new Date(typeof _col.DefaultValue === 'string' ? parseInt(_col.DefaultValue) : _col.DefaultValue)))
                        break;
                    case FEDataType.DATETIME:
                        methods.setValue(_col.Name, Ultis.datetoString(new Date(typeof _col.DefaultValue === 'string' ? parseInt(_col.DefaultValue) : _col.DefaultValue), 'dd/mm/yyyy hh:mm:ss'))
                        break;
                    case FEDataType.MONEY:
                        methods.setValue(_col.Name, Ultis.money(_col.DefaultValue))
                        break;
                    case FEDataType.PASSWORD:
                        methods.setValue(_col.Name, _col.DefaultValue)
                        break;
                    default:
                        break;
                }
            })
        }
    }, [item])

    useEffect(() => {
        rels.forEach((_rel) => {
            const _dataPKController = new DataController({ pid: pid, module: _rel.TablePK })
            _dataPKController.getListSimple({ page: 1, size: 20, query: _rel.Query }).then((res) => {
                methodOptions.setValue(`${_rel.Column}_Options`, res?.data ?? [])
            })
        })
    }, [rels])

    return <form className="col" style={{ flex: 1, width: '100%', height: '100%' }}>
        <div className="col" style={{ flex: 1, width: '100%', height: '100%', padding: '2.4rem', gap: '2rem', overflow: 'hidden auto' }}>
            {cols.map((e, i) => <RenderComponentByType key={e.Id} fieldItem={e} methods={methods} />)}
            {rels.map((_rel, _) => {
                switch (_rel.ComponentType) {
                    case ComponentType.selectMultiple:
                        return <SelectMultipleForm
                            key={_rel.Id}
                            required={_rel.Form.Required}
                            className={'row'}
                            control={methods.control}
                            errors={methods.clearErrors}
                            name={_rel.Column}
                            label={_rel.Form.Label ?? _rel.Column}
                            options={(methodOptions.watch(`${_rel.Column}_Options`) ?? []).map(e => {
                                return {
                                    id: e.Id,
                                    name: e.Name
                                }
                            })}
                        />
                    default:
                        return <Select1Form
                            key={_rel.Id}
                            required={_rel.Form.Required}
                            className={'row'}
                            control={methods.control}
                            errors={methods.clearErrors}
                            name={_rel.Column}
                            label={_rel.Form.Label ?? _rel.Column}
                            options={(methodOptions.watch(`${_rel.Column}_Options`) ?? []).map(e => {
                                return {
                                    id: e.Id,
                                    name: e.Name
                                }
                            })}
                        />
                }
            })}
        </div>
        <div className="row popup-footer">
            <Text className="button-text-3" onClick={onCancel}>Hủy</Text>
            <div style={{ flex: 1 }}></div>
            <button type="button" className="row button-primary" onClick={methods.handleSubmit(onSubmit, onError)}>
                <Text className="button-text-3">{item ? 'Lưu' : 'Tạo mới'}</Text>
            </button>
        </div>
    </form>
}

const SettingFormMode = ({ cols = [], rels = [], pid, module, onCancel, onSuccess }) => {
    const methods = useForm({ shouldFocusError: false })
    const columns = useFieldArray({
        control: methods.control,
        name: 'column',
        keyName: undefined
    })
    const relatives = useFieldArray({
        control: methods.control,
        name: 'rel',
        keyName: undefined
    })
    const _colController = new TableController(pid, "column")
    const _relController = new TableController(pid, "rel")

    const onSubmit = async (ev) => {
        const updateCols = ev.column.map(e => {
            if (e.Form.Validate?.length) {
                e.Form.Validate = e.Form.Validate.map((v) => {
                    let type = v
                    let message = "";
                    switch (v) {
                        case ValidateType.email:
                            message = "Bạn nhập chưa đúng kiểu email";
                            break;
                        case ValidateType.phone:
                            message = "Bạn nhập chưa đúng kiểu phone";
                            break;
                        case ValidateType.number:
                            message = "Bạn nhập chưa đúng kiểu number";
                            break;
                        case ValidateType.required:
                            message = "Bạn không được để trống trường này";
                            break;
                        // ... thêm case
                        default:
                            break;
                    }
                    return {
                        type: v,
                        // message: message, 
                        // value:  Object.keys(ValidateType).find(key => ValidateType[key] === v)   
                        message: null,
                        value: null
                    };
                });
            }
            delete e.isShow
            return e
        })
        const resCol = await _colController.edit(updateCols.map(e => {
            let _parseE = { ...e }
            if (typeof _parseE.Form !== "string") _parseE.Form = JSON.stringify(_parseE.Form)
            return _parseE
        }))
        const resRel = await _relController.edit(ev.rel.map(e => {
            delete e.isShow
            let _parseE = { ...e }
            if (typeof _parseE.Form !== "string") _parseE.Form = JSON.stringify(_parseE.Form)
            return _parseE
        }))
        if (resCol.code !== 200) return ToastMessage.errors(resCol.message)
        if (resRel.code !== 200) return ToastMessage.errors(resRel.message)
        ToastMessage.success(`Cập nhật form ${module} thành công`)
        onSuccess({ newCols: updateCols, newRels: ev.rels })
    }

    useEffect(() => {
        methods.setValue("column", cols)
        methods.setValue("rel", rels)
    }, [cols])

    return <div className="col" style={{ flex: 1, width: '100%', height: '100%' }}>
        <div className="col" style={{ flex: 1, width: '100%', height: '100%', padding: '1.2rem 0', overflow: 'hidden auto' }}>
            {columns.fields.map((_col, i) => {
                let _listCom = []
                switch (_col.DataType) {
                    case FEDataType.GID:
                        _listCom = [ComponentType.textField, ComponentType.select1, ComponentType.selectMultiple]
                        break;
                    case FEDataType.STRING:
                        _listCom = [ComponentType.textField, ComponentType.select1, ComponentType.textArea, ComponentType.selectMultiple, ComponentType.radio, ComponentType.ckEditor]
                        break;
                    case FEDataType.BOOLEAN:
                        _listCom = [ComponentType.checkbox, ComponentType.switch, ComponentType.radio, ComponentType.select1]
                        break;
                    case FEDataType.NUMBER:
                        _listCom = [ComponentType.textField, ComponentType.select1]
                        break;
                    case FEDataType.DATE:
                        _listCom = [ComponentType.datePicker]
                        break;
                    case FEDataType.DATETIME:
                        _listCom = [ComponentType.dateTimePicker]
                        break;
                    case FEDataType.MONEY:
                        _listCom = [ComponentType.textField, ComponentType.select1]
                        break;
                    case FEDataType.PASSWORD:
                        _listCom = [ComponentType.textField]
                        break;
                    default:
                        break;
                }
                function renderByComponentType() {
                    switch (methods.watch(`column[${i}].Form.ComponentType`)) {
                        case ComponentType.textField:
                            return <>
                                <TextFieldForm
                                    name={`column[${i}].Form.Placeholder`}
                                    label="Placeholder"
                                    className="row"
                                    style={{ gap: '0.8rem' }}
                                    errors={methods.formState.errors}
                                    register={methods.register}
                                />
                                {_col.Form.ComponentType === ComponentType.textField && <SelectMultipleForm
                                    name={`column[${i}].Form.Validate`}
                                    label="Validate"
                                    className="row"
                                    style={{ gap: '0.8rem' }}
                                    control={methods.control}
                                    errors={methods.formState.errors}
                                    register={methods.register}
                                    options={Object.keys(ValidateType).filter(key => isNaN(Number(key))).map(key => {
                                        return {
                                            id: ValidateType[key],
                                            name: key
                                        }
                                    })}
                                />}
                            </>
                        default:
                            return <>
                                <TextFieldForm
                                    name={`column[${i}].Form.Placeholder`}
                                    label="Placeholder"
                                    className="row"
                                    style={{ gap: '0.8rem' }}
                                    errors={methods.formState.errors}
                                    register={methods.register}
                                />
                            </>
                    }
                }
                return <div key={_col.Id} className="col" style={{ borderBottom: 'var(--border-grey1)' }}>
                    <div className="row"
                        style={{ padding: '1.2rem 2.4rem', gap: '1.2rem', cursor: 'pointer', backgroundColor: methods.watch(`column[${i}].isShow`) ? 'var(--selected-background)' : undefined }}
                        onClick={_col.Name.toLowerCase() === "id" ? undefined : () => {
                            const _iCol = methods.getValues('column')[i]
                            columns.update(i, { ..._iCol, isShow: !_iCol.isShow })
                        }}
                    >
                        <button type='button' className='row icon-button24' style={_col.Name === "Name" ? { pointerEvents: 'none' } : undefined}>
                            <FontAwesomeIcon icon={faGripVertical} style={{ color: '#87909e' }} />
                        </button>
                        <Checkbox
                            size={'2.2rem'}
                            value={!_col.IsHidden}
                            style={{ borderRadius: '0.4rem' }}
                            disabled={_col.Name === "Name"}
                            onChange={(v) => {
                                const _iCol = methods.getValues('column')[i]
                                columns.update(i, { ..._iCol, Form: { ..._iCol.Form, IsHidden: v } })
                            }}
                        />
                        <Text className="semibold2" style={{ fontSize: '1.5rem' }}>{_col.Name}</Text>
                        <div style={{ flex: 1 }} />
                        <FontAwesomeIcon icon={methods.watch(`column[${i}].isShow`) ? faCaretUp : faCaretDown} style={{ color: '#00204D99', fontSize: '1.6rem' }} />
                    </div>
                    <div className="col" style={{ gap: '1.2rem', padding: '0.8rem 2.4rem 0.8rem 6.4rem', display: methods.watch(`column[${i}].isShow`) ? 'flex' : 'none' }}>
                        <TextFieldForm
                            name={`column[${i}].Form.Label`}
                            label="Label"
                            className="row"
                            errors={methods.formState.errors}
                            style={{ gap: '0.8rem' }}
                            register={methods.register}
                        />
                        {_listCom.length ? <Select1Form
                            control={methods.control}
                            className="row"
                            disabled={_col.Name === "Name"}
                            style={{ gap: '0.8rem' }}
                            errors={methods.formState.errors}
                            name={`column[${i}].Form.ComponentType`}
                            options={_listCom.map(e => {
                                return {
                                    id: e,
                                    name: e
                                }
                            })}
                            label="Kiểu component"
                        /> : null}
                        {renderByComponentType()}
                    </div>
                </div>
            })}
            {relatives.fields.map((_rel, i) => {
                return <div key={_rel.Id} className="col" style={{ borderBottom: 'var(--border-grey1)' }}>
                    <div className="row"
                        style={{ padding: '1.2rem 2.4rem', gap: '1.2rem', cursor: 'pointer', backgroundColor: methods.watch(`rel[${i}].isShow`) ? 'var(--selected-background)' : undefined }}
                        onClick={() => {
                            const _iRel = methods.getValues('rel')[i]
                            relatives.update(i, { ..._iRel, isShow: !_iRel.isShow })
                        }}
                    >
                        <div className='row icon-button24' />
                        <Checkbox size={'2.2rem'} value={true} style={{ borderRadius: '0.4rem' }} disabled />
                        <Text className="semibold2" style={{ fontSize: '1.5rem' }}>{_rel.Column}</Text>
                        <div style={{ flex: 1 }} />
                        <FontAwesomeIcon icon={methods.watch(`rel[${i}].isShow`) ? faCaretUp : faCaretDown} style={{ color: '#00204D99', fontSize: '1.6rem' }} />
                    </div>
                    <div className="col" style={{ gap: '1.2rem', padding: '0.8rem 2.4rem 0.8rem 6.4rem', display: methods.watch(`rel[${i}].isShow`) ? 'flex' : 'none' }}>
                        <TextFieldForm
                            name={`rel[${i}].Form.Label`}
                            label="Label"
                            className="row"
                            errors={methods.formState.errors}
                            style={{ gap: '0.8rem' }}
                            register={methods.register}
                        />
                        <div className="row" style={{ gap: '0.8rem' }}>
                            <Text className="label-3" style={{ width: '20rem' }}>Trường bắt buộc</Text>
                            <Checkbox
                                size={'2.2rem'}
                                value={methods.watch(`rel[${i}].Form.Required`)}
                                style={{ borderRadius: '0.4rem' }}
                                onChange={(v) => {
                                    const _iRel = methods.getValues('rel')[i]
                                    columns.update(i, { ..._iRel, Form: { ..._iRel.Form, Required: v } })
                                }}
                            />
                        </div>
                        <Select1Form
                            control={methods.control}
                            className="row"
                            style={{ gap: '0.8rem' }}
                            errors={methods.formState.errors}
                            name={`rel[${i}].Form.ComponentType`}
                            options={[ComponentType.select1, ComponentType.selectMultiple].map(e => {
                                return {
                                    id: e,
                                    name: e
                                }
                            })}
                            label="Kiểu component"
                        />
                    </div>
                </div>
            })}
        </div>
        <div className="row popup-footer">
            <Text className="button-text-3" onClick={onCancel}>Hủy</Text>
            <div style={{ flex: 1 }}></div>
            <button type="button" className="row button-primary" onClick={methods.handleSubmit(onSubmit)}>
                <Text className="button-text-3">Lưu</Text>
            </button>
        </div>
    </div>
}