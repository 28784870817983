import { FEDataType } from "../home/table/da"

// report module
export interface ReportModelItem {
    Id: string,
    Name: string,
    TbName: string,
    DateCreated: number,
    Description?: string,
    Columns: string
}

export enum Reducers {
    COUNT = "COUNT",
    COUNT_DISTINCT = "COUNT_DISTINCT",
    COUNT_DISTINCTISH = "COUNT_DISTINCTISH",
    SUM = "SUM",
    MIN = "MIN",
    MAX = "MAX",
    AVG = "AVG",
    STDDEV = "STDDEV",
    QUANTILE = "QUANTILE",
    TOLIST = "TOLIST",
    FIRST_VALUE = "FIRST_VALUE",
    RANDOM_SAMPLE = "RANDOM_SAMPLE"
}

export interface ReducerItem {
    Id: string,
    Name: string,
    Title?: string,
    ReducerBy?: string,
    Reducer: string | Reducers,
    DataType: FEDataType,
    ModelId: string,
    RelativeId: string,
}


// dashboard module
export enum DashBoardCardType {
    // "area" | "line" | "donut" | "pie" | "radar" | "bar" | "scatter" | "bubble" | "polarArea" | "radialBar" | "heatmap" | "candlestick" | "boxPlot" | "rangeBar" | "rangeArea" | "treemap" | undefined
    line = 'line',
    bar = 'bar',
    horizontalBar = 'horizontal bar',
    scatter = 'scatter',
    bubble = 'bubble',
    radar = 'radar',
    area = 'area',
    doughnut = 'doughnut',
    pie = 'pie',
    numberOnly = 'number only',
}

// export interface ChartDataSetItem {
//     title?: string,
//     name?: string,
//     // data: Array<string | number | { x: number, y: number, r?: number }>,
//     borderWidth?: number | Array<number>,
//     borderColor?: string | Array<string>,
//     backgroundColor?: string | Array<string>,
//     // type?: 'line' | 'bar' | 'scatter' | 'bubble' | 'radar' | 'area' | 'doughnut' | 'pie'
// }

export interface ChartItem {
    Id: string,
    Name: string,
    TbName: string,
    Title: string,
    Type?: DashBoardCardType,
    RelativeId: string,
    GroupBy?: string,
    ReducerBy?: string,
    DateCreated: number,
    Sort: number,
    Reducer: string | Reducers,
    DataType: FEDataType,
    Setting: string | { Datasets: Array<{ Id: string | number, Label?: string, BackgroundColor?: string }>, BorderWidth?: number, Legend?: 'top' | 'right' | 'bottom' | 'left' }
}

export interface FormItem {
    Id: string,
    Name: string,
    TbName: string,
    DateCreated: number,
    Description: string,
    Type: string
}

export interface PageItem {
    Id: string,
    Name: string,
    DateCreated: number,
    Sort: number,
}

export interface LayerItem {
    Id: string,
    Name: string,
    DateCreated: number,
    Type: string,
    ParentId?: string,
    Css?: string,
    Setting?: string,
    PageId: string,
}