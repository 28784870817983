import styles from './container.module.css'
import { faBars, faChevronDown, faGears, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Controller, useForm } from "react-hook-form"
import { Checkbox, Select1, Text, TextField } from "wini-web-components"
import { OutlineArrowDown, OutlineArrowRight, OutlineArrowTurnLeft, OutlineRadius4Angle, OutlineRadiusAll } from "../../../../assets/icon"
import { useEffect, useState } from "react"
import { PageActions } from "../reducer"
import { useDispatch, useSelector } from "react-redux"
import { onSelectedLayer } from "../pageView"
import { Ultis } from '../../../../Utils'
import { ColorPicker } from './functions/funtions'
import { regexResponsiveClass } from '../../config'
import { CheckboxForm } from '../../../../project-component/component-form'

export default function SettingContainer({ selectedLayer }) {
    const methods = useForm({ shouldFocusError: false })
    const layers = useSelector((store) => store.page.data?.layers)
    const dispatch = useDispatch()
    const [radiusAll, setRadiusAll] = useState(true)
    const [paddingAll, setPaddingAll] = useState(true)

    const updateStyle = (style) => {
        let _newSettingStyle = { ...selectedLayer.Setting.style, ...style }
        for (const prop in _newSettingStyle) {
            if (_newSettingStyle[prop] == undefined) delete _newSettingStyle[prop]
        }
        PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: _newSettingStyle } }])
    }

    useEffect(() => {
        //
        const _newElement = document.getElementById(selectedLayer?.Id)
        const _newStyle = window.getComputedStyle(_newElement)
        if (_newElement) {
            const _rootFz = parseFloat(window.getComputedStyle(document.documentElement)['font-size'].replace("px", ""))
            const _direction = _newStyle.flexDirection
            methods.setValue('flex-direction', _direction)
            methods.setValue('flex-wrap', _newStyle.flexWrap)
            methods.setValue('flex-alignment', (_direction === "column" ? (_newStyle.justifyContent + "-" + _newStyle.alignItems) : (_newStyle.alignItems + "-" + _newStyle.justifyContent)).replaceAll("normal", "start"))
            if (selectedLayer.Setting.style?.backgroundColor) {
                const _bgColor = Ultis.rgbToHex(_newStyle.backgroundColor)
                methods.setValue('background-color-value', _bgColor.substring(0, 7))
                methods.setValue('background-color-opacity', `${Ultis.hexToPercent(_bgColor.substring(7))}`)
                methods.setValue('opacity', parseFloat(_newStyle.opacity))
            } else {
                methods.setValue('background-color-value', undefined)
            }

            if (selectedLayer.ParentId) {
                const _clN = [..._newElement.classList].find(e => regexResponsiveClass.test(e))
                if (_clN) {
                    methods.setValue('select-col', _clN)
                    methods.setValue('width-unit', "flex")
                } else if (selectedLayer.Setting.style?.width?.includes("px")) {
                    methods.setValue('width', _newStyle['width'].replace("px", ""))
                    methods.setValue('width-unit', "px")
                } else if (selectedLayer.Setting.style?.width?.includes("%")) {
                    methods.setValue('width', parseFloat(_newStyle['width'].replace("%", "")))
                    methods.setValue('width-unit', "%")
                } else {
                    methods.setValue('width', (parseFloat(_newStyle['width'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                    methods.setValue('width-unit', "rem")
                }

                if (selectedLayer.Setting.style?.height?.includes("px")) {
                    methods.setValue('height', _newStyle['height'].replace("px", ""))
                    methods.setValue('height-unit', "px")
                } else if (selectedLayer.Setting.style?.height?.includes("%")) {
                    methods.setValue('height', parseFloat(_newStyle['height'].replace("%", "")))
                    methods.setValue('height-unit', "%")
                } else {
                    methods.setValue('height', (parseFloat(_newStyle['height'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                    methods.setValue('height-unit', "rem")
                }
            }

            const _isScrollX = _newStyle.overflowX // hidden
            const _isScrollY = _newStyle.overflowY // hidden

            methods.setValue('isScroll', _isScrollY != undefined && _isScrollY != 'hidden' || _isScrollX != undefined && _isScrollX != 'hidden')

            const _paddingStyle = _newStyle.padding
            methods.setValue('padding', _paddingStyle)

            const _borderStyle = _newStyle.borderStyle
            methods.setValue('border-style', _borderStyle)

            const _borderColor = Ultis.rgbToHex(_newStyle.borderColor)
            methods.setValue('border-color', _borderColor.substring(0, 7))
            methods.setValue('border-color-opacity', `${Ultis.hexToPercent(_borderColor.substring(7))}`)

            if (selectedLayer.Setting.style.borderWidth?.includes("px")) {
                methods.setValue('border-width', _newStyle['border-width'].replace("px", ""))
                methods.setValue('border-unit', "px")
            } else if (selectedLayer.Setting.style.borderWidth?.includes("%")) {
                methods.setValue('border-width', parseFloat(_newStyle['border-width'].replace("%", "")))
                methods.setValue('border-unit', "%")
            } else {
                methods.setValue('border-width', (parseFloat(_newStyle['border-width'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                methods.setValue('border-unit', "rem")
            }

            if (['borderTopLeftRadius', 'borderTopRightRadius', 'borderBottomLeftRadius', 'borderBottomRightRadius'].some((v) => selectedLayer.Setting.style[v] != undefined)) {
                if (selectedLayer.Setting.style?.borderTopLeftRadius?.includes("px")) {
                    var _vTL = _newStyle['border-top-left-radius'].replace("px", "")
                    var _vUnitTL = 'px'
                } else if (selectedLayer.Setting.style?.borderTopLeftRadius?.includes("%")) {
                    _vTL = parseFloat(_newStyle['border-top-left-radius'].replace("%", ""))
                    _vUnitTL = '%'
                } else {
                    _vTL = (parseFloat(_newStyle['border-top-left-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                    _vUnitTL = "rem"
                }
                if (selectedLayer.Setting.style?.borderTopRightRadius?.includes("px")) {
                    var _vTR = _newStyle['border-top-right-radius'].replace("px", "")
                    var _vUnitTR = 'px'
                } else if (selectedLayer.Setting.style?.borderTopRightRadius?.includes("%")) {
                    _vTR = parseFloat(_newStyle['border-top-right-radius'].replace("%", ""))
                    _vUnitTR = '%'
                } else {
                    _vTR = (parseFloat(_newStyle['border-top-right-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                    _vUnitTR = "rem"
                }
                if (selectedLayer.Setting.style?.borderBottomLeftRadius?.includes("px")) {
                    var _vBL = _newStyle['border-bottom-left-radius'].replace("px", "")
                    var _vUnitBL = 'px'
                } else if (selectedLayer.Setting.style?.borderBottomLeftRadius?.includes("%")) {
                    _vBL = parseFloat(_newStyle['border-bottom-left-radius'].replace("%", ""))
                    _vUnitBL = '%'
                } else {
                    _vBL = (parseFloat(_newStyle['border-bottom-left-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                    _vUnitBL = "rem"
                }
                if (selectedLayer.Setting.style?.borderBottomRightRadius?.includes("px")) {
                    var _vBR = _newStyle['border-bottom-right-radius'].replace("px", "")
                    var _vUnitBR = 'px'
                } else if (selectedLayer.Setting.style?.borderBottomRightRadius?.includes("%")) {
                    _vBR = parseFloat(_newStyle['border-bottom-right-radius'].replace("%", ""))
                    _vUnitBR = '%'
                } else {
                    _vBR = (parseFloat(_newStyle['border-bottom-right-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                    _vUnitBR = "rem"
                }
                methods.setValue('border-top-left-radius', _vTL)
                methods.setValue('border-top-left-radius-unit', _vUnitTL)
                methods.setValue('border-top-right-radius', _vTR)
                methods.setValue('border-top-right-radius-unit', _vUnitTR)
                methods.setValue('border-bottom-left-radius', _vBL)
                methods.setValue('border-bottom-left-radius-unit', _vUnitBL)
                methods.setValue('border-bottom-right-radius', _vBR)
                methods.setValue('border-bottom-right-radius-unit', _vUnitBR)
                methods.setValue('border-radius', _vTL)
                methods.setValue('border-radius-unit', _vUnitTL)
                if (radiusAll) setRadiusAll(false)
            } else {
                if (selectedLayer.Setting.style?.borderRadius?.includes("px")) {
                    var _v = _newStyle['border-radius'].replace("px", "")
                    var _vUnit = 'px'
                } else if (selectedLayer.Setting.style?.borderRadius?.includes("%")) {
                    _v = parseFloat(_newStyle['border-radius'].replace("%", ""))
                    _vUnit = '%'
                } else {
                    _v = (parseFloat(_newStyle['border-radius'].replace("px", "")) / _rootFz).toFixed(1).replace(".0", "")
                    _vUnit = "rem"
                }
                methods.setValue('border-radius', _v)
                methods.setValue('border-radius-unit', _vUnit)
                methods.setValue('border-top-left-radius', _v)
                methods.setValue('border-top-left-radius-unit', _vUnit)
                methods.setValue('border-top-right-radius', _v)
                methods.setValue('border-top-right-radius-unit', _vUnit)
                methods.setValue('border-bottom-left-radius', _v)
                methods.setValue('border-bottom-left-radius-unit', _vUnit)
                methods.setValue('border-bottom-right-radius', _v)
                methods.setValue('border-bottom-right-radius-unit', _vUnit)
                if (!radiusAll) setRadiusAll(true)
            }

            if (_newStyle.flexWrap === "wrap") {
                const _gapC = _newStyle['column-gap']
                if (_gapC === "normal" || selectedLayer.Setting.style?.columnGap?.includes("px")) {
                    methods.setValue('column-gap', _gapC === "normal" ? 0 : _gapC.replace("px", ""))
                    methods.setValue('column-gap-unit', "px")
                } else if (selectedLayer.Setting.style?.columnGap?.includes("%")) {
                    methods.setValue('column-gap', parseFloat(_gapC.replace("%", "")))
                    methods.setValue('column-gap-unit', "%")
                } else {
                    methods.setValue('column-gap', (parseFloat(_gapC.replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                    methods.setValue('column-gap-unit', "rem")
                }
                const _gapR = _newStyle['row-gap']
                if (_gapR === "normal" || selectedLayer.Setting.style?.rowGap?.includes("px")) {
                    methods.setValue('row-gap', _gapR === "normal" ? 0 : _gapR.replace("px", ""))
                    methods.setValue('row-gap-unit', "px")
                } else if (selectedLayer.Setting.style?.rowGap?.includes("%")) {
                    methods.setValue('row-gap', parseFloat(_gapR.replace("%", "")))
                    methods.setValue('row-gap-unit', "%")
                } else {
                    methods.setValue('row-gap', (parseFloat(_gapR.replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                    methods.setValue('row-gap-unit', "rem")
                }
            } else {
                const _gap = _newStyle['gap'].split(" ")[0]
                if (_gap === "normal" || selectedLayer.Setting.style?.gap?.includes("px")) {
                    methods.setValue('gap', _gap === "normal" ? 0 : _gap.replace("px", ""))
                    methods.setValue('gap-unit', "px")
                } else if (selectedLayer.Setting.style?.gap?.includes("%")) {
                    methods.setValue('gap', parseFloat(_gap.replace("%", "")))
                    methods.setValue('gap-unit', "%")
                } else {
                    methods.setValue('gap', (parseFloat(_gap.replace("px", "")) / _rootFz).toFixed(1).replace(".0", ""))
                    methods.setValue('gap-unit', "rem")
                }
            }
        }
        setTimeout(() => { onSelectedLayer(selectedLayer?.Id) }, 100)

    }, [selectedLayer])

    return <>
        <div className="row" style={{ borderBottom: '1px solid #444444', padding: '0.8rem 1.2rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>{selectedLayer.Name}</Text>
            <button type="button" className="row icon-button16"><FontAwesomeIcon icon={faBars} /></button>
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Layout</Text>
            <button type="button" className="row icon-button16"><FontAwesomeIcon icon={faChevronDown} /></button>
        </div>
        <div className="col" style={{ padding: '0.4rem 0.8rem 0.4rem 1.2rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            <div className="row" style={{ gap: '0.8rem' }}>
                <div className="col" style={{ gap: '0.8rem', flex: 1, height: '100%' }}>
                    <div className={`row ${styles['flex-direction-options']}`}>
                        <button type="button" className={`row ${methods.watch("flex-direction") === 'column' ? styles.selected : ""}`}
                            onClick={() => {
                                let _st = { ...selectedLayer.Setting.style, overflowY: methods.getValues('isScroll') == true ? 'scroll' : undefined, overflowX: undefined }
                                delete _st.flexWrap
                                delete _st.placeContent
                                PageActions.updateLayerAll(dispatch, [{
                                    ...selectedLayer, Setting: {
                                        ...selectedLayer.Setting, style: _st, className: [...selectedLayer.Setting.className.split(" ").filter(e => e !== 'row'), "col"].join(" ")
                                    }
                                }])
                            }}
                        >
                            <OutlineArrowDown color={'#fff'} size={'1.4rem'} />
                        </button>
                        <button type="button" className={`row ${methods.watch("flex-direction") === 'row' && methods.watch("flex-wrap") !== 'wrap' ? styles.selected : ""}`}
                            onClick={() => {
                                let _st = {
                                    ...selectedLayer.Setting.style,
                                    overflowX: methods.getValues('isScroll') == true ? 'scroll' : undefined, overflowY: undefined
                                }
                                const flexDirection = methods.getValues('flex-direction')
                                const flexWrap = methods.getValues('flex-wrap')
                                delete _st.flexWrap
                                delete _st.placeContent
                                PageActions.updateLayerAll(dispatch, [{
                                    ...selectedLayer, Setting: {
                                        ...selectedLayer.Setting, style: _st, className: [...selectedLayer.Setting.className.split(" ").filter(e => e !== 'col'), "row"].join(" "),
                                    }
                                }])
                            }}>
                            <OutlineArrowRight color={'#fff'} size={'1.4rem'} />
                        </button>
                        <button type="button" className={`row ${methods.watch("flex-wrap") === 'wrap' ? styles.selected : ""}`}
                            onClick={() => {
                                PageActions.updateLayerAll(dispatch, [{
                                    ...selectedLayer, Setting: {
                                        ...selectedLayer.Setting, style: {
                                            ...selectedLayer.Setting.style, flexWrap: 'wrap',
                                            overflowY: methods.getValues('isScroll') == true ? 'scroll' : undefined, overflowX: undefined
                                        }, className: [...selectedLayer.Setting.className.split(" ").filter(e => e !== 'col'), "row"].join(" "),
                                    }
                                }])
                            }}>
                            <OutlineArrowTurnLeft color={'#fff'} size={'1.4rem'} />
                        </button>
                    </div>
                    {methods.watch("flex-wrap") !== 'wrap' ? <TextField
                        className="regular1"
                        name="gap"
                        register={methods.register('gap', {
                            onBlur: (ev) => {
                                updateStyle({
                                    gap: ev.target.value.trim() + methods.getValues('gap-unit'),
                                    rowGap: undefined,
                                    columnGap: undefined,
                                })
                            }
                        })}
                        placeholder="gap"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('gap-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('gap-unit')
                                if (_tmp !== v.id) {
                                    updateStyle({
                                        gap: v.id === 'rem' ? `${parseFloat(methods.getValues('gap')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('gap')) * 10}${v.id}` : `${parseFloat(methods.getValues('gap'))}${v.id}`,
                                        rowGap: undefined,
                                        columnGap: undefined,
                                    })
                                }
                            }}
                        />}
                    /> : null}
                </div>
                <div className={`row ${styles['alignment-options']}`}>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'start-start' ? styles.selected : ""}`}
                        onClick={() => {
                            let _v = { justifyContent: 'start', alignItems: 'start' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'start'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'start-center' ? styles.selected : ""}`} style={{ justifyContent: 'center' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'start', alignItems: 'center' } : { justifyContent: 'center', alignItems: 'start' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'start'
                            updateStyle(_v)
                        }}
                    >
                        <div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'start-end' ? styles.selected : ""}`} style={{ justifyContent: 'end' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'start', alignItems: 'end' } : { justifyContent: 'end', alignItems: 'start' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'start'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'center-start' ? styles.selected : ""}`}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'center', alignItems: 'start' } : { justifyContent: 'start', alignItems: undefined }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'center'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'center-center' ? styles.selected : ""}`} style={{ justifyContent: 'center' }}
                        onClick={() => {
                            let _v = { justifyContent: 'center', alignItems: methods.getValues("flex-direction") === 'column' ? 'center' : undefined }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'center'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'center-end' ? styles.selected : ""}`} style={{ justifyContent: 'end' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'center', alignItems: 'end' } : { justifyContent: 'end', alignItems: undefined }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'center'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'end-start' ? styles.selected : ""}`}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'end', alignItems: 'start' } : { justifyContent: 'start', alignItems: 'end' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'end'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'end-center' ? styles.selected : ""}`} style={{ justifyContent: 'center' }}
                        onClick={() => {
                            let _v = methods.getValues("flex-direction") === 'column' ? { justifyContent: 'end', alignItems: 'center' } : { justifyContent: 'center', alignItems: 'end' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'end'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                    <button type="button" className={`row ${methods.watch('flex-alignment') === 'end-end' ? styles.selected : ""}`} style={{ justifyContent: 'end' }}
                        onClick={() => {
                            let _v = { justifyContent: 'end', alignItems: 'end' }
                            if (methods.getValues("flex-wrap") === "wrap") _v['placeContent'] = 'end'
                            updateStyle(_v)
                        }}
                    ><div /></button>
                </div>
                <button type="button" className="row icon-button24"><FontAwesomeIcon icon={faGears} style={{ fontSize: '1.2rem' }} /></button>
            </div>
            {methods.watch("flex-wrap") === "wrap" ? <div className="row" style={{ gap: '0.8rem' }}>
                <TextField
                    className="regular1"
                    name="column-gap"
                    register={methods.register('column-gap', {
                        onBlur: (ev) => {
                            updateStyle({
                                columnGap: ev.target.value.trim() + methods.getValues('column-gap-unit'),
                                gap: undefined,
                            })
                        }
                    })}
                    placeholder="column-gap"
                    type="number"
                    style={{ flex: 1 }}
                    suffix={<Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('column-gap-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('column-gap-unit')
                            if (_tmp !== v.id) {
                                updateStyle({
                                    columnGap: v.id === 'rem' ? `${parseFloat(methods.getValues('column-gap')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('column-gap')) * 10}${v.id}` : `${parseFloat(methods.getValues('column-gap'))}${v.id}`,
                                    gap: undefined,
                                })
                            }
                        }}
                    />}
                />
                <TextField
                    className="regular1"
                    name="row-gap"
                    register={methods.register('row-gap', {
                        onBlur: (ev) => {
                            updateStyle({
                                rowGap: ev.target.value.trim() + methods.getValues('row-gap-unit'),
                                gap: undefined,
                            })
                        }
                    })}
                    placeholder="row-gap"
                    type="number"
                    style={{ flex: 1 }}
                    suffix={<Select1
                        readOnly
                        className="regular1"
                        popupClassName="select-unit-options"
                        value={methods.watch('row-gap-unit')}
                        options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                        style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                        onChange={(v) => {
                            const _tmp = methods.getValues('row-gap-unit')
                            if (_tmp !== v.id) {
                                updateStyle({
                                    rowGap: v.id === 'rem' ? `${parseFloat(methods.getValues('row-gap')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('row-gap')) * 10}${v.id}` : `${parseFloat(methods.getValues('row-gap'))}${v.id}`,
                                    gap: undefined,
                                })
                            }
                        }}
                    />}
                />
                <div style={{ width: '1.6rem' }} />
            </div> : null}
            <div className="col" style={{ gap: '0.4rem', }}>
                <CheckboxForm label={<Text className="semibold11" >IsScroll</Text>} name="isScroll" size={16} control={methods.control} onChange={(v) => {
                    const flexDirection = methods.getValues('flex-direction')
                    const flexWrap = methods.getValues('flex-wrap')

                    if (v) {
                        updateStyle({ overflow: undefined, overflowY: flexDirection === 'column' || flexWrap === 'wrap' ? 'scroll' : undefined, overflowX: flexDirection === 'row' && flexWrap !== 'wrap' ? 'scroll' : undefined })
                    } else {
                        updateStyle({ overflowY: undefined, overflowX: undefined, overflow: undefined })
                    }
                }} />
            </div>
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Box Properties</Text>
            <button type="button" className="row icon-button16"><FontAwesomeIcon icon={faChevronDown} /></button>
        </div>
        <div className="col" style={{ padding: '0.4rem 1.2rem 0.8rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>

            {selectedLayer.ParentId &&
                <div className="row" style={{ gap: '0.8rem' }}>
                    <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                        <Text className="semibold11" style={{ color: '#999999' }}>Width</Text>
                        {methods.watch('width-unit') === 'flex' && methods.getValues('flex-direction') == 'row' ? <Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-col"
                            value={methods.watch('select-col')}
                            options={['fixed', 'col-rest', 'col0', 'col1', 'col2'].map(e => {
                                return {
                                    id: e,
                                    name: e
                                }
                            })}
                            onChange={(ev) => {
                                const _tmp = methods.getValues('select-col')
                                if (_tmp == 'fixed') {
                                    PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, className: selectedLayer.Setting.className.split(" ").filter(e => e !== _tmp).join(" ") } }])
                                } else if (_tmp !== ev.id) {
                                    const { columnGap, gap } = layers.find(e => e.Id === selectedLayer.ParentId)?.Setting?.style
                                    PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, className: selectedLayer.Setting.className.split(" ").map(e => e === _tmp ? ev.id : e).join(" "), style: { ...selectedLayer.Setting.style, '--gutter': columnGap ?? gap } } }])
                                }
                            }}
                        /> :
                            <TextField
                                className="regular1"
                                name="width"
                                register={methods.register('width', { onBlur: (ev) => { updateStyle({ width: ev.target.value.trim() + methods.getValues('width-unit') }) } })}
                                placeholder="width"
                                type="number"
                                suffix={<Select1
                                    readOnly
                                    className="regular1"
                                    popupClassName="select-unit-options"
                                    value={methods.watch('width-unit')}
                                    options={methods.getValues('flex-direction') == 'row' ? [{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }, { id: 'flex', name: 'flex' }] : [{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                    style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                    onChange={(v) => {
                                        const _tmp = methods.getValues('width-unit')
                                        if (v.id === 'flex') {
                                            PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, className: [...(selectedLayer.Setting.className ?? "").split(" "), 'col-rest'].join(" ") } }])
                                        } else if (_tmp !== v.id) {
                                            updateStyle({ width: v.id === 'rem' ? `${parseFloat(methods.getValues('width')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('width')) * 10}${v.id}` : `${parseFloat(methods.getValues('width'))}${v.id}` })
                                        }
                                    }}
                                />}
                            />}
                    </div>
                    <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                        <Text className="semibold11" style={{ color: '#999999' }}>Height</Text>
                        <TextField
                            className="regular1"
                            name="height"
                            register={methods.register('height', { onBlur: (ev) => { updateStyle({ height: ev.target.value.trim() + methods.getValues('height-unit') }) } })}
                            placeholder="height"
                            type="number"
                            suffix={<Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-unit-options"
                                value={methods.watch('height-unit')}
                                options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                onChange={(v) => {
                                    const _tmp = methods.getValues('height-unit')
                                    if (_tmp !== v.id) {
                                        updateStyle({ height: v.id === 'rem' ? `${parseFloat(methods.getValues('height')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('height')) * 10}${v.id}` : `${parseFloat(methods.getValues('height'))}${v.id}` })
                                    }
                                }}
                            />}
                        />
                    </div>
                </div>
            }
            <div className="row" style={{ gap: '0.8rem' }}>
                <Text className="semibold11" style={{ color: '#999999' }}>Border radius</Text>
                <div className="row" style={{ borderRadius: '0.6rem', border: '1px solid #444444' }}>
                    <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: radiusAll ? undefined : "#9c6334" }} onClick={() => setRadiusAll(false)}>
                        <OutlineRadius4Angle size={'1.1rem'} color={'#fff'} />
                    </button>
                    <button type="button" style={{ padding: '0.1rem 0.4rem', borderRadius: '0.4rem', backgroundColor: radiusAll ? "#9c6334" : undefined }} onClick={() => setRadiusAll(true)}>
                        <OutlineRadiusAll size={'1.1rem'} color={'#fff'} />
                    </button>
                </div>
            </div>
            {radiusAll ? <TextField
                className="regular1"
                name="border-radius"
                register={methods.register('border-radius', { onBlur: (ev) => { updateStyle({ borderRadius: ev.target.value.trim() + methods.getValues('border-radius-unit') }) } })}
                placeholder="Border radius"
                type="number"
                suffix={<Select1
                    readOnly
                    className="regular1"
                    popupClassName="select-unit-options"
                    value={methods.watch('border-radius-unit')}
                    options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                    style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                    onChange={(v) => {
                        const _tmp = methods.getValues('border-radius-unit')
                        if (_tmp !== v.id) {
                            updateStyle({
                                borderRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-radius'))}${v.id}`,
                                borderTopLeftRadius: undefined,
                                borderBottomLeftRadius: undefined,
                                borderTopRightRadius: undefined,
                                borderBottomRightRadius: undefined,
                            })
                        }
                    }}
                />}
            /> : <div className="row" style={{ padding: '0.4rem 0', gap: '0.8rem', height: '8rem' }}>
                <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                    <TextField
                        className="regular1"
                        name="border-top-left-radius"
                        register={methods.register('border-top-left-radius', {
                            onBlur: (ev) => {
                                updateStyle({
                                    borderRadius: undefined,
                                    borderTopLeftRadius: ev.target.value.trim() + methods.getValues('border-top-left-radius-unit')
                                })
                            }
                        })}
                        placeholder="TL radius"
                        type="number"
                        suffix={<Controller
                            name={'border-top-left-radius-unit'}
                            control={methods.control}
                            render={({ field }) => <Select1
                                readOnly
                                className="regular1"
                                popupClassName="select-unit-options"
                                value={field.value}
                                options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                                style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                                onChange={(v) => {
                                    const _tmp = methods.getValues('border-top-left-radius-unit')
                                    if (_tmp !== v.id) {
                                        updateStyle({
                                            borderRadius: undefined,
                                            borderTopLeftRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-top-left-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-top-left-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-top-left-radius'))}${v.id}`
                                        })
                                    }
                                }}
                            />}
                        />}
                    />
                    <TextField
                        className="regular1"
                        name="border-bottom-left-radius"
                        register={methods.register('border-bottom-left-radius', {
                            onBlur: (ev) => {
                                updateStyle({
                                    borderRadius: undefined,
                                    borderBottomLeftRadius: ev.target.value.trim() + methods.getValues('border-bottom-left-radius-unit')
                                })
                            }
                        })}
                        placeholder="BL radius"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('border-bottom-left-radius-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('border-bottom-left-radius-unit')
                                if (_tmp !== v.id) {
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderBottomLeftRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-bottom-left-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-bottom-left-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-bottom-left-radius'))}${v.id}`
                                    })
                                }
                            }}
                        />}
                    />
                </div>
                <OutlineRadius4Angle size={'3.2rem'} color={'#fff'} />
                <div className="col" style={{ flex: 1, justifyContent: 'space-between', height: '100%' }}>
                    <TextField
                        className="regular1"
                        name="border-top-right-radius"
                        register={methods.register('border-top-right-radius', {
                            onBlur: (ev) => {
                                updateStyle({
                                    borderRadius: undefined,
                                    borderTopRightRadius: ev.target.value.trim() + methods.getValues('border-top-right-radius-unit')
                                })
                            }
                        })}
                        placeholder="TR radius"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('border-top-right-radius-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('border-top-right-radius-unit')
                                if (_tmp !== v.id)
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderTopRightRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-top-right-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-top-right-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-top-right-radius'))}${v.id}`
                                    })
                            }}
                        />}
                    />
                    <TextField
                        className="regular1"
                        name="border-bottom-right-radius"
                        register={methods.register('border-bottom-right-radius', {
                            onBlur: (ev) => {
                                updateStyle({
                                    borderRadius: undefined,
                                    borderBottomRightRadius: ev.target.value.trim() + methods.getValues('border-bottom-right-radius-unit')
                                })
                            }
                        })}
                        placeholder="BR radius"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('border-bottom-right-radius-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('border-bottom-right-radius-unit')
                                if (_tmp !== v.id)
                                    updateStyle({
                                        borderRadius: undefined,
                                        borderBottomRightRadius: v.id === 'rem' ? `${parseFloat(methods.getValues('border-bottom-right-radius')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-bottom-right-radius')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-bottom-right-radius'))}${v.id}`
                                    })
                            }}
                        />}
                    />
                </div>
            </div>}
            <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                <Text className="semibold11" style={{ color: '#999999' }}>Border style</Text>
                <Select1
                    readOnly
                    className="regular1"
                    popupClassName="border-style"
                    value={methods.watch('border-style')}
                    options={['none', 'solid', 'dashed', 'dotted'].map(e => {
                        return {
                            id: e,
                            name: e
                        }
                    })}
                    onChange={(ev) => {
                        const _tmp = methods.getValues('border-style')
                        if (_tmp !== ev.id) {
                            updateStyle({ borderStyle: ev.id })
                        }
                    }}
                />
            </div>
            <div style={{ paddingTop: '0.4rem ', paddingBottom: '0.4rem ' }}>
                <div className="col" style={{ gap: '0.4rem', flex: 1 }}>
                    <Text className="semibold11" style={{ color: '#999999' }}>Border width</Text>
                    <TextField
                        className="regular1"
                        name="border-width"
                        register={methods.register('border-width', {
                            onBlur: (ev) => {
                                PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: { ...selectedLayer.Setting.style, borderWidth: ev.target.value.trim() + methods.getValues('border-unit') } } }])
                            }
                        })}
                        placeholder="border width"
                        type="number"
                        suffix={<Select1
                            readOnly
                            className="regular1"
                            popupClassName="select-unit-options"
                            value={methods.watch('border-unit')}
                            options={[{ id: 'rem', name: 'rem' }, { id: 'px', name: 'px' }, { id: '%', name: '%' }]}
                            style={{ border: 'none', padding: 0, width: '2.4rem', textAlign: 'right' }}
                            onChange={(v) => {
                                const _tmp = methods.getValues('border-unit')
                                if (_tmp !== v.id) {
                                    PageActions.updateLayerAll(dispatch, [{ ...selectedLayer, Setting: { ...selectedLayer.Setting, style: { ...selectedLayer.Setting.style, borderWidth: v.id === 'rem' ? `${parseFloat(methods.getValues('border-width')) / 10}rem` : _tmp === "rem" ? `${parseFloat(methods.getValues('border-width')) * 10}${v.id}` : `${parseFloat(methods.getValues('border-width'))}${v.id}` } } }])
                                }
                            }}
                        />}
                    />
                </div>
                <div className="col" style={{ gap: '0.4rem', flex: 1, paddingTop: '0.8rem' }}>
                    <Text className="semibold11" style={{ color: '#999999' }}>Border color</Text>
                    {methods.watch("border-color") ? <ColorPicker
                        colorName={'border-color'}
                        colorOpcaityName={'border-color-opacity'}
                        register={methods.register}
                        colorValue={methods.watch("border-color") + Ultis.percentToHex(parseFloat(methods.watch('border-color-opacity')))}
                        onChange={({ colorValue, opacity }) => updateStyle({ borderColor: colorValue })}
                    /> : null}
                </div>
            </div>
        </div>
        <div className="row" style={{ padding: '0.8rem 1.2rem 0.4rem' }}>
            <Text className="semibold1" style={{ flex: 1 }}>Background</Text>
            {methods.watch("background-color-value") ? <button type="button" className="row icon-button16">
                <FontAwesomeIcon icon={faChevronDown} />
            </button> : <button type="button" className="row icon-button16">
                <FontAwesomeIcon icon={faPlus} />
            </button>}
        </div>
        <div className="col" style={{ padding: '0.4rem 0.8rem 0.4rem 1.2rem', borderBottom: '1px solid #444444', gap: '0.8rem' }}>
            {methods.watch("background-color-value") ? <ColorPicker
                colorName={'background-color-value'}
                colorOpcaityName={'background-color-opacity'}
                register={methods.register}
                colorValue={methods.watch("background-color-value") + Ultis.percentToHex(parseFloat(methods.watch('background-color-opacity')))}
                opacity={methods.watch('opacity')}
                onChange={({ colorValue, opacity }) => updateStyle({ backgroundColor: colorValue, opacity: opacity })}
                onDelete={() => updateStyle({ backgroundColor: undefined, opacity: undefined })}
            /> : null}
        </div>
    </>
}

