import HeroBackground from '../../../assets/Hero.png'
import PartnerImg from '../../../assets/partner.png'
import StatsImg from '../../../assets/Stats.png'
import HanwaImg from '../../../assets/project-hw.png'
import ProgramingIcon from '../../../assets/programing.svg'
import SolutionIcon from '../../../assets/solution.svg'
import GraphicDgIcon from '../../../assets/graphic.svg'
import ConsultingIcon from '../../../assets/consulting.svg'
import UIUXDgIcon from '../../../assets/ui-ux.svg'
import SupportIcon from '../../../assets/support.svg'
import LogoWini from '../../../assets/LogoWini.svg'
import FiveApp from "../../../assets/FiveApp.png";
import EtradeVN from "../../../assets/etrade.png";
import Vinbase from "../../../assets/vinbase.png";
import LogoLinkedin from '../../../assets/LogoLinkedin.svg'
import LogoTwitter from '../../../assets/LogoTwitter.svg'
import LogoFB from '../../../assets/LogoFB.svg'
import './view.css'
import { FilledEye, FilledHeadPhone, FilledRulerPencil } from '../../../assets/icon'
import { CustomSlider, Text, TextField } from 'wini-web-components'
import { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { TextAreaForm, TextFieldForm } from '../../../project-component/component-form'
import { useForm } from 'react-hook-form'
import { CardInformationVertical } from '../../../project-component/cards'

export default function LandingLayout() {
    const scrollref = useRef();
    return <div ref={scrollref} className="col main-layout" >
        <LandingHeader onChange={(id) => {
            var i = document.getElementById(id).offsetTop
            scrollref.current.scrollTo({
                top: i,
                behavior: "smooth",
            })
        }} />
        <LandingBody />
    </div>
}

const LandingHeader = ({ onChange }) => {
    return <div className="row header" style={{ height: '8rem', padding: '0 max(7.6%, 2.4rem)', justifyContent: 'space-between', backgroundColor: 'var(--background)'}}>
        <img src={LogoWini} alt='' />
        <div className='row' style={{ gap: '4.8rem' }}>
            <button type="button" style={{ fontSize: '1.8rem', color: '#14171F' }} onClick={() => onChange('home')} >Home</button>
            <button type="button" style={{ fontSize: '1.8rem', color: '#14171F' }} onClick={() => onChange('about')}>About Us</button>
            <button type="button" style={{ fontSize: '1.8rem', color: '#14171F' }} onClick={() => onChange('sv')}>Services</button>
            <button type="button" style={{ fontSize: '1.8rem', color: '#14171F' }} onClick={() => onChange('contact')}>Contact Us</button>
        </div>
    </div>
}

function LandingBody() {
    const sliderRef = useRef();
    const methods = useForm();
    const [index, setIndex] = useState(0);

    var postSubmit = (data) => {
        const response = fetch("https://api.ebig.co/api/Account/register", {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({ name: data.name, userName: data.name, email: data.email, mobile: data.phone, address: data.address, }),
        });
    };

    return (
        <div className="col" style={{ width: "100%" }}>
            {/* hero */}
            <div id="home" style={{ position: "relative", width: "100%" }}>
                <img src={HeroBackground} alt="" style={{ width: "100%" }} sizes="" />
                <Text
                    maxLine={3}
                    className="heading-1"
                    style={{
                        width: "min(55.6rem, 84.8%)",
                        position: "absolute",
                        left: "max(9.6%, 2.4rem)",
                        bottom: "min(20.6rem, 24%)",
                        fontSize: "6.6rem",
                        overflow: "visible",
                    }}
                >
                    Let WINI turn your Ideas into Reality
                </Text>
            </div>
            {/* Section 1 */}
            <div
                id="about"
                className="col"
                style={{
                    padding: "9.6rem max(11.2%, 2.4rem)",
                    alignItems: "center",
                    gap: "2.4rem",
                }}
            >
                <Text className="heading-3">About Us</Text>
                <Text
                    className="regular5"
                    style={{ textAlign: "center", width: "100%", color: "#667299" }}
                >
                    WINI là đội ngũ chuyên nghiệp với kỹ năng vững vàng trong thiết kế
                    website và phát triển ứng dụng di động. Với sự tận tâm và sáng tạo,
                    chúng tôi cam kết mang đến cho bạn những giải pháp công nghệ hiệu quả
                    và độc đáo nhất. Hãy để chúng tôi giúp bạn biến ý tưởng thành hiện
                    thực
                </Text>
                <div
                    className="row"
                    style={{ marginTop: "3.2rem", gap: "min(10.4rem, 9.2%)" }}
                >
                    {Array.from({ length: 3 }).map((_, i) => {
                        switch (i) {
                            case 0:
                                var icon = <FilledEye size={40} color={"#F9995D"} />;
                                var title = "Tầm nhìn";
                                var content =
                                    "Xây dựng một thế giới kết nối thông qua công nghệ, giúp mọi người dễ dàng trải nghiệm và tương tác một cách sáng tạo, mang lại giá trị cho mọi cá nhân và doanh nghiệp.";
                                break;
                            case 1:
                                var icon = <FilledHeadPhone size={40} color={"#F9995D"} />;
                                var title = "Sứ mệnh";
                                var content =
                                    "Phát triển và cung cấp giải pháp công nghệ tiên tiến, tạo sự thay đổi tích cực và nâng cao hiệu suất cho cá nhân và doanh nghiệp, góp phần vào sự phát triển bền vững của cộng đồng.";
                                break;
                            case 2:
                                var icon = <FilledRulerPencil size={40} color={"#F9995D"} />;
                                var title = "Mục tiêu";
                                var content =
                                    "Mục tiêu chính của chúng tôi là trở thành đối tác đáng tin cậy và lãnh đạo trong lĩnh vực công nghệ, mang lại giải pháp sáng tạo và giá trị cao nhất cho khách hàng và cộng đồng.";
                                break;
                            default:
                                break;
                        }
                        return (
                            <CardInformationVertical
                                key={"sdf-" + i}
                                style={{
                                    flex: 1,
                                    gap: "1.6rem",
                                    borderRadius: "0.8rem",
                                    border: "1px solid #e5e5e5",
                                    padding: "2.4rem",
                                }}
                                media={icon}
                                title={title}
                                content={content}
                            />
                        );
                    })}
                </div>
            </div>
            {/* Section 2 */}
            <div
                className="row"
                style={{ padding: "9.6rem max(7.6%, 2.4rem)", gap: "2.4rem" }}
            >
                <div
                    className="col"
                    style={{ flex: 1, gap: "2rem", padding: "0 max(8.8%, 2.4rem)" }}
                >
                    <Text className="heading-4" style={{ paddingLeft: "1.6rem" }}>
                        Đối tác đáng tin cậy
                    </Text>
                    <img src={PartnerImg} alt="" style={{ width: "100%" }} />
                </div>
                <CardInformationVertical
                    style={{
                        gap: "2rem",
                        borderRadius: 2,
                        backgroundColor: "var(--primary-color)",
                        padding: "4rem",
                        color: "#fff",
                        width: "min(48.8rem, 36%)",
                    }}
                    media={
                        <div className="col" style={{ gap: "2rem" }}>
                            <Text className="heading-3" style={{ color: "#fff" }}>
                                Giới thiệu
                            </Text>
                            <Text
                                Text
                                className="subtitle-2"
                                maxLine={8}
                                style={{ color: "#fff" }}
                            >
                                Với bối cảnh thị trường công nghệ thay đổi liên tục, các ứng
                                dụng phần mềm không chỉ cần chức năng để vận hành mà doanh
                                nghiệp và người dùng ngày càng chú trọng hơn về tính dễ dùng
                                tiện lợi, thẩm mỹ của phần mềm.
                            </Text>
                            <Text
                                className="subtitle-2"
                                maxLine={8}
                                style={{ color: "#fff" }}
                            >
                                Wini được ra đời để đáp ứng, cung cấp các giải pháp thiết kế và
                                module cho bối cảnh thị trường hiện tại và tương lai.
                            </Text>
                            <Text
                                className="subtitle-2"
                                maxLine={8}
                                style={{ color: "#fff" }}
                            >
                                Với tôn chỉ lấy người dùng làm trung tâm để xây dựng, phát triển
                                công ty; Wini đã được các đối tác trong và ngoài nước tin cậy
                                giao trọng trách đồng hành cùng phát triển các sản phẩm công
                                nghệ.
                            </Text>
                        </div>
                    }
                    bottomAction={
                        <button
                            style={{
                                borderRadius: "0.6rem",
                                backgroundColor: "#fff",
                                padding: "1.6rem 2.4rem",
                                width: "fit-content",
                            }}
                        >
                            <Text className="button-text-1" style={{ color: "#F9995D" }}>
                                Contact Us
                            </Text>
                        </button>
                    }
                />
            </div>
            {/* Section 3 */}
            <div
                id="sv"
                className="col"
                style={{
                    padding: "9.6rem max(11.2%, 2.4rem)",
                    alignItems: "center",
                    gap: "3.6rem",
                    marginTop: "3.2rem",
                    backgroundColor: "#F6F8F7",
                }}
            >
                <Text className="heading-3">Services</Text>
                <div
                    className="row"
                    style={{ gap: "4.8rem min(10.4rem, 9.2%)", flexWrap: "wrap" }}
                >
                    {Array.from({ length: 6 }).map((_, i) => {
                        switch (i) {
                            case 0:
                                var title = "Programing";
                                var icon = ProgramingIcon;
                                break;
                            case 1:
                                title = "Solution";
                                icon = SolutionIcon;
                                break;
                            case 2:
                                title = "Graphic Design";
                                icon = GraphicDgIcon;
                                break;
                            case 3:
                                title = "Consulting";
                                icon = ConsultingIcon;
                                break;
                            case 4:
                                title = "UX/UI Design";
                                icon = UIUXDgIcon;
                                break;
                            case 5:
                                title = "Support";
                                icon = SupportIcon;
                                break;
                            default:
                                break;
                        }
                        return (
                            <CardInformationVertical
                                key={"sduf-" + i}
                                className="col8 card-service"
                                style={{ color: i % 2 === 0 ? "var(--primary-color)" : "#fff" }}
                                media={
                                    <div className="col media">
                                        <img
                                            src={icon}
                                            alt=""
                                            style={{ width: "4rem", height: "4rem" }}
                                        />
                                    </div>
                                }
                                title={title}
                            />
                        );
                    })}
                </div>
            </div>
            {/* Section 4 */}
            <div className="col" style={{ position: "relative" }}>
                <img src={StatsImg} alt="" style={{ width: "100%" }} />
                <CardInformationVertical
                    style={{
                        position: "absolute",
                        right: "max(9.6%, 2.4rem)",
                        top: "50%",
                        transform: "translateY(-50%)",
                        width: "36rem",
                        gap: "2.4rem",
                    }}
                    titleElement={
                        <Text
                            className="heading-1"
                            maxLine={2}
                            style={{ color: "var(--primary-color)", marginBottom: "2rem" }}
                        >
                            Team Experience
                        </Text>
                    }
                    content="Là tập hợp các thành viên có nhiều năm kinh nghiệm trong công nghệ."
                    bottomAction={
                        <button
                            style={{
                                borderRadius: "0.6rem",
                                backgroundColor: "var(--primary-color)",
                                padding: "1.6rem 2.4rem",
                                width: "fit-content",
                            }}
                        >
                            <Text className="button-text-1" style={{ color: "#fff" }}>
                                Contact Us
                            </Text>
                        </button>
                    }
                />
            </div>
            {/* Section 5 */}
            <div
                className="col"
                style={{ padding: "9.6rem max(11.2%, 2.4rem)", gap: "4rem" }}
            >
                <Text className="heading-3">Projects</Text>
                <div
                    className="row"
                    style={{ justifyContent: "space-between", alignItems: "start" }}
                >
                    <div className="col">
                        <Text
                            className="semibold5"
                            style={{
                                paddingLeft: "1.6rem",
                                borderLeft: "4px solid var(--primary-color)",
                                color: "var(--primary-color)",
                            }}
                        >
                            All
                        </Text>
                    </div>
                    <div
                        className="col"
                        style={{ width: "56%", maxWidth: 840, gap: "3.2rem" }}
                    >
                        <CustomSlider
                            ref={sliderRef}
                            buttons={false}
                            onChage={(index) => setIndex(index)}
                            style={{
                                width: "100%",
                                height: "67rem",
                                backgroundColor: "#00000000",
                            }}
                        >
                            {Array.from({ length: 4 }).map((_, i) => {
                                switch (i) {
                                    case 0:
                                        var title = "Hanwha Life Insurance";
                                        var icon = HanwaImg;
                                        break;
                                    case 1:
                                        var title = "Vinbase AI platform";
                                        var icon = Vinbase;
                                        break;
                                    case 2:
                                        var title = "The Five Application";
                                        var icon = FiveApp;
                                        break;
                                    case 3:
                                        var title = "EtradeVN LC Platform";
                                        var icon = EtradeVN;
                                        break;
                                    default:
                                        break;
                                }
                                return (
                                    <div key={"iewyi-" + i}>
                                        <div
                                            className="row"
                                            style={{
                                                gap: "3.2rem",
                                                flexWrap: "wrap",
                                                height: "100%",
                                            }}
                                        >
                                            {Array.from({ length: 4 }).map((_, j) => {
                                                return (
                                                    <div
                                                        key={"sdhjfb-" + j * (i + 1)}
                                                        className="col col12"
                                                        style={{ "--gutter": "3.2rem", height: "31.8rem" }}
                                                    >
                                                        <img src={icon} alt="" style={{ width: "100%" }} />
                                                        <Text
                                                            maxLine={1}
                                                            className="heading-5"
                                                            style={{
                                                                backgroundColor: "var(--primary-color)",
                                                                padding: "1.2rem",
                                                                color: "#fff",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            {title}
                                                        </Text>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </CustomSlider>
                        <div className="row" style={{ gap: "3.2rem" }}>
                            <button
                                type="button"
                                onClick={() => {
                                    sliderRef.current.previousPage();
                                }}
                                className="row"
                                style={{
                                    gap: "0.8rem",
                                    padding: "2rem",
                                    justifyContent: "center",
                                    flex: 1,
                                    backgroundColor: "#292E3D",
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faArrowLeft}
                                    style={{ fontSize: "2.4rem", color: "#fff" }}
                                />
                                <Text className="button-text-2" style={{ color: "#fff" }}>
                                    Back
                                </Text>
                            </button>
                            <div className="row" style={{ gap: "1.6rem" }}>
                                {Array.from({ length: 4 }).map((_, i) => {
                                    return (
                                        <div
                                            key={"weihfo-" + i}
                                            style={{
                                                width: "1.6rem",
                                                height: "1.6rem",
                                                borderRadius: "50%",
                                                backgroundColor:
                                                    index === i ? "var(--primary-color)" : "#F6F8F7",
                                            }}
                                        ></div>
                                    );
                                })}
                            </div>
                            <button
                                type="button"
                                onClick={() => {
                                    sliderRef.current.nextPage();
                                }}
                                className="row"
                                style={{
                                    gap: "0.8rem",
                                    padding: "2rem",
                                    justifyContent: "center",
                                    flex: 1,
                                    backgroundColor: "#292E3D",
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faArrowRight}
                                    style={{ fontSize: "2.4rem", color: "#fff" }}
                                />
                                <Text className="button-text-2" style={{ color: "#fff" }}>
                                    Back
                                </Text>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Section 6 */}
            <form
                onSubmit={methods.handleSubmit(postSubmit)}
                className="col"
                style={{
                    padding: "9.6rem max(30%, 4rem)",
                    alignItems: "center",
                    gap: "2.4rem",
                    backgroundColor: "#F6F8F7",
                }}
            >
                <div className="col" style={{ gap: "2rem", alignItems: "center" }}>
                    <Text className="heading-3">Liên hệ tư vấn trực tiếp!</Text>
                    <Text
                        className="regular2"
                        style={{ color: "#858EAD", textAlign: "center" }}
                    >
                        Liên hệ ngay với chúng tôi để tư vấn miễn phí và khám phá các giải
                        pháp công nghệ phần mềm tùy chỉnh có thể thúc đẩy sự thành công của
                        doanh nghiệp bạn!
                    </Text>
                </div>
                <div className="row" style={{ flexWrap: "wrap", gap: "2rem 3.2rem" }}>
                    <TextFieldForm
                        required
                        className={"col12"}
                        style={{ "--gutter": "3.2rem" }}
                        placeholder={"Name"}
                        errors={methods.formState.errors}
                        register={methods.register}
                        name={"name"}
                    />
                    <TextFieldForm
                        required
                        className={"col12"}
                        style={{ "--gutter": "3.2rem" }}
                        placeholder={"Email"}
                        register={methods.register}
                        errors={methods.formState.errors}
                        name={"email"}
                    />
                    <TextFieldForm
                        required
                        className={"col12"}
                        style={{ "--gutter": "3.2rem" }}
                        placeholder={"Phone"}
                        register={methods.register}
                        errors={methods.formState.errors}
                        name={"phone"}
                        type={"number"}
                    />
                    <TextFieldForm
                        className={"col12"}
                        style={{ "--gutter": "3.2rem" }}
                        placeholder={"Address"}
                        register={methods.register}
                        errors={methods.formState.errors}
                        name={"address"}
                    />
                    <TextAreaForm
                        className={"col24"}
                        style={{ "--gutter": "3.2rem" }}
                        placeholder={"Message"}
                        errors={methods.formState.errors}
                        register={methods.register}
                        name={"message"}
                    />
                </div>
                <button
                    type="submit"
                    style={{
                        backgroundColor: "var(--primary-color)",
                        padding: "1.6rem 8rem",
                    }}
                >
                    <Text className="button-text-2" style={{ color: "#fff" }}>
                        Submit
                    </Text>
                </button>
            </form>
            {/* Section 7 */}
            <div
                id="contact"
                className="row"
                style={{
                    padding: "9.6rem max(6.4%, 1.6rem)",
                    gap: "2.4rem",
                    justifyContent: "space-between",
                    alignItems: "start",
                }}
            >
                <div className="col" style={{ gap: "3.2rem" }}>
                    <div className="row" style={{ gap: "1.2rem" }}>
                        <Text
                            className="regular4"
                            style={{ width: "9.6rem", color: "var(--primary-coolor)" }}
                        >
                            Company:
                        </Text>
                        <Text className="regular4">
                            WINI TECHNOLOGY COMPANY LIMITED{" "}
                        </Text>
                    </div>
                    <div className="row" style={{ gap: "1.2rem" }}>
                        <Text
                            className="regular4"
                            style={{ width: "9.6rem", color: "var(--primary-coolor)" }}
                        >
                            Address:
                        </Text>
                        <Text className="regular4">
                            Thanh Cong Village, Nhue Duong Ward, Hung Yen, Viet Nam{" "}
                        </Text>
                    </div>
                    <div className="row" style={{ gap: "1.2rem" }}>
                        <Text
                            className="regular4"
                            style={{ width: "9.6rem", color: "var(--primary-coolor)" }}
                        >
                            Phone:
                        </Text>
                        <Text className="regular4">+84795055003</Text>
                    </div>
                    <div className="row" style={{ gap: "1.2rem" }}>
                        <Text
                            className="regular4"
                            style={{ width: "9.6rem", color: "var(--primary-coolor)" }}
                        >
                            Email:
                        </Text>
                        <Text className="regular4">apple@wini.vn</Text>
                    </div>
                    <img
                        src={LogoWini}
                        alt=""
                        style={{ marginTop: "0.4rem", height: 48, width: "fit-content" }}
                    />
                </div>
                <div className="col" style={{ gap: "2rem" }}>
                    <Text className="regular4" style={{ color: "var(--primary-coolor)" }}>
                        Newsletter
                    </Text>
                    <div className="row" style={{ gap: "0.8rem" }}>
                        <TextField style={{ flex: 1 }} placeholder="Your email here" />
                        <button
                            className="button-text-3"
                            style={{
                                borderRadius: "0.4rem",
                                backgroundColor: "#F9995D",
                                padding: "1rem 2.2rem",
                                color: "#fff",
                            }}
                        >
                            Subscribe
                        </button>
                    </div>
                    <Text
                        className="regular4"
                        style={{ color: "var(--primary-coolor)", marginTop: "2rem" }}
                    >
                        Social
                    </Text>
                    <div className="row" style={{ gap: "2rem" }}>
                        <img
                            src={LogoFB}
                            alt=""
                            style={{ width: "4rem", height: "4rem" }}
                        />
                        <img
                            src={LogoLinkedin}
                            alt=""
                            style={{ width: "4rem", height: "4rem" }}
                        />
                        <img
                            src={LogoTwitter}
                            alt=""
                            style={{ width: "4rem", height: "4rem" }}
                        />
                    </div>
                </div>
            </div>
            {/* Footer */}
            <div style={{ height: "7rem", backgroundColor: "var(--primary-color)" }}>
                <Text
                    style={{
                        height: "7rem",
                        color: "#fff",
                        fontSize: "16px",
                        padding: "2.6rem max(6.4%, 1.6rem)",
                    }}
                >
                    WINI Technology © 2024. All Rights Reserved
                </Text>
            </div>
        </div>
    );
}